import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import useSEO from "../../hooks/useSEO";
import { useSelector } from "react-redux";
const About = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  const isEn = useSelector((state) => state.FoxReducer.En);

  useSEO({
    title: "About",
    description: "Fox Commerce Shopping",
    keywords: ["ecommerce", "Fox", "about"],
  });

  useEffect(() => {
    if (location.state && location.state.data) {
      setPrevLocation(location.state.data);
    } else {
      setPrevLocation("Home"); // Set a default value if no state is found
    }
  }, [location]);

  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title= {isEn ? "About" : "Về chúng tôi"}  prevLocation={prevLocation} />
      <div className="mt-auto">
        <h1 className="text-2xl font-bold mb-4 -mt-8">
          {isEn ? "About B's Mart" : "Giới thiệu về B’s mart"}
        </h1>
        
        <p className="mb-4">
          {isEn ? (
            <>
              B’s Mart was officially launched on June 20, 2013, and up to now, we have over 150 convenience stores in Ho Chi Minh City. In the future, B’s Mart will rapidly expand our business across the country with the ambition to be the largest convenience store chain in Vietnam.
            </>
          ) : (
            <>
              Chính thức hoạt động vào ngày 20/6/2013, hiện nay chuỗi cửa hàng tiện lợi B’s mart đã sở hữu hơn 150 cửa hàng, trải khắp khu vực thành phố Hồ Chí Minh. Trong tương lai, B’s mart sẽ nhanh chóng mở rộng phát triển trên toàn quốc với mong muốn trở thành chuỗi cửa hàng tiện lợi hàng đầu tại Việt Nam.
            </>
          )}
        </p>

        <p className="mb-4">
          {isEn ? (
            <>
              Along with rapid social development and high consumer demand, B’s Mart promises to bring our customers high value through four essential guidelines:
            </>
          ) : (
            <>
              Cùng với sự phát triển của xã hội và nhu cầu mua sắm mạnh mẽ của người tiêu dùng, B’s mart ra đời hứa hẹn sẽ mang đến cho khách hàng những giá trị tiêu dùng tốt nhất thông qua 4 phương châm chính:
            </>
          )}
        </p>

        <ul className="list-disc ml-6 mb-4">
          <li>
            <strong>{isEn ? "Be convenient 24/7:" : "Tiện lợi 24/7:"}</strong> {isEn ? "Open 24 hours a day and 7 days a week, B’s Mart is always ready to serve our customers anytime, anywhere." : "Mở cửa liên tục 24 giờ một ngày và 7 ngày một tuần, B’s mart luôn sẵn sàng chào đón khách hàng ghé đến tham quan và mua sắm, phục vụ nhu cầu tiêu dùng mọi lúc, mọi nơi."}
          </li>
          <li>
            <strong>{isEn ? "Save shopping time:" : "Tiết kiệm thời gian:"}</strong> {isEn ? "B’s Mart was created to help our busy customers and travelers save shopping time." : "Chuỗi cửa hàng tiện lợi B’s mart sinh ra là để phục vụ cho đối tượng khách hàng bận rộn, khách hàng mua lẻ và khách du lịch, giúp quý khách tiết kiệm tối đa thời gian mua sắm của mình."}
          </li>
          <li>
            <strong>{isEn ? "Satisfy customers’ demand:" : "Đáp ứng tối đa nhu cầu mua sắm:"}</strong> {isEn ? "We pay close attention to expanding our product line, maintaining stable prices, and ensuring high product quality to meet most consumer budgets. Customers can easily find products ranging from food and beverages to personal care items in a modern, clean, and comfortable shopping environment. Additionally, B’s Mart provides convenient services such as ATMs and top-up cards." : "Mặt hàng phong phú, chất lượng cùng giá thành ổn định, phù hợp với kế hoạch chi tiêu của đa số người tiêu dùng chính là yếu tố luôn được B’s mart đặc biệt quan tâm. Tại B’s mart, khách hàng có thể lựa chọn cho mình những sản phẩm ưng ý từ thực phẩm, giải khát, văn phòng phẩm cho đến vật dụng sinh hoạt cá nhân trong một không gian mua sắm hiện đại, sạch sẽ và thoải mái. Ngoài ra, B’s mart còn cung cấp cho khách hàng các dịch vụ tiện ích như: máy rút tiền ATM, thẻ nạp điện thoại và các dịch vụ khác."}
          </li>
          <li>
            <strong>{isEn ? "Serve customers in a modern style:" : "Phong cách bán lẻ hiện đại:"}</strong> {isEn ? "We focus on building enthusiastic and dynamic staff who serve our customers thoughtfully and wholeheartedly. All B’s Mart employees are trained to welcome customers with smiles and respect." : "B’s mart luôn chú trọng xây dựng đội ngũ nhân viên nhiệt tình, năng động, nhằm phục vụ quý khách tận tình và chu đáo. Nhân viên tại B’s mart luôn đón chào khách bằng nụ cười và sự trân trọng."}
          </li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">
          {isEn ? "Vision:" : "Tầm nhìn:"}
        </h2>
        <p className="mb-4">
          {isEn ? (
            "To be the community's destination, offering the best value products and services in everyday life."
          ) : (
            "Kinh doanh đa ngành nghề, đa hình thức và đa quốc gia."
          )}
        </p>

        <h2 className="text-xl font-semibold mb-2">
          {isEn ? "Mission:" : "Sứ mệnh:"}
        </h2>
        <ul className="list-disc ml-6 mb-4">
          <li>{isEn ? "To provide customers with consistently quality goods and services at reasonable prices." : "Lắng nghe và khuyến khích nhân viên, hướng đến xây dựng, phát triển công ty một cách nhanh chóng và làm hài lòng khách hàng."}</li>
          <li>{isEn ? "To be recognized as a good business partner to all suppliers." : "Phát huy thế mạnh của các chuyên gia kinh doanh bán lẻ trên toàn thế giới thông qua đội ngũ quản lý địa phương."}</li>
          <li>{isEn ? "To develop our people’s capabilities to provide excellent service to customers." : "Trở thành một doanh nghiệp năng động và là đối tác đáng tin cậy trong cộng đồng kinh doanh tiêu dùng tại Việt Nam."}</li>
        </ul>
      </div>

      <div className="pb-10">
        <Link to="/shop">
          <button className="w-52 h-10 bg-primeButtonColor text-white hover:bg-black transition duration-300">
            {isEn ? "Continue Shopping" : "Tiếp tục mua sắm"}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default About;
