import { store } from "../redux/store";
import { toast } from "react-toastify";
import { CONST_DATA } from "./constData";
import BackendApi from "./backendApi";
import { genToken } from "../services/AccountServices";
import _, { create } from "lodash";
import { v4 as uuidv4 } from "uuid";
import BizError from "./BizError";
import { useDispatch } from "react-redux";
import {
  clearIdentifiedUser,
  createOrUpdateDummy,
  increaseRetryTimes,
  resetLocker,
  updateUserToken,
} from "../redux/FoxSlice";
async function fetchData(props) {
  const state = store.getState();
  let store_code = "unselected";
  if (state.FoxReducer.useSelectedStore !== null) {
    store_code = state.FoxReducer.useSelectedStore.store_code;
  }

  if (props.method === "GET" || props.method === "get") {
    if (!props.url.includes("channel")) {
      if (props.url.includes("?")) {
        props.url = `${props.url}&channel=${CONST_DATA.CHANNEL}`;
      } else {
        props.url = `${props.url}?channel=${CONST_DATA.CHANNEL}`;
      }
    }
  }

  try {
    const response = await fetch(props.url, {
      method: props.method,
      // credentials: "include",
      headers: createHeaders(),
      body: JSON.stringify(props.body),
    });

    if (response.status === 401) {
      await refreshToken();
      return await fetchData(props);
    }
    if (response.status === 400) {
      const errorData = await response.json();
      return errorData;
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw new Error("Network response was not ok...");
  }
}

async function fetchDataNoContent(props) {
  const state = store.getState();
  let store_code = "unselected";
  if (state.FoxReducer.useSelectedStore !== null) {
    store_code = state.FoxReducer.useSelectedStore.store_code;
  }

  if (props.method === "GET" || props.method === "get") {
    if (!props.url.includes("channel")) {
      if (props.url.includes("?")) {
        props.url = `${props.url}&channel=${CONST_DATA.CHANNEL}`;
      } else {
        props.url = `${props.url}?channel=${CONST_DATA.CHANNEL}`;
      }
    }
  }

  try {
    const response = await fetch(props.url, {
      method: props.method,
      // credentials: "include",
      headers: createHeaders(),
      body: JSON.stringify(props.body),
    });

    if (response.status === 401) {
      await refreshToken();
      return await fetchData(props);
    }
    

   return response.status;

  } catch (error) {
    throw new Error("Network response was not ok...");
  }
}
function createHeaders() {
  const state = store.getState();
  const userToken = state.FoxReducer.userToken;
  const language = state.FoxReducer.En
    ? CONST_DATA.EN_LANGUAGE
    : CONST_DATA.VN_LANGUAGE;
  const headers = {
    "Accept-Language": language,
    "Content-Type": "application/json",
    "mm-token": userToken?.mm_token ?? "",
    token: userToken?.mm_token ?? userToken?.token,
    refreshToken: userToken?.refreshToken ?? "",
  };
  return headers;
}
async function fetchDataExcludeStore(props) {
  // let token = localStorage.getItem();
  const state = store.getState();

  if (props.method === "GET" || props.method === "get") {
    if (!props.url.includes("channel")) {
      if (props.url.includes("?")) {
        props.url = `${props.url}&channel=${CONST_DATA.CHANNEL}`;
      } else {
        props.url = `${props.url}?channel=${CONST_DATA.CHANNEL}`;
      }
    }
  }
  const language = state.FoxReducer.En
    ? CONST_DATA.EN_LANGUAGE
    : CONST_DATA.VN_LANGUAGE;
  try {
    const response = await fetch(props.url, {
      method: props.method,
      //credentials: "include",
      headers: {
        "Accept-Language": language,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(props.body),
    });

    if (response.status === 401) {
      await refreshToken();
      return await fetchData(props);
    }
    if (response.status === 400) {
      throw new Error("Network response was not ok 400");
    }
    const data = await response.json();

    return data;
    //}
  } catch (error) {
    if (error instanceof BizError) {
      toast.error(error.message);
      throw error;
    } else {
      throw new Error("Network response was not ok....");
    }
  }
}

async function fetchDataV2(props) {
  const state = store.getState();
  let store_code = "unselected";
  if (state.FoxReducer.useSelectedStore !== null) {
    store_code = state.FoxReducer.useSelectedStore.store_code;
  }
  if (props.method === "GET" || props.method === "get") {
    if (props.url.includes("?")) {
      if (!props.url.includes("store_id")) {
        props.url = `${props.url}&store_id=${CONST_DATA.STORE_DEFAULT}`;
      }
    } else {
      if (!props.url.includes("store_id")) {
        props.url = `${props.url}?store_id=${CONST_DATA.STORE_DEFAULT}`;
      }
    }
    if (!props.url.includes("channel")) {
      if (props.url.includes("?")) {
        props.url = `${props.url}&channel=${CONST_DATA.CHANNEL}`;
      } else {
        props.url = `${props.url}?channel=${CONST_DATA.CHANNEL}`;
      }
    }
  }

  try {
    const response = await fetch(props.url, {
      method: props.method,
      headers: createHeaders(),
      body: JSON.stringify(props.body),
    });
    if (response.status === 401) {
      await refreshToken();
      return fetchData(props);
    }
    if (response.status > 0) {
      const data = await response.json();
      data.status = response.status;
      return data;
    } else {
      const errorContent = await response.json();
      return {
        success: false,
        message: JSON.stringify(errorContent),
        status: response.status,
      };
    }
  } catch (error) {
    return { success: false, message: JSON.stringify(error), status: -100 };
  }
}
async function refreshToken() {
  const state = store.getState();

  if (!canRetryRefreshToken()) {
    throw new BizError("retry refresh session too many times but no success");
  }
  store.dispatch(increaseRetryTimes);

  try {
    if (!_.isNil(state.FoxReducer.userInfo)) {
      const response = await fetch(BackendApi.RefreshTokenGw.url, {
        method: BackendApi.RefreshTokenGw.method,
        // credentials: "include",
        headers: createHeaders(),
      });

      if (!response.ok) {
        window.location.href = "/logout/return-url=signin";
      } else {
        let res = await response.json();

        let tokenResult = {
          mm_token: res.result.token,
          refreshToken: res.result.refreshToken,
        };

        updateUserTokenState(tokenResult);
        store.dispatch(resetLocker);
      }
    } else {
      const dummyId = state.FoxReducer?.dummyCust ?? uuidv4();
      store.dispatch(clearIdentifiedUser)
      let response = await genToken(dummyId);
      if (response.ok) {
        store.dispatch(resetLocker);
        store.dispatch(createOrUpdateDummy(dummyId))
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function updateUserTokenState(tokenResult) {
  store.dispatch(updateUserToken(tokenResult));
}
function canRetryRefreshToken() {
  const state = store.getState();
 
  if (state.FoxReducer.retryLocker.retryTimes < CONST_DATA.RETRY_REFRESH_KEY) {
    return true;
  }
  let retryTimes = state.FoxReducer.retryLocker.retryTimes;
  if (isExpiredLockTime() && retryTimes < CONST_DATA.MAX_RETRY_REFRESH_TIMES) {
    return true;
  }
  return false;
}
function isExpiredLockTime() {
  const state = store.getState();
  const lastRetry = state.FoxReducer.retryLocker.lastRetry;

  try {
    const expiredMinutes = CONST_DATA.EXPIRED_IN_MINUTES * 60 * 1000;

    const difference = new Date() - new Date(lastRetry);
    
    if (difference > expiredMinutes) {
      return true;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export {
  fetchData,
  fetchDataExcludeStore,
  fetchDataV2,
  refreshToken,
  resetLocker,
  createHeaders,
  fetchDataNoContent
};
