import React, { useState, useEffect } from "react";
import Heading from "../Products/Heading";
import ProductV2 from "../Products/ProductV2";
import { useSelector } from "react-redux";
import { FoxProduct_Home } from "../../../common.js/transformation";
import { LableItem } from '../../../components/LabelLanguge/LabelItem'
import BackendApi from "../../../common.js/backendApi";
import { fetchData } from "../../../common.js/http";
import { CONST_DATA } from "../../../common.js/constData";
const BestSellers = () => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const En = useSelector((state) => state.FoxReducer.En);
  const store_code = useSelector((state) => state.FoxReducer.useSelectedStore);
  const [currentPage, setCurrentPage] = useState(1);

  const [hasMore, setHasMore] = useState(true);
  const [page_size, setPageSize] = useState(12); // Initial page_size

  // Function to determine page size based on viewport width
  const updatePageSize = () => {
    const width = window.innerWidth;
    if (width >= 1200) {
      setPageSize(8); // For large screens, show 8 products
    } else if (width >= 768) {
      setPageSize(6); // For medium screens, show 6 products
    } else {
      setPageSize(4); // For small screens, show 4 products
    }               
  };

  useEffect(() => {
    // Set initial page size based on viewport
    updatePageSize();
    
    // Update page size when window is resized
    window.addEventListener("resize", updatePageSize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", updatePageSize);
  }, []);

  const fetchProducts = async (page) => {
    
    try {
      setLoading(true);
      let props = {
        url: `${BackendApi.allProductsAndCount.url}?page=${page}&size=${page_size}&active=true&published=true&store_id=${CONST_DATA.STORE_DEFAULT}&attribute_code=2&scopes=["ProductOrigin","ProductCategory","ProductAttribute","ProductPrice","ProductImage"]`,
        method: BackendApi.allProductsAndCount.method,
      };

      const data1 = await fetchData(props);

      if (data1.success) {
        setData((prevData) => [...prevData, ...data1.results]);
        if (data1.results.length === 0 || data1.results.length < page_size || data1.count <=page_size ) {
          setHasMore(false); // Ngừng tải thêm nếu không còn dữ liệu
        }
      } else {
        setHasMore(false); // Ngừng tải thêm nếu không có kết quả
      }

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setData([]); // Xóa dữ liệu hiện tại
    setCurrentPage(1); // Reset về trang đầu tiên
    setHasMore(true); // Khởi động lại quá trình load thêm dữ liệu
    fetchProducts(1); // Tải lại từ trang 1
  }, [store_code, En]);

  let filteredData = data;
  
  const bestSeller = <LableItem code="C.BestSellers.BestSeller"></LableItem>

  const loadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    fetchProducts(currentPage + 1);
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        {/* <div className="w-8 h-8 border-2 border-t-transparent border-blue-500 rounded-full animate-spin"></div> */}
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="w-full pb-6">
      <Heading heading={bestSeller} />
      {/* <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-4 xl:grid-cols-4 gap-10"> */}
      <div className="w-full grid grid-cols-2 md:grid-cols-3 lgl:grid-cols-4 xl:grid-cols-6 gap-4">  
        {filteredData && filteredData.length > 0 ? (
          filteredData.map((data) => {
            let item = FoxProduct_Home(data,En);
            return (
              <ProductV2
                key={item.product_code}
                product_code={item.product_code}
                slug={item.slug}
                sku={item.sku}
                image={item.image}
                name={item.name}
                price={item.price}
                retail_price={item.retail_price}
                price_ex_vat={item.price_ex_vat}
                retail_price_ex_vat={item.retail_price_ex_vat}
                vat_code={item.vat_code}
                vat_rate={item.vat_rate}
                color={item.color}
                badge={item.badge}
                des={item.des}
                cat={item.cat}
                cat_name={item.cat_name}
                unit={item.unit}
              />
            );
          })
        ) : (
          <div>{En ? "No products available" : "Không có sản phẩm nào khả dụng"}</div>
        )}
  
      </div>
      {hasMore && !loading && (
        <div className="flex justify-center mt-8">
          <button
            className="px-4 py-2 bg-primeButtonColor text-secondTextColor rounded"
            onClick={loadMore}
          >
            {En?"Load More":"Xem thêm"}
          </button>
        </div>
      )}
    </div>
  );
  
};

export default BestSellers;
