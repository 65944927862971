import { useSelector } from "react-redux";
import { store } from "../../redux/store";
function GetContentLangByCode(code) {
  try{
 
    const language =store.getState().FoxReducer.foxLangugeDictionary
   
   const labelItem = language.find(item => item.LabelCode === code);
  
    const labelValue =  labelItem ? labelItem.LabelValue : code;
    return labelValue;
  }
  catch(error){
    console.log(error);
  }
   
}
const useContentLangByCode = (code) => {
  //const language =store.getState().FoxReducer.foxLangugeDictionary
  const language = useSelector((state) => state.FoxReducer.foxLangugeDictionary);
  const labelItem = language.find((item) => item.LabelCode === code);
  return labelItem ? labelItem.LabelValue : code;
};
export { GetContentLangByCode, useContentLangByCode };