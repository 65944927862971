import React, { useEffect, useRef, useState } from "react";
import { TfiClose } from "react-icons/tfi";

import Select from "react-select";
import Breadcrumbs from "../pageProps/Breadcrumbs";
import {
  FaBox,
  FaTruck,
  FaShoppingBag,
  FaExclamationCircle,
  FaCheckCircle,
  FaTimesCircle,
  FaClock,
  FaBoxOpen,
  FaFileInvoice,
  FaTrash,
  FaBan,
} from "react-icons/fa";
import { TfiMoreAlt } from "react-icons/tfi";

import { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { DateToString } from "../../utils/DatetimeHelper";
import {
  GetOrdersHistory,
  GetOrderStatuses,
} from "../../services/OrderServices";
//GetOrderStatuses
import { useNavigate } from "react-router-dom";
import CurrencyComponent from "../Currency/CurrencyFormat";
import { LableItem } from "../LabelLanguge/LabelItem";
//import { GetContentLangByCode } from "../contentLanguage/ContentFactory";
import { CONST_DATA } from "../../common.js/constData";
import { GetDeliveryItems } from "../../services/OrderServices";
import { FoxOrderHistory } from "../../common.js/transformation";
import {
  GetContentLangByCode,
  useContentLangByCode,
} from "../contentLanguage/ContentFactory";
import OrderDetailComponent from "./OrderDetailComponent";
import { getStatusIcon } from "../../utils/orderUiHelper";

const OrderHistory = () => {
  const FIRST_PAGE = 1;
  const CLEAR_LIST = true;
  const [startDate, setStartDate] = useState("");
  //const [isCompletedInit, setIsCompletedInit] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  //const dispatch = useDispatch();
  const customer = useSelector((state) => state.FoxReducer.userInfo);
  const isEn = useSelector((state) => state.FoxReducer.En);
  const [orderHistory, setOrderHistory] = useState([]);
  const [orderHistoryTransform, setOrderHistoryTransform] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [endDate, setEndDate] = useState("");
  const [orderStatus, setOrderStatus] = useState([]);
  const customer_no = customer.customers[0].customer_no;
  const [minDate, setMinDate] = useState("");
  const [statuses, setStatuses] = useState([]);
  const [orderStatusOptions, setOrderStatusOptions] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageSize, setPageSize] = useState(4); // Initial page_size
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(FIRST_PAGE);
  const [deliveryItems, setDeliveryItems] = useState([]);
  const dataRef = useRef(null);
  const selectedOrderRef = useRef(null);

  //let deliveryItems =[];
  var orderStatusOptionsInit = [
    {
      value: "10",
      label: "Processing",
      icon: <FaClock className="text-yellow-500" />,
    },
    {
      value: "20",
      label: "Confirmed",
      icon: <FaCheckCircle className="text-blue-500" />,
    },
    {
      value: "30",
      label: "Picking",
      icon: <FaBoxOpen className="text-purple-500" />,
    },
    {
      value: "40",
      label: "Picked",
      icon: <FaBox className="text-indigo-500" />,
    },
    {
      value: "50",
      label: "In Shippment",
      icon: <FaTruck className="text-blue-600" />,
    },
    {
      value: "60",
      label: "Invoiced",
      icon: <FaFileInvoice className="text-green-600" />,
    },
    {
      value: "70",
      label: "Completed",
      icon: <FaCheckCircle className="text-green-500" />,
    },
    {
      value: "90",
      label: "Deleted",
      icon: <FaTrash className="text-red-500" />,
    },
    {
      value: "98",
      label: "Refused",
      icon: <FaTimesCircle className="text-red-500" />,
    },
    {
      value: "99",
      label: "Cancelled",
      icon: <FaBan className="text-red-600" />,
    },
  ];
  const navigate = useNavigate();
  const handleOnclick = (order) => {
    navigate(`/order-detail/${order.order_id}`);
  };
  function orderStatusTransform(statuses) {
    let newOrderStatusOptions = orderStatusOptionsInit.map((item) => {
      item.label = statuses.find((o) => o.value === item.value).label;
      return item;
    });

    setOrderStatusOptions(newOrderStatusOptions);
  }

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [error, setError] = useState(null);

  const getStatusColor = (status) => {
    switch (status) {
      case "10":
        return "bg-yellow-100 text-yellow-800";
      case "20":
        return "bg-blue-100 text-blue-800";
      case "30":
        return "bg-purple-100 text-purple-800";
      case "40":
        return "bg-indigo-100 text-indigo-800";
      case "50":
        return "bg-blue-100 text-blue-800";
      case "60":
        return "bg-green-100 text-green-800";
      case "70":
        return "bg-green-100 text-green-800";
      case "90":
        return "bg-gray-100 text-red-800";
      case "98":
        return "bg-red-100 text-red-800";
      case "99":
        return "bg-red-100 text-red-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const handleOrderClick = (order) => {
    try {
      setSelectedOrder(order);
    } catch (err) {
      setError("Failed to load order details");
    }
  };
  const fetchOrders = async (page, customerNo, clearList, pageNum) => {
    if (clearList === true) {
      setOrderHistoryTransform([]);
    }
    var deliItems = [];
    if (deliveryItems.length === 0) {
      deliItems = await fetchDeliveryItems();
    } else deliItems = deliveryItems;
    try {
      setLoading(true);
      const response = await GetOrdersHistory(
        customer_no,
        selectedOptions,
        startDate,
        pageSize,
        page
      );
      if (response.success) {
        setOrderHistory((prevData) => [...prevData, ...response.results]);

        let ordersTrans = response.results.map((item) => {
          return FoxOrderHistory(item, isEn, deliItems);
        });
        setOrderHistoryTransform((prevData) => [...prevData, ...ordersTrans]);
        if (
          response.results.length === 0 ||
          response.results.length < pageSize
        ) {
          setHasMore(false);
        } else {
          setHasMore(true); //
        }
      } else {
        setHasMore(false);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchDeliveryItems = async () => {
    try {
      let deliveryItemsResponse = await GetDeliveryItems();
      setDeliveryItems(deliveryItemsResponse.results);
      //deliveryItems= deliveryItemsResponse.results;
      return deliveryItemsResponse.results;
    } catch {}
  };
  useEffect(() => {
    if (selectedOrderRef.current) {
      selectedOrderRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [selectedOrder]);
  useEffect(() => {
    const fetchOrderHistoryAndOrderStatuses = async () => {
      setOrderStatusOptions(orderStatusOptionsInit);
      try {
        await fetchDeliveryItems();
        // Fetch order history
        await fetchOrders(currentPage, customer_no);

        // Fetch order statuses
        const responseOrderStatuses = await GetOrderStatuses();
        if (responseOrderStatuses.success) {
          const options = responseOrderStatuses.results.map((item) => ({
            value: item.status_code,
            label: isEn ? item.status_name_en : item.status_name_local,
          }));
          setOrderStatus(options);
          orderStatusTransform(options);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchOrderHistoryAndOrderStatuses();

    initFilter();
    // Setting the minimum date
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - 90));
    setMinDate(pastDate.toISOString().split("T")[0]);
    setHasMore(true);
  }, [isEn]);

  if (error) {
    return (
      <div className="flex items-center justify-center p-8 bg-red-50 rounded-lg">
        <FaExclamationCircle className="text-red-500 mr-2" />
        <p className="text-red-500">{error}</p>
      </div>
    );
  }
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div className="w-16 h-16 border-4 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  const customOption = (props) => {
    return (
      <components.Option
        {...props}
        innerref={props.innerRef}
        innerprops={props.innerProps}
      >
        <div div {...props} className="flex items-center p-2">
          <span className="mr-2">{props.data.icon}</span>
          {props.data.label}
        </div>
      </components.Option>
    );
  };
  const initFilter = () => {
    const fromDate = new Date();

    fromDate.setDate(fromDate.getDate() - 30);
    setStartDate(DateToString(fromDate));
    setEndDate(DateToString(new Date()));
  };

  const handleFilter = async (e) => {
    setCurrentPage(FIRST_PAGE);
    await fetchOrders(FIRST_PAGE, customer_no, CLEAR_LIST);
  };

  const loadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    fetchOrders(currentPage + 1, customer_no, false);
    if (dataRef.current) {
      // Adding a slight delay to ensure the content loads before scrolling
      setTimeout(() => {
        if (dataRef.current) {
          dataRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }, 100);
    }
  };

  return (
    <div className="max-w-container mx-auto px-1 pt-2 md:pt-5">
      <Breadcrumbs
        title={GetContentLangByCode("C.OrderHistory.Header.Title")}
      />

      <div className="w-full mb-2 p-2 bg-white shadow-md rounded-lg flex flex-col md:flex-row justify-between items-end gap-2">
        <div className="w-[90%] sm:w-[90%] lg:w-[20%] flex flex-col items-start gap-1">
          <label
            className="text-base font-semibold text-gray-700 mb-2"
            htmlFor="startDate"
          >
            <LableItem code="C.OrderHistory.OrderFromDate" />
          </label>
          <input
            id="startDate"
            type="date"
            value={startDate}
            min={minDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent py-2"
          />
        </div>

        <div className="flex flex-col gap-1 w-[90%]">
          <label
            className="text-base font-semibold text-gray-700 mb-2"
            htmlFor="orderStatus"
          >
            <LableItem code="C.OrderHistory.OrderStatus" />
          </label>

          <Select
            isMulti
            name="orderStatus"
            onChange={handleChange}
            value={selectedOptions}
            options={orderStatusOptions}
            className="basic-multi-select w-full"
            classNamePrefix="select"
            components={{
              Option: customOption,
            }}
            styles={{
              control: (base, state) => ({
                ...base,
                padding: "2px",
                backgroundColor: state.isFocused ? "#f0f0f0" : "#ffffff",
                borderColor: state.isFocused ? "#007bff" : "#ced4da",
                boxShadow: state.isFocused
                  ? "0 0 0 2px rgba(0,123,255,.25)"
                  : "none",
                "&:hover": {
                  borderColor: "#007bff",
                },
              }),
              option: (base, { isFocused, isSelected }) => ({
                ...base,
                backgroundColor: isSelected
                  ? "#007bff"
                  : isFocused
                  ? "#e2e6ea"
                  : "#ffffff",
                color: isSelected ? "#ffffff" : "#212529",
                "&:hover": {
                  backgroundColor: isSelected ? "#0056b3" : "#e2e6ea",
                  color: isSelected ? "#ffffff" : "#212529",
                },
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: "#e9ecef",
                color: "#495057",
                borderRadius: "4px",
              }),
              multiValueLabel: (base) => ({
                ...base,
                color: "#495057",
              }),
              multiValueRemove: (base) => ({
                ...base,
                color: "#6c757d",
                ":hover": {
                  backgroundColor: "#007bff",
                  color: "white",
                },
              }),
            }}
          />
        </div>

        <div className="flex flex-col gap-2 md:w-[15%] w-[80%]  items-center">
          <button
            onClick={handleFilter}
            className="bg-primeButtonColor hover:bg-black w-52 text-white py-2 px-6 rounded-md font-semibold transition-colors duration-300 h-15"
          >
            <LableItem code="C.OrderHistory.FilterButton" />
          </button>
        </div>
      </div>

      <div className="grid gap-2 md:grid-cols-1 lg:grid-cols-2" ref={dataRef}>
        {orderHistoryTransform.map((order) => (
          <div
            key={order.order_id}
            className="bg-white rounded-lg shadow-lg p-6 hover:shadow-2xl transition-shadow duration-300 hover:scale-105"
            //onClick={() => handleOnclick(order)}
            onClick={() => handleOrderClick(order)}
            tabIndex="0"
            role="button"
            ref={
              selectedOrder?.order_id === order.order_id
                ? selectedOrderRef
                : null
            }
            aria-label={`Order ${order.order_no}`}
          >
            <div className="flex justify-between items-center mb-4">
              <div>
                <h4 className="text-xl font-semibold">
                  {order.order_id}/{order.order_no}
                </h4>
                <p className="text-gray-600">{order.order_date}</p>
              </div>
              <div
                className={`flex items-center px-3 py-1 rounded-full ${getStatusColor(
                  order.status_code
                )}`}
              >
                {getStatusIcon(order.status_code)}
                <span className="ml-2 text-sm font-medium">
                  {order.status_name}
                </span>
              </div>
            </div>
            {order.item_default && (
              <div className="space-y-4">
                <div className="flex items-center space-x-4">
                  <img
                    src={order.item_default.image || CONST_DATA.DEFAULT_IMG}
                    className="w-16 h-16 object-cover rounded"
                    onError={(e) => {
                      e.target.src = CONST_DATA.DEFAULT_IMG;
                    }}
                  />
                  <div className="flex-1">
                    <h3 className="font-medium">
                      {order.item_default.item_name}
                    </h3>
                    <p className="text-gray-600">
                      {order.item_default.unit_name}
                    </p>
                    <p className="text-gray-600">
                      <LableItem code="C.Multi.Quantity" />:{" "}
                      {order.item_default.quantity} |
                      <CurrencyComponent price={order.item_default.price} />
                    </p>
                  </div>
                </div>
                <div>
                  <span>
                    <TfiMoreAlt className="text-blue-700" />
                  </span>
                </div>
              </div>
            )}

            <div className="mt-4 pt-4 border-t border-gray-200">
              <div className="flex justify-between items-center">
                <span className="font-medium">
                  <LableItem code="C.OrderHistory.Header.Total" />:
                </span>
                <span className="text-lg font-bold">
                  <CurrencyComponent price={order.total_amount} />
                </span>
              </div>
              <p className="mt-2 text-sm text-gray-600">
                {order.delivery_address}
              </p>
            </div>
          </div>
        ))}
      </div>

      {hasMore && !loading && (
        <div className="flex justify-center mt-8">
          <button
            className="px-4 py-2 bg-primeButtonColor text-secondTextColor rounded load-more-button"
            onClick={loadMore}
          >
            {isEn ? "Load More" : "Xem thêm"}
          </button>
        </div>
      )}

      {selectedOrder && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="relative bg-white rounded-lg p-3 max-w-[77rem] w-full max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <button
                onClick={() => setSelectedOrder(null)}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-transform transform hover:scale-300"
                aria-label="Close dialog"
              >
                <h1>
                  <TfiClose className="size-4 text-red-600" />
                </h1>
              </button>
            </div>
            <div className="space-y-0">
              <OrderDetailComponent
                id={selectedOrder.order_id}
              ></OrderDetailComponent>
            </div>
          </div>
        </div>
      )}

      <div className="mb-20"></div>
    </div>
  );
};

export default OrderHistory;
