export const CONST_DATA = {
    EN_LANGUAGE: "en",
    VN_LANGUAGE:"vi",
    CHANNEL: "1",
    FOX_GW:"http://172.26.16.163:1880",
    FOX:"https://172.26.16.188:3030/api/v1",
    FOX_GW_PROXY:"fox-gw",
    FOX_PROXY: "fox/api/v1",
    FOX_BASE_URL : "https://api-test.mmvietnam.com:5000",
    STORE_DEFAULT: "30010",
    DEFAULT_IMG:"/sampleImage.jpg",
    DELIVERY_ADDRESS_TYPE :"2",
    INVOICE_ADDRESS_TYPE :"1",
    RETRY_REFRESH_KEY:'RETRY_REFRESH_KEY',
    MAX_RETRY_REFRESH_TIMES: 3,
    LAST_RETRY_REFRESH :'LAST_RETRY_REFRESH',
    EXPIRED_IN_MINUTES: 5,
    ADD_BASE_DELI_FEE: true,
    ADD_THRESHOLD_DELI_FEE: true,
    DELI_SLOT_DEFAUL: "8T9",
    REQUIRED_COOKIE: false,
    FUZZINESS: "0",
    PUBLISH_URL: "https://online-test.bsmartvina.com",
    PAYMENT:{
        PAYMENT_SUCCESS_CODE:"001",
        MERCHANT_CODE: "BCOM",
        SERVICE_CODE: "PAYMENT"
    },
    ORDER: {
        PAY_NOW_STATUS : "0",
        PAY_LATER_STATUS: "10"
    }

}