import { Loading } from "./Loading";
export const LoadingButton = (props) => {
  let className = props.className;

  if (props.className === undefined || props.className === "mt-4") {
    className =
      "bg-primeButtonColor hover:bg-black text-cartBGLight hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md  duration-300";
  }

  return (
    <>
      <button
        onClick={props.onButtonClick}
        disabled={props.btDisabled}
        className={className}
      >
        {props.loading && (
          <Loading className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
        )}
        {!props.loading ? props.btName : "Loading..."}
      </button>
    </>
  );
};
