import { useEffect } from "react";

const useSEO = ({ title, description, keywords = [], ogTitle, ogDescription, ogImage, ogUrl, ogType = 'website', canonicalUrl }) => {
  const setMetaTag = ({ attr, key, content }) => {
    if (content) {
      let element = document.querySelector(`meta[${attr}='${key}']`);
      if (!element) {
        element = document.createElement('meta');
        element.setAttribute(attr, key);
        document.head.appendChild(element);
      }
      element.setAttribute('content', content);
    }
  };

  const setCanonicalLink = (url) => {
    if (url) {
      let element = document.querySelector(`link[rel='canonical']`);
      if (!element) {
        element = document.createElement('link');
        element.setAttribute('rel', 'canonical');
        document.head.appendChild(element);
      }
      element.setAttribute('href', url);
    }
  };

  useEffect(() => {
    document.title = title;

    setMetaTag({ attr: 'name', key: 'description', content: description });
    setMetaTag({ attr: 'name', key: 'keywords', content: keywords.join(', ') });

    const host = window.location.origin;
    const defaultOgImage = `${host}/fox-commerce.jpg`;

    setMetaTag({ attr: 'property', key: 'og:title', content: ogTitle ?? title });
    setMetaTag({ attr: 'property', key: 'og:description', content: ogDescription ?? description });
    setMetaTag({ attr: 'property', key: 'og:image', content: ogImage ?? defaultOgImage });
    setMetaTag({ attr: 'property', key: 'og:url', content: ogUrl ?? window.location.href });
    setMetaTag({ attr: 'property', key: 'og:type', content: ogType ?? 'website' });

    setCanonicalLink(canonicalUrl ?? window.location.href);

    return () => {
      // Do cleanup if needed
    };
  }, [title, description, keywords, ogTitle, ogDescription, ogImage, ogUrl, ogType, canonicalUrl]);
};

export default useSEO;
