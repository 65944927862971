import React from "react";
import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import "slick-carousel/slick/slick.css";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import "./index.css";
import App from "./App";

// Sentry
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://6138d645ff7f8a4710c7a52224e897e4@o1053244.ingest.us.sentry.io/4507841801355264",
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Sentry.ErrorBoundary
    onError={(error, componentStack) => {
      // Log error to Sentry without displaying fallback UI
      Sentry.captureException(error);
      console.error("Error captured by Sentry:", error);
    }}
  >
    <Provider store={store}>
      {persistor ? (
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      ) : (
        <App />
      )}
    </Provider>
  </Sentry.ErrorBoundary>
);
