import React, { useState, useEffect } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { logoLight, vi, uk } from "../../assets/images";
import { LableItem } from "../../components/LabelLanguge/LabelItem";
import { useDispatch } from "react-redux";
import { toggleLanguage } from "../../redux/FoxSlice";
import Image from "../../components/designLayouts/Image";
import { GetContentLangByCode, useContentLangByCode } from "../../components/contentLanguage/ContentFactory";

import { AddressInput } from "../../components/Address/AddressInput";
import { LoadingButton } from "../../components/Load/LoadingButton";
import { GetCustomerInfomation, UpdateCustomer } from "../../services/AccountServices"; 
import { CONST_DATA } from "../../common.js/constData";
import { isNullOrUndefinedOrStringEmpty, ValidateVietnamesePhone } from "../../utils/CheckObjectHelper";
import { toast } from "react-toastify";

const EditProfile = () => {
  // ============= Initial State Start here =============
  
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  
  
  
  const [customer, setCustomer]= useState({});
  
  const [customerName, setCustomerName] = useState("");
  // ============= Initial State End here ===============
  // ============= Error Msg Start here =================
  const [errClientName, setErrClientName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errPhone, setErrPhone] = useState("");
  const [errAddress, setErrAddress] = useState("");
  // ============= Error Msg End here ===================
  const [successMsg, setSuccessMsg] = useState("");
  // ============= Event Handler Start here =============
  const [En, setEn] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [deliveryProvince, setDeliveryProvince] = useState("");
  const [deliveryDistrict, setDeliveryDistrict] = useState("");
  const [deliveryWard, setDeliveryWard] = useState(null);

  const [invoiceAddress, setInvoiceAddress] = useState("");
  const [invoiceProvince, setInvoiceProvince] = useState(null);
  const [invoiceDistrict, setInvoiceDistrict] = useState(null);
  const [invoiceWard, setInvoiceWard] = useState(null);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [deliveryAddressInit, setDeliveryAddressInit] = useState(null);
  const [invoiceAddressInit, setInvoiceAddressInit] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [isLoading, setIsLoading]= useState(true);
  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  let buttonConfirm = useContentLangByCode("P.EditProfile.Confirm");
  const handleChangeDeliveryWard = (option) => {
    setDeliveryWard(option);
  };
  const handleChangeDeliveryProvince = async (option) => {
    setDeliveryProvince(option);
  };
  const handleChangeDeliveryDistrict = async (option) => {
    setDeliveryDistrict(option);
  };

  const handleChangeInvoiceWard = (option) => {
    setInvoiceWard(option);
  };
  const handleChangeInvoiceProvince = async (option) => {
      setInvoiceProvince(option);
  };
  const handleChangeInvoiceDistrict = async (option) => {
      setInvoiceDistrict(option);
  };

  const handleChangeInvoiceAddress = async (address) => {
      setInvoiceAddress(address);
  };

  const handleLanguageToggle = () => {
    const newLanguage = En ? "vi" : "en";
    setEn(!En);
    dispatch(toggleLanguage(newLanguage));
  };
  useEffect(() => {
    const defaultLanguage = En ? "en" : "vi"; // Adjust if you have a specific default
    dispatch(toggleLanguage(defaultLanguage));
    const getCusttomer = async ()=>{
      let response =await GetCustomerInfomation();
      if(response.success){
        let cust = response.results[0]
          setCustomer(cust);
          
          if(!cust.card_holder_list[0].email.startsWith("Null_")){
            setEmail(cust.card_holder_list[0].email);
            setCustomerName(cust.customer_name);
          }
          
          setPhone(cust.card_holder_list[0].mobile_no)
          setDateOfBirth(cust.card_holder_list[0].birth_date);
          let deliAddr = cust.address_list?.find((o)=>o.address_type===CONST_DATA.DELIVERY_ADDRESS_TYPE);
          let invoiceAddr = cust.address_list?.find((o)=>o.address_type===CONST_DATA.INVOICE_ADDRESS_TYPE);
          if(!isNullOrUndefinedOrStringEmpty(deliAddr)){
              let deliInit = {
                address: deliAddr.address,
                province: deliAddr.province,
                district: deliAddr.district,
                ward: deliAddr.sub_district
              }
              setDeliveryAddress(deliAddr.address);
              setDeliveryAddressInit(deliInit);
              
          }
          if(!isNullOrUndefinedOrStringEmpty(invoiceAddr)){
            let invAddrInit = {
              address: invoiceAddr.address,
              province: invoiceAddr.province,
              district: invoiceAddr.district,
              ward: invoiceAddr.sub_district
            }
            setInvoiceAddress(invoiceAddr.address);
            setInvoiceAddressInit(invAddrInit);
          }
          setIsLoading(false);
      }
    }
    getCusttomer();
  }, [dispatch, En, isLoading]);
  
  const handleName = (e) => {
    setCustomerName(e.target.value);
    setErrClientName("");
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
    setErrPhone("");
  };
 
  const handleChangeDeliveryAddress = (address) => {
    
    setDeliveryAddress(address);
  };

  // ============= Event Handler End here ===============
  // ================= Email Validation start here =============
  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };
  // ================= Email Validation End here ===============

  const handleSubmit =async (e) => {
    e.preventDefault();
    let isValid =true;
    
      if (!customerName) {
        setErrClientName("Enter your name");
        isValid=false;
      }
      if (!email) {
        setErrEmail("Enter your email");
        isValid=false;
      } else {
        if (!EmailValidation(email)) {
          setErrEmail("Enter a Valid email");
          isValid=false;
        }
      }
      if (!phone) {
        setErrPhone("Enter your phone number");
        isValid=false;
      }

      // if(!ValidateVietnamesePhone(phone)){
      //   setErrPhone("phone is not correct format");
      //   isValid=false;
      // }

      if (!deliveryAddress) {
        setErrAddress("Enter your address");
        isValid=false;
      }
      if(!isValid){
        return;
      }
      let addressInvoice= `${invoiceAddress}, ${invoiceWard.label}, ${invoiceDistrict.label}, ${invoiceProvince.label}`

     
      let addressDeli= `${deliveryAddress}, ${deliveryWard.label}, ${deliveryDistrict.label}, ${deliveryProvince.label}`
      
      let props={
        
          customer_id: customer.customer_id,
          customer_name: customerName,
          customer_phone: phone,
          email: email,
          address: addressDeli,
          // "address": "123, Le Van Tho, Go Vap, TP HCM",
          address_delivery: addressDeli,
          address_invoice: addressInvoice,
          dateOfBirth: dateOfBirth,
          custAddresses: [
              {
                  address: deliveryAddress,
                  provinceCode: deliveryProvince.value.toString(),
                  districtCode: deliveryDistrict.value.toString(),
                  wardCode: deliveryWard.value.toString(),
                  addressType: CONST_DATA.DELIVERY_ADDRESS_TYPE
              },
              {
                  address: invoiceAddress,
                  provinceCode:invoiceProvince.value.toString(),
                  districtCode: invoiceDistrict.value.toString(),
                  wardCode: invoiceWard.value.toString(),
                  addressType: CONST_DATA.INVOICE_ADDRESS_TYPE
              }
          ]
        
      }
      
      let response  =await UpdateCustomer(props);
      if(!response.success){
        toast.error(response.message);
      }
      else {
        navigate('/home');
      }
     
      // ============== Getting the value ==============
      // if (
      //   customerName &&
      //   email &&
      //   EmailValidation(email) &&
      //   password &&
      //   password.length >= 6 &&
      //   deliveryAddress &&
      //   city
      // ) {
      //   setSuccessMsg(
      //     `Hello dear ${customerName}, Welcome you to BSM Admin panel. We received your Sign up request. We are processing to validate your access. Till then stay connected and additional assistance will be sent to you by your mail at ${email}`
      //   );
      //   setCustomerName("");
      //   setEmail("");
      //   setPhone("");
      //   setDeliveryAddress("");
      //   setCity("");
      // }
    
  };
  return (
    <div className="h-full flex pb-20 max-w-container mx-auto px-1 pt-6 md:pt-6">
      
      <div className="w-full  h-full flex flex-col justify-center">
        {successMsg ? (
          <div className="w-[500px]">
            <p className="w-full px-1 py-10 text-green-500 font-medium font-titleFont">
              {successMsg}
            </p>
            <Link to="/signin">
              <button
                className="w-full h-10 bg-primeColor rounded-md text-cartBGLight text-base font-titleFont font-semibold 
            tracking-wide hover:bg-black hover:text-white duration-300"
              >
                Sign in
              </button>
            </Link>
          </div>
        ) : (
          <form className="px-4  h-full flex items-center justify-center mt-14 overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor" >

            <div
              className="absolute top-4 right-4"
              onClick={handleLanguageToggle}
            >
              <Image
                className="w-6 h-6 object-cover cursor-pointer"
                imgSrc={En ? uk : vi}
              />
            </div>
            <div className="px-6 py-4 w-full h-full flex flex-col justify-start ">
              <h1 className="font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-2xl mdl:text-3xl mb-4">
                <LableItem code="P.EditProfile.Header"></LableItem>
              </h1>

              {/* First Section */}
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                {/* Client Name */}
                <div className="flex flex-col gap-1">
                  <p className="font-titleFont text-base font-semibold text-thirdTextColor">
                    <LableItem code="P.EditProfile.FullName"></LableItem>
                  </p>
                  <input
                    onChange={handleName}
                    value={customerName}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-bsmBoder outline-none"
                    type="text"
                    placeholder={GetContentLangByCode(
                      "P.EditProfile.FullName.Plh"
                    )}
                  />
                  {errClientName && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errClientName}
                    </p>
                  )}
                </div>

                {/* Email */}
                <div className="flex flex-col gap-1">
                  <p className="font-titleFont text-base font-semibold text-thirdTextColor">
                    <LableItem code="P.EditProfile.Email"></LableItem>
                  </p>
                  <input
                    onChange={handleEmail}
                    value={email}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-bsmBoder outline-none"
                    type="email"
                    placeholder={GetContentLangByCode(
                      "P.EditProfile.Email.Plh"
                    )}
                  />
                  {errEmail && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errEmail}
                    </p>
                  )}
                </div>

                {/* Phone Number */}
                <div className="flex flex-col gap-1">
                  <p className="font-titleFont text-base font-semibold text-thirdTextColor">
                    <LableItem code="P.EditProfile.Phone"></LableItem>
                  </p>
                  <input
                    onChange={handlePhone}
                    value={phone}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-bsmBoder outline-none bg-bsmBG"
                    type="text"
                    readOnly={true}
                    placeholder={GetContentLangByCode("P.Signup.Phone.Plh")}
                  />
                  {errPhone && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errPhone}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-1">
                  <p className="font-titleFont text-base font-semibold text-thirdTextColor">
                    <LableItem code="P.EditProfile.BirthDate"></LableItem>
                  </p>
                  <input
                  onChange={(e) => {
                    setDateOfBirth(e.target.value);
                    
                  }}
                  value={dateOfBirth}
                  className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-bsmBoder outline-none"
                  type="date"
                />
                {/* {errDeliveryDate && (
                  <p className="text-xs text-red-500 mt-1">
                    <span className="italic mr-1">!</span>
                    {errDeliveryDate}
                  </p>
                )} */}
                </div>
              
              </div>

              {/* Second Section - Address Inputs */}
             
              <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mt-6">

              {!isLoading ? (
                <>
                 <AddressInput
                  isHorizontal={true}
                  handleChangeDistrict={handleChangeDeliveryDistrict}
                  handleChangeWard={handleChangeDeliveryWard}
                  handleChangeProvince={handleChangeDeliveryProvince}
                  handleAddressChange={handleChangeDeliveryAddress}
                  addressInit= {deliveryAddressInit}
                  sectionName="Địa chỉ giao hàng"
                />
                </>
              ) : null}
                

               
              </div>
              <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mt-6">
              {!isLoading ? (
                <>
                <AddressInput
                  isHorizontal={true}
                  handleChangeDistrict={handleChangeInvoiceDistrict}
                  handleChangeWard={handleChangeInvoiceWard}
                  handleChangeProvince={handleChangeInvoiceProvince}
                  handleAddressChange={handleChangeInvoiceAddress}
                  addressInit= {invoiceAddressInit}
                  sectionName="Địa chỉ hóa đơn"
                  active={false}
                />
                </>
              ) : null}
             
              </div>

              {/* Checkbox and Submit */}
              <div className="flex  flex-row mt-6 gap-4 justify-end">
                
                <LoadingButton 
                  btName={buttonConfirm}
                  onButtonClick= {handleSubmit}
                  className="bg-primeColor hover:bg-black text-cartBGLight hover:text-white cursor-pointer w-1/4 text-base font-medium h-10 rounded-md  duration-300"
                  >
                     <LableItem code="P.EditProfile.Confirm"></LableItem>
                </LoadingButton>
                
              </div>
              
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default EditProfile;
