import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { resetCart } from "../../redux/FoxSlice";
import { emptyCart } from "../../assets/images/index";
import ItemCardReadOnly from "../../pages/Cart/ItemCardReadOnly";
import { LableItem } from "../LabelLanguge/LabelItem";
import CheckoutModal from "../Checkout/CheckoutModal";
import { LoadingButton } from "../Load/LoadingButton";
import { updateOrderDelivery } from "../../redux/FoxSlice";
import CurrencyComponent from "../Currency/CurrencyFormat";
import { useContentLangByCode } from "../contentLanguage/ContentFactory";
import { CreateOrder, GetDeliveryFee, ReCalcPriceExVatItems } from "../../services/OrderServices";
import { toast } from "react-toastify";
import { RemoveCartItemsAnyway } from "../../services/CartServices";
import DeliveryFeeFactory from "../../services/DeliveryServices";
import { CONST_DATA } from "../../common.js/constData";
//import { useContentLangByCode } from "../contentLanguage/ContentFactory";
const OrderConfirm = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.FoxReducer.productsSelected);
  const [totalAmt, setTotalAmt] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [fullName, setFullName] = useState("");
  const [address, setAddress] = useState("");
  const [note, setNote] = useState("");
  const [city, setCity] = useState("");
  const [showOrderSummary, setShowOrderSummary] = useState(true); // Default to show order summary
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
  const [isDeliveryChange, setIsDeliveryChange] = useState(false);
  const [deliExpectation, setDeliExpectation] = useState("");
  const [shippingFeeItem, setShippingFeeItem] = useState(null);
  const [deliFeeResult , setDeliFeeResult] = useState(null);
  //const [shippingFeeCalcValue,setShippingFeeCalcValue ] =useState(0);
  let buttonCreate = useContentLangByCode("C.OrderConfirm.CreateOrder");

  const checkOutInformation = useSelector(
    (state) => state.FoxReducer.deliveryInfomation
  );

  const userInfo = useSelector((state) => state.FoxReducer.userInfo);
  const customer = userInfo.customers[0];
  const selectedStore = useSelector(
    (state) => state.FoxReducer.useSelectedStore
  );

  useEffect(() => {
    let price = 0;

    products.forEach((item) => {
      price += item.price * item.quantity;
    });
    setTotalAmt(price);
  }, [products, isDeliveryChange]);

  useEffect(() => {
    const getAndSetShippingFee = async () => {
      let oderInfo = {
        order_items: products,
        delivery_info: {
          address: checkOutInformation.address,
          city: checkOutInformation.city,
          district: checkOutInformation.district,
          sub_district: checkOutInformation.ward,
        },
      };
      const deliFee = new DeliveryFeeFactory(oderInfo);
      if(CONST_DATA.ADD_BASE_DELI_FEE){
        await deliFee.addBaseFee();
      }
      if(CONST_DATA.ADD_THRESHOLD_DELI_FEE){
        await deliFee.addThresholdFee();
      }
      
      
      setDeliFeeResult(deliFee);

      setShippingCharge(deliFee.calcFee().shipingValue);
      
    };
    getAndSetShippingFee();

    setEmail(checkOutInformation.email);
    setFullName(checkOutInformation.fullName);
    let fullAddress = `${checkOutInformation.address}, ${checkOutInformation.ward.label}
    , ${checkOutInformation.district.label}, ${checkOutInformation.city.label}`;
    setAddress(fullAddress);
    setPhone(checkOutInformation.phone);
    setNote(checkOutInformation.note);
    setCity(checkOutInformation.city.label);
    let deliExp = `Ngày ${checkOutInformation.delivery_date} từ  ${checkOutInformation.delivery_time_from} đến ${checkOutInformation.delivery_time_to}`;
    setDeliExpectation(deliExp);
  }, [totalAmt, isDeliveryChange]);
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowOrderSummary(true);
  };
  const [loading, setLoading] = useState(false);
  const handleComplete = async (e) => {
    setLoading(true);

    // let total_price_in_vat = products.reduce(
    //   (total, product) => total + product.price * product.quantity,
    //   0
    // );

    let total_discount_ex_vat = 0;
    let delivery_address = `${checkOutInformation.address},${checkOutInformation.ward.label}, ${checkOutInformation.district.label}, ${checkOutInformation.city.label} `;

    let seq = 0;
    var order_items = products.map((p) => {
      seq += 1;
      return {
        item_seq: seq,
        article_code: p.product_code,
        order_qty: p.quantity,
        //remark: "",
        //price_code: "72",
        //discount_code: "",
        //discount_event: "",
        fulfillment_price: p.retail_price,
        vat_rate: p.vat_rate,
        vat_code: p.vat_code.toString(),
        unit_price_ex_vat: p.retail_price_ex_vat,
        // item_discount_ex_vat:0,
        // p.retail_price_ex_vat,
        //qty_discount: 3,
        total_price_ex_vat: p.retail_price_ex_vat * p.quantity,
        total_price_in_vat: p.retail_price * p.quantity,
        vat_after_disc:
          p.retail_price * p.quantity - p.retail_price_ex_vat * p.quantity,
        total_discount_ex_vat: total_discount_ex_vat,
        mmunit:p.unit_vn,
        mmunit_en:p.unit_en,
        mmunit_code:p.mmunit_code
        //(p.price / p.vat_rate) * p.quantity,
      };
    });
    
    let oderInfo = {
      order_items: products,
      delivery_info: {
        address: checkOutInformation.address,
        city: checkOutInformation.city,
        district: checkOutInformation.district,
        sub_district: checkOutInformation.ward,
      },
    };
    
    
    let shippingFeeIt = {
      item_seq: order_items.length + 1,
      article_code: deliFeeResult.charge_item,
      order_qty: 1,
      fulfillment_price: shippingCharge,
      vat_rate: deliFeeResult.vatRate,
      vat_code: deliFeeResult.vatCode.toString(),
      unit_price_ex_vat:
        shippingCharge -
        (shippingCharge * deliFeeResult.vatRate) / 100,
      // item_discount_ex_vat:0,
      total_price_ex_vat:
        shippingCharge -
        (shippingCharge * deliFeeResult.vatRate) / 100,
      total_price_in_vat: shippingCharge,
      vat_after_disc:
        (shippingCharge * deliFeeResult.vatRate) / 100,
    };
    if(shippingCharge>1){
      order_items.push(shippingFeeIt);
    }
    
    
    let newOrderItems = ReCalcPriceExVatItems(order_items);
    
    let total_price_in_vat = newOrderItems.reduce(
      (total, product) => total + product.total_price_in_vat,
      0
    );
    let total_price_ex_vat = newOrderItems.reduce(
      (total, product) => total + product.total_price_ex_vat,
      0
    );
    let total_vat = total_price_in_vat - total_price_ex_vat;

    const orderInfomation = {
      created_by: customer.customer_no,
      //data.customer_no,

      customer_no: customer.customer_no, //data.customer_no,
      card_no: customer.main_card_holder,
      customer_name: customer.customer_name, //data.customer_name,
      vat_indicator: "2",
      representative: customer.customer_name, //data.customer_name,
      sale_order_source: "1",
      store_id: deliFeeResult.storeCode, // data.store_id,

      invoice_address: delivery_address, // data.invoice_address,

      delivery_address: delivery_address, // data.delivery_address,
      delevery_fee: shippingCharge,
      delivery_email: checkOutInformation.email, // data.email,
      delivery_date: checkOutInformation.delivery_date,
      delivery_time_from: checkOutInformation.delivery_time_from,
      delivery_time_to: checkOutInformation.delivery_time_to,

      total_price_ex_vat: total_price_ex_vat,
      total_discount_ex_vat: total_discount_ex_vat,
      total_vat: total_vat,
      grand_total: total_price_ex_vat + total_vat,
      receiver_name: checkOutInformation.fullName, // data.receiver_name,
      receiver_phone: checkOutInformation.phone, // data.receiver_phone,
      delivery_comment: checkOutInformation.note,
      delivery_code: {
        province_code: checkOutInformation.city.value.toString(),
        district_code: checkOutInformation.district.value.toString(),
        sub_district_code: checkOutInformation.ward.value.toString(),
      },
    };
    orderInfomation.order_items = newOrderItems;
    
    var product_codes = orderInfomation.order_items.map((item)=>item.article_code);
    try {
      let data = await CreateOrder(orderInfomation);
      if (data.success) {
        await RemoveCartItemsAnyway(orderInfomation.customer_no,product_codes );
        navigate("/order-completed", {
          state: { order_no: data.results[0].order_id },
        });
      } else {
        
        let messages = data.errors.map((item) => {
          return item.message;
        });
        let messageString = `${data.message}: ${messages.join(",\n ")}`;
        toast.error(messageString, { autoClose: 10000 });
      }
      setLoading(false);
    } catch (error) {
      toast.error(error);
      
      setLoading(false);
    }

    //dispatch(resetCart());
  };
  const handleConfirmOrder = (e) => {
    //e.preventDefault();

    // Handle order confirmation logic here
    console.log({
      email,
      phone,
      fullName,
      address,
      note,
      products,
      totalAmt,
      shippingCharge,
    });
    // Reset cart and form after order confirmation
    // dispatch(resetCart());
    // setEmail("");
    // setPhone("");
    // setFullName("");
    // setAddress("");
    // setNote("");

    let checkOutInformation = {
      fullName: fullName,
      phone: phone,
      address: address,
      city: city,
      //country:country,
      email: email,
      //zip:zip,
      note: note,
    };
    dispatch(updateOrderDelivery(checkOutInformation));

    setIsDeliveryChange(true);
    closeModal();
    //setShowOrderSummary(false);
    // Redirect or navigate to checkout process
    // history.push("/checkout"); // Replace with your checkout route
  };

  const handleEdit = () => {
    //setIsModalOpen(true); // Open modal for editing checkout information
    navigate("/checkout");
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close modal
  };

  return (
    <div className="max-w-container mx-auto px-4 pt-5 md:pt-0">
      <Breadcrumbs title={useContentLangByCode("C.OrderConfirm.Title")} />
      {products.length > 0 ? (
        <div className="pb-20">
          <div className="w-full h-20 bg-[#F5F7F7] text-primeColor hidden lgl:grid grid-cols-5 place-content-center px-6 text-lg font-titleFont font-semibold">
            <h2 className="col-span-2">
              <LableItem code="C.Multi.ProductName"></LableItem>
            </h2>
            <h2>
              <LableItem code="C.Multi.Price"></LableItem>
            </h2>
            <h2>
              <LableItem code="C.Multi.Quantity"></LableItem>
            </h2>
            <h2>
              <LableItem code="C.Multi.SubTotal"></LableItem>
            </h2>
          </div>
          <div className="mt-5">
            {products.map((item) => (
              <div key={item.product_code}>
                <ItemCardReadOnly item={item} />
              </div>
            ))}
          </div>

          {showOrderSummary && (
            <div className="mt-8 space-y-6">
              {/* User Information */}
              <div className="border border-bsmBG rounded-md shadow-sm p-4">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold">
                    <LableItem code="C.OrderConfirm.UserInformation"></LableItem>
                  </h2>
                  <span
                    className="text-blue-500 cursor-pointer"
                    onClick={handleEdit}
                  >
                    (Edit)
                  </span>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderConfirm.FullName"></LableItem>:
                      </strong>{" "}
                      {fullName}
                    </p>
                    <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderConfirm.Email"></LableItem>:
                      </strong>{" "}
                      {email}
                    </p>
                    <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderConfirm.Phone"></LableItem>:
                      </strong>{" "}
                      {phone}
                    </p>
                  </div>
                  <div>
                    <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderConfirm.Address"></LableItem>:
                      </strong>{" "}
                      <i> {address} </i>
                    </p>
                    <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderConfirm.DeliExpectation"></LableItem>
                        :
                      </strong>{" "}
                      {deliExpectation}
                    </p>
                    <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderConfirm.Note"></LableItem>:
                      </strong>{" "}
                      {note}
                    </p>
                  </div>
                </div>
              </div>

              {/* Pricing */}
              <div className="border border-bsmBG rounded-md shadow-sm p-4">
                <h2 className="text-xl font-semibold mb-4">
                  <LableItem code="C.OrderConfirm.Pricing"></LableItem>
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderConfirm.TotalAmount"></LableItem>
                        :
                      </strong>{" "}
                      <CurrencyComponent price={totalAmt}></CurrencyComponent>
                    </p>
                    
                  <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderConfirm.ShippingCharge"></LableItem>
                        :
                      </strong>{" "}
                      <CurrencyComponent
                        price={shippingCharge}
                      ></CurrencyComponent>
                    </p>
                    <p className="text-lg">
                      <strong>
                        <LableItem code="C.OrderConfirm.GrandTotal"></LableItem>
                        :
                      </strong>{" "}
                      <CurrencyComponent
                        price={totalAmt + shippingCharge}
                      ></CurrencyComponent>
                    </p>
                </div>
              </div>
              <div className="flex justify-end items-end h-full">
                <LoadingButton
                  onButtonClick={handleComplete}
                  loading={loading}
                  btName={buttonCreate}
                  className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300"
                />
              </div>
            </div>
          )}

          <CheckoutModal
            isOpen={isModalOpen}
            onClose={closeModal}
            fullName={fullName}
            setFullName={setFullName}
            email={email}
            setEmail={setEmail}
            phone={phone}
            setPhone={setPhone}
            address={address}
            setAddress={setAddress}
            note={note}
            setNote={setNote}
            city={city}
            setCity={setCity}
            handleConfirmOrder={handleConfirmOrder}
          />
        </div>
      ) : (
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20"
        >
          <div>
            <img
              className="w-80 rounded-lg p-4 mx-auto"
              src={emptyCart}
              alt="emptyCart"
            />
          </div>
          <div className="max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
            <h1 className="font-titleFont text-xl font-bold uppercase">
              Your Cart feels lonely.
            </h1>
            <p className="text-sm text-center px-10 -mt-2">
              Your Shopping cart lives to serve. Give it purpose - fill it with
              books, electronics, videos, etc. and make it happy.
            </p>
            <Link
              to="/shop"
              className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300 flex justify-center items-center rounded-md font-semibold text-sm mt-4"
            >
              Continue Shopping
            </Link>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default OrderConfirm;
