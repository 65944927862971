import React from "react";
import CurrencyComponent from "../../components/Currency/CurrencyFormat";



const ItemCardReadOnly = ({ item }) => {
  
  return (
    <div className="w-full grid grid-cols-5 mb-4 border py-2">
      <div className="flex col-span-5 mdl:col-span-2 items-center gap-4 ml-4">
        
        <img className="w-32 h-32" src={item.image} alt="productImage" />
        <h1 className="font-titleFont font-semibold">{item.name}</h1>
      </div>
      <div className="col-span-5 mdl:col-span-3 flex items-center justify-between py-4 mdl:py-0 px-4 mdl:px-0 gap-6 mdl:gap-0">
        <div className="flex w-1/3 items-center text-lg font-semibold">
        <CurrencyComponent price={item.price}></CurrencyComponent>/{item.unit_name}
          
        </div>
        <div className="w-1/3 flex items-center gap-6 text-lg">
          
          <p>{item.quantity}</p>
          
        </div>
        <div className="w-1/3 flex items-center font-titleFont font-bold text-lg">
          <p>
          <CurrencyComponent price={item.quantity * item.price}></CurrencyComponent>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ItemCardReadOnly;
