import React, { useEffect, useRef, useState } from "react";

const ImageV2 = ({ imgSrc, className, width, height }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  const srcToDisplay = isVisible && imgSrc ? imgSrc : `${window.location.origin}/sampleImage.jpg`;

  return (
    <img
      ref={imgRef}
      style={{"mix-blend-mode":"multiply"}} 
      className={className}
      src={srcToDisplay}
      alt={imgSrc}
      width={width ?? "auto"}
      height={height ?? "auto"}      
    />
  );
};

export default ImageV2;
