import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

import { emptyCart } from "../../assets/images/index";

import { LableItem } from "../../components/LabelLanguge/LabelItem";
import CurrencyComponent from "../../components/Currency/CurrencyFormat";
import { GetCurrentCart, RemoveCartItem, UpdateCardItem } from "../../services/CartServices";
import { FoxCartItem } from "../../common.js/transformation";
import { ImCross } from "react-icons/im";
import { isNullOrUndefinedOrStringEmpty } from "../../utils/CheckObjectHelper";
import {
  updateCartItemsFromSever,
  updateItemsUserSelected,
  updateOrderDelivery,
} from "../../redux/FoxSlice";
import NumberInput from "../../components/NumberHelper/NumberInput";
import { useContentLangByCode } from "../../components/contentLanguage/ContentFactory";
import _, { forEach } from "lodash";
import { GetCustomerInfomation } from "../../services/AccountServices";
import { CONST_DATA } from "../../common.js/constData";
import { getLocationByWardCode } from "../../services/MasterDataServices";
import { DateToString } from "../../utils/DatetimeHelper";
import { toast } from "react-toastify";
import { DeliveryTimeSlots } from "../../services/OrderServices";
const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const isEn = useSelector((state) => state.FoxReducer.En);
  const [totalAmt, setTotalAmt] = useState(0);
  const [shippingCharge, setShippingCharge] = useState("");
  const customerNo = useSelector((state) => state.FoxReducer.cart.customer_no);
  const [customerInfo, setCustomerInfo] = useState(null);
  const deliveryInfo = useSelector(
    (state) => state.FoxReducer.deliveryInfomation
  );
 const cart = useSelector(
  (state) => state.FoxReducer.cart
);
  const [defaultCheckedItems, setDefaultCheckedItems] = useState([]);
  const [checkedItems, setCheckedItems] = useState(defaultCheckedItems);
  const [isSelectedAll, setIsSelectedAll] = useState(true);
  const handleCheckout = (e) => {
    let itemsSelected = products.filter((item) => item.isSelected);
    if (itemsSelected.length === 0) {
      
      toast.error("phải chọn ít nhất 1 sản phẩm");
      return;
    } else {
      let notvalidItem = itemsSelected.find((o) => o.quantity === 0);
      if (notvalidItem) {
        toast.error("Số lượng phải lớn hơn 0");
        return;
      }
    }
    dispatch(updateItemsUserSelected(itemsSelected));
    if (!isEnoughDeliveryInfo()) {
      navigate("/checkout");
    } else navigate("/order-confirmation-v2");
    // navigate("/checkout");
  };
  const handleCheckboxChange = (product_code) => (e) => {
    try {
      let changes = (products.find(
        (item) => item.product_code === product_code
      ).isSelected = e.target.checked);

      setProducts(products);

      setCheckedItems((prevState) => ({
        ...prevState,
        [product_code]: e.target.checked,
      }));
      let price = calcTotalAmount(products);
      setTotalAmt(price);
    } catch (error) {
      console.log(error);
    }
  };
  const isEnoughDeliveryInfo = () => {
    if (isNullOrUndefinedOrStringEmpty(deliveryInfo)) return false;

    const requiredFields = [
      "fullName",
      "phone",
      "address",
      "city",
      "district",
      "ward",
    ];
    return requiredFields.every(
      (field) => !isNullOrUndefinedOrStringEmpty(deliveryInfo[field])
    );
  };
  const handleQuantityChange =async (value, product_code) => {
    let item = products.find((item) => item.product_code === product_code);
    item.quantity = +value;
    
    await UpdateCartItemOnServer(item);
    setProducts(products);
    let price = calcTotalAmount(products);
    setTotalAmt(price);
  };
  
  const UpdateCartItemOnServer = async(props) =>{
    try{
      let cartItem = {
        product_code: props.product_code,
        quantity: props.quantity,
        price: props.retail_price,
        channel_code: CONST_DATA.CHANNEL,
        discount: 0,
        discount_info: null,
        vat_rate: props.vat_rate,
        vat_code: props.vat_code,
        vat_amount: props.price - props.price_ex_vat,
        total: props.quantity * props.price,
        cart_id: cart.cart_id,
        customer_no: customerNo,
        
      };
       await UpdateCardItem(cartItem);
    }
    catch(err){
        console.log(err);
    }
    
    
  }
  const handleDecreaseQuantity = (p) => (e) => {
    let item = products.find((item) => item.product_code === p.product_code);

    if (item.quantity > 1) {
      setQuantityChange(p.product_code, -1);
    }
  };
  const setQuantityChange =async (product_code, changedValue) => {
    let item = products.find((item) => item.product_code === product_code);
    if (!item.isSelected) return;
    item.quantity = item.quantity + changedValue;
    await UpdateCartItemOnServer(item);
    setProducts(products);
    let price = calcTotalAmount(products);
    setTotalAmt(price);
  };
  const handleIncreaseQuantity = (p) => (e) => {
    setQuantityChange(p.product_code, 1);
  };
  const removeFromCart = (cartItems, product_code) => {
    return cartItems.filter((item) => item.product_code !== product_code);
  };
  const handleCheckOrUncheckAll = (e) => {
    try {
      e?.target?.checked ? setIsSelectedAll(true) : setIsSelectedAll(false);
      let changedProducts = products.map((item) => {
        return { ...item, isSelected: e.target.checked };
      });
      setProducts(changedProducts);
      let price = calcTotalAmount(changedProducts);
      setTotalAmt(price);
    } catch (error) {
      console.log(error);
    }
  };
  const handleRemoveCartItem = (product_code) => async (e) => {
    try {
      const response = await RemoveCartItem(customerNo, product_code);
      if (response.success) {
        let newItems = removeFromCart(products, product_code);
        setProducts(newItems);
        let price = calcTotalAmount(newItems);
        setTotalAmt(price);
        let copyObject = _.cloneDeep(newItems);
        dispatch(updateCartItemsFromSever(copyObject));
      }
    } catch (error) {}
  };
  useEffect(() => {
    let price = 0;
    const getCartByCustomer = async () => {
      try {
        let response = await GetCurrentCart(
          customerNo,
          CONST_DATA.STORE_DEFAULT
        );
        
        if (response.success) {
          let listProducts = response.results[0].CartItems.map((item) => {
            return FoxCartItem(item, isEn);
          });
          
          setProducts(listProducts);
          let checkedItems = listProducts.reduce((acc, item) => {
            acc[item.product_code.toString()] = true; // Set to true for default checked, false otherwise
            return acc;
          }, {});

          setDefaultCheckedItems(checkedItems);
          price = calcTotalAmount(listProducts);
          let copyObject = _.cloneDeep(listProducts);
          setTotalAmt(price);

          dispatch(updateCartItemsFromSever(copyObject));
        }
      } catch (error) {
        console.log(error);
      }
    };
    const getCustInfo = async () => {
      let custResponse = await GetCustomerInfomation();
      if (custResponse.success) {
        const [custInfo] = custResponse.results;
        setCustomerInfo(custInfo);
        let deliAddress = custInfo.address_list?.find(
          (o) => o.address_type === CONST_DATA.DELIVERY_ADDRESS_TYPE
        );
        let location;
        if (deliAddress) {
          location = await getLocationByWardCode(deliAddress.sub_district);
        }

        let deliDate = new Date(new Date().setDate(new Date().getDate() + 1));
        let deliSlots =await DeliveryTimeSlots();
        let defaultSlot = deliSlots.find((o)=>o.slot_code ===CONST_DATA.DELI_SLOT_DEFAUL)
        let delieryInfo = {
          fullName: custInfo.customer_name,
          phone: custInfo.card_holder_list[0].mobile_no,
          address: deliAddress?.address,
          city: location
            ? {
                value: deliAddress.province,
                label: location.province_short_name,
              }
            : null,
          district: location
            ? {
                value: deliAddress.district,
                label: location.district_short_name,
              }
            : null,
          ward: location
            ? {
                value: deliAddress.sub_district,
                label: location.sub_district_short_name,
              }
            : null,
          email: custInfo.card_holder_list[0].email,
          note: "",
          delivery_date: DateToString(deliDate),
          delivery_time_from: defaultSlot?.from_time??"08:00",
          delivery_time_to:defaultSlot?.to_time?? "09:00",
        };

        dispatch(updateOrderDelivery(delieryInfo));
      }
    };
    getCartByCustomer();
    getCustInfo();
  }, []);
  // useEffect(() => {}, [totalAmt, products]);
  function calcTotalAmount(items) {
    let total = 0;
    try {
      items.map((item) => {
        total += item.isSelected ? item.price * item.quantity : 0;
        return total;
      });
    } catch (error) {
      console.log(error);
    }

    return total;
  }
  return (
    <>
      <div className="max-w-container mx-auto px-4 pt-6 md:pt-6">
        <Breadcrumbs title={useContentLangByCode("P.Card.Title")} />
        {products.length > 0 ? (
          <div className="pb-20">
            {/* Table Header */}
            <div className="w-full h-20 bg-[#F5F7F7] text-primeColor hidden lgl:grid grid-cols-5 place-content-center px-6 text-lg font-titleFont font-semibold">
              <div className="flex col-span-2 items-center gap-4 ml-1">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-primeColor border-bsmBG rounded focus:ring-primeColor"
                  checked={isSelectedAll}
                  onChange={handleCheckOrUncheckAll}
                />
                <h2>
                  <LableItem code="P.Cart.Product" />
                </h2>
              </div>
              <h2>
                <LableItem code="P.Cart.Header.Price" />
              </h2>
              <h2>
                <LableItem code="P.Cart.Header.Quantity" />
              </h2>
              <h2>
                <LableItem code="P.Cart.Header.SubTotal" />
              </h2>
              {/* <div className="flex col-span-1 items-center gap-4 ml-4">
                <h2><LableItem code="P.Cart.Header.Price" /></h2>
              </div>
              <div className="flex col-span-1 items-center gap-4 ml-4">
                <h2><LableItem code="P.Cart.Header.Quantity" /></h2>
              </div>
              <div className="flex col-span-1 items-center gap-4 ml-4">
                <h2><LableItem code="P.Cart.Header.Unit" /></h2>
              </div>
              <div className="flex col-span-1 items-center gap-4 ml-4">
                <h2><LableItem code="P.Cart.Header.SubTotal" /></h2>
              </div> */}
            </div>

            {/* Products */}
            <div className="mt-5">
              {products.map((item) => (
                <div key={item.product_code}>
                  <div className="w-full grid grid-cols-5 mb-4 border py-2 relative">
                    <div className="flex col-span-5 mdl:col-span-2 items-center gap-4 ml-4">
                      <input
                        type="checkbox"
                        className="flex-shrink-0 form-checkbox h-5 w-5 text-primeColor border-bsmBG rounded focus:ring-primeColor"
                        checked={item.isSelected}
                        onChange={handleCheckboxChange(item.product_code)}
                      />

                      <img
                        className="w-32 h-32"
                        src={item.image}
                        alt="productImage"
                      ></img>
                      <h1 className="font-titleFont font-semibold">
                        {item.name}/{item.unit_name}
                      </h1>
                    </div>
                    <div className="col-span-5 mdl:col-span-3 flex items-center justify-between py-4 mdl:py-0 px-4 mdl:px-0 gap-4 mdl:gap-0">
                      <div className="flex w-1/3 items-center text-lg md:text-base sm:text-sm font-semibold">
                        <CurrencyComponent price={item.price} />
                      </div>
                      <div className="w-1/3 flex items-center gap-6 text-lg">
                        <span
                          onClick={handleDecreaseQuantity({
                            product_code: item.product_code,
                          })}
                          class="w-6 h-6 bg-bsmBG text-2xl flex items-center justify-center hover:bg-bsmBG cursor-pointer duration-300 border-[1px] border-bsmBG hover:border-bsmBG"
                        >
                          -
                        </span>
                        <NumberInput
                          value={item.quantity}
                          key_code={item.product_code}
                          onChange={handleQuantityChange}
                          unit_code={item.unit_code}
                        />
                        <span
                          onClick={handleIncreaseQuantity({
                            product_code: item.product_code,
                          })}
                          class="w-6 h-6 bg-bsmBG text-2xl flex items-center justify-center hover:bg-bsmBG cursor-pointer duration-300 border-[1px] border-bsmBG hover:border-bsmBG"
                        >
                          +
                        </span>
                      </div>
                      <div className="w-1/3 flex items-center font-titleFont font-bold text-lg md:text-base sm:text-sm">
                        <p>
                          <CurrencyComponent
                            price={item.quantity * item.price}
                          />
                        </p>
                      </div>
                    </div>
                    <div
                      className="aa de dn absolute right-2 md:right-2 text-[20px] font-bold text-red-500"
                      onClick={handleRemoveCartItem(item.product_code)}
                    >
                      <button type="button" class="fs ly aqw axv bla">
                        <span className="t">X</span>
                      </button>
                    </div>
                  </div>
                </div>
                // <div
                //   className="grid grid-cols-1 md:grid-cols-7 gap-4 border-b p-2"
                //   key={item.product_code}
                // >
                //   <div className="flex flex-col md:flex-row md:col-span-3 items-center gap-4 ml-4">
                //     <input
                //       type="checkbox"
                //       className="form-checkbox h-5 w-5 text-primeColor border-bsmBG rounded focus:ring-primeColor"
                //       checked={item.isSelected}
                //       onChange={handleCheckboxChange(item.product_code)}
                //     />
                //     <img
                //       className="w-32 h-32"
                //       src={item.image}
                //       alt="productImage"
                //     />
                //     <h1 className="font-titleFont font-semibold text-sm md:text-base">
                //       {item.name}
                //     </h1>
                //   </div>
                //   <div className="flex flex-col items-start md:flex-row md:items-center gap-4 ml-4">
                //     <CurrencyComponent price={item.price} />
                //   </div>
                //   <div className="flex flex-col items-start md:flex-row md:items-center gap-4 ml-4">
                //     <span
                //       onClick={handleDecreaseQuantity({ product_code: item.product_code })}
                //       className="w-6 h-6 bg-bsmBG text-lg flex items-center justify-center hover:bg-bsmBG cursor-pointer duration-300 border-[1px] border-bsmBG hover:border-bsmBG"
                //     >
                //       -
                //     </span>
                //     <NumberInput
                //       value={item.quantity}
                //       key_code={item.product_code}
                //       onChange={handleQuantityChange}
                //       unit_code={item.unit_code}
                //     />
                //     <span
                //       onClick={handleIncreaseQuantity({ product_code: item.product_code })}
                //       className="w-6 h-6 bg-bsmBG text-lg flex items-center justify-center hover:bg-bsmBG cursor-pointer duration-300 border-[1px] border-bsmBG hover:border-bsmBG mx-2"
                //     >
                //       +
                //     </span>
                //   </div>
                //   <div className="flex flex-col items-start md:flex-row md:items-center gap-4 ml-4">
                //     <p className="text-sm">{item.unit_name}</p>
                //   </div>
                //   <div className="flex flex-col items-start md:flex-row md:items-center gap-4 ml-4 relative">
                //     <p>
                //       <CurrencyComponent price={item.quantity * item.price} />
                //     </p>
                //     <div className="absolute right-4 top-1">
                //       <ImCross
                //         onClick={handleRemoveCartItem(item.product_code)}
                //         className="text-red-700 hover:text-red-500 duration-300 cursor-pointer"
                //       />
                //     </div>
                //   </div>
                // </div>
              ))}
            </div>

            {/* Cart Total and Checkout */}
            <div className="max-w-7xl gap-4 flex flex-col md:flex-row justify-end mt-4">
              <div className="w-full md:w-96 flex flex-col gap-4">
                <h1 className="text-xl md:text-2xl font-semibold text-right">
                  <LableItem code="P.Cart.CartTotal" />
                </h1>
                <div>
                  <p className="flex items-center justify-between border-[1px] border-bsmBoder py-1.5 text-lg px-4 font-medium">
                    <LableItem code="P.Cart.Total" />
                    <span className="font-bold tracking-wide text-lg font-titleFont">
                      <CurrencyComponent price={totalAmt} />
                    </span>
                  </p>
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={handleCheckout}
                    className="w-full md:w-52 h-10 bg-primeButtonColor text-white hover:bg-black duration-300"
                  >
                    <LableItem code="P.Cart.ProceedToCheckout" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <motion.div
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.4 }}
            className="flex flex-col md:flex-row justify-center items-center gap-4 pb-20"
          >
            <div>
              <img
                className="w-80 rounded-lg p-4 mx-auto"
                src={emptyCart}
                alt="emptyCart"
              />
            </div>
            <div className="max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
              <h1 className="font-titleFont text-xl font-bold uppercase">
                <LableItem code="P.Cart.P1" />
              </h1>
              <p className="text-sm text-center px-10 -mt-2">
                <LableItem code="P.Cart.P2" />
              </p>
              <Link to="/shop">
                <button className="bg-primeColor rounded-md cursor-pointer hover:bg-black active:bg-black px-8 py-2 font-titleFont font-semibold text-lg text-cartBGLight hover:text-white duration-300">
                  <LableItem code="P.Cart.ContinueShopping" />
                </button>
              </Link>
            </div>
          </motion.div>
        )}
      </div>
    </>
  );
};

export default Cart;
