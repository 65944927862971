import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

import {orderSuccess} from "../../assets/images/index";

import { LableItem } from "../LabelLanguge/LabelItem";

import { useContentLangByCode } from "../contentLanguage/ContentFactory";

const OrderCompleted = () => {
  const location = useLocation();
  const order_no = location.state?.order_no;

  return (
    <>
      <div className="max-w-container mx-auto px-4">
        <Breadcrumbs title={useContentLangByCode("C.OrderCompleted.Title")} />

        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20"
        >
          <div>
            <img
              className="w-52 rounded-lg p-0 mx-auto"
              src={orderSuccess}
              alt="order success"
            />
          </div>
          <div className="max-w-[500px] p-4 py-4 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
            <h1 className="font-titleFont text-xl font-bold uppercase">
              <LableItem code="C.OrderCompleted.Title"></LableItem>
            </h1>
            <p className="text-sm text-center px-10 -mt-2">
              <LableItem code="C.OrderCompleted.OrderNumber"></LableItem>:<span className="font-bold">{order_no}</span>
            </p>  
            <p>
              <LableItem code="C.OrderCompleted.Message"></LableItem>
            </p>
            <Link
              to="/shop"
              className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300 flex justify-center items-center rounded-md font-semibold text-sm mt-4"
            >
              🛒 <span className="ml-2"> </span><LableItem code="C.OrderCompleted.Continue"></LableItem>
            </Link>
            <Link
              to="/order-history"
              className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300 flex justify-center items-center rounded-md font-semibold text-sm mt-4"
            >
              📜<span className="ml-2"> </span> <LableItem code="C.OrderCompleted.OrderHistory"></LableItem>
            </Link>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default OrderCompleted;
