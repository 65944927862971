// src/analytics.js

export function trackViewItem(productID, productName, price, currency = 'VND') {
    window.gtag('event', 'view_item', {
        items: [{
            id: productID,
            name: productName,
            price: price,
            currency: currency
        }]
    });
}

export function trackAddToCart(productID, productName, price, currency = 'VND', quantity = 1) {
    window.gtag('event', 'add_to_cart', {
        items: [{
            id: productID,
            name: productName,
            price: price,
            currency: currency,
            quantity: quantity
        }]
    });
}

export function trackBeginCheckout(cartItems) {
    window.gtag('event', 'begin_checkout', {
        items: cartItems
    });
}

export function trackPurchase(transactionID, cartItems, totalValue, currency = 'VND') {
    window.gtag('event', 'purchase', {
        transaction_id: transactionID,
        value: totalValue,
        currency: currency,
        items: cartItems
    });
}
