import React, { useState, useEffect } from "react";
import Heading from "../home/Products/Heading";
import ProductV2 from "../home/Products/ProductV2";
import { useSelector } from "react-redux";
import {  FoxProduct_Home } from "../../common.js/transformation";
import { LableItem } from "../../components/LabelLanguge/LabelItem";
import BackendApi from "../../common.js/backendApi";
import { fetchData } from "../../common.js/http";
import { CONST_DATA } from "../../common.js/constData";

const PromotionProduct = ({item_banner}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const En = useSelector((state) => state.FoxReducer.En);
  const store_code = useSelector((state) => state.FoxReducer.useSelectedStore);
  const [currentPage, setCurrentPage] = useState(1);
  const [OldPage, setOldPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  var page_size = 10;
  

  const fetchProducts = async (page) => {
    
    try {
      setLoading(true);
      let props = {
        url: `${BackendApi.allProductsAndCount.url}?page=${page}&size=${page_size}&active=true&published=true&d${CONST_DATA.STORE_DEFAULT}&scopes=["ProductOrigin","ProductCategory","ProductAttribute","ProductPrice","ProductImage"]&product_list=${item_banner.product_list}`,
        method: BackendApi.allProductsAndCount.method,
      };

      const data1 = await fetchData(props);

      if (data1.success) {
        setData((prevData) => [...prevData, ...data1.results]);
        if (data1.results.length === 0 || data1.results.length < page_size || data1.count <=page_size ) {
          setHasMore(false); 
        }
      } else {
        setHasMore(false);
      }
      setOldPage((prevPage) => prevPage + 1);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setData([]); 
    setCurrentPage(1);
    setHasMore(true); 
    fetchProducts(1);
  }, [store_code, En]);

  let filteredData = data;
  const bestSeller = <LableItem code="C.SpecialOffers.SpecialOffer"></LableItem>

  const loadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    fetchProducts(currentPage + 1);
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <div className="w-16 h-16 border-4 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
//https://api-test.mmvietnam.com:5000/fox/assets/images/banners/web-banner-3.jpg
  return (
    <div className="w-full pb-20">
      <img 
  src={item_banner.url} 
  alt="Promotional Banner" 
  className="w-full object-cover h-auto mb-8" 
  style={{ maxHeight: '200px', objectPosition: 'top' }}
/>
      {/* <Heading heading={bestSeller} /> */}
      <div className="w-full grid grid-cols-2 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-5 gap-10">
  
        {filteredData && filteredData.length > 0 ? (
          filteredData.map((data) => {
            let item = FoxProduct_Home(data,En);
            return (
              <ProductV2
                key={item.product_code}
                product_code={item.product_code}
                slug={item.slug}
                sku={item.sku}
                image={item.image}
                name={item.name}
                price={item.price}
                retail_price={item.retail_price}
                price_ex_vat={item.price_ex_vat}
                retail_price_ex_vat={item.retail_price_ex_vat}
                vat_code={item.vat_code}
                vat_rate={item.vat_rate}
                color={item.color}
                badge={item.badge}
                des={item.des}
                cat={item.cat}
                cat_name={item.cat_name}
                unit={item.unit}
              />
            );
          })
        ) : (
          <div>{En ? "No products available" : "Không có sản phẩm nào khả dụng"}</div>
        )}
  
      </div>
      {hasMore && !loading && (
        <div className="flex justify-center mt-8">
          <button
            className="px-4 py-2 bg-primeButtonColor text-secondTextColor rounded"
            onClick={loadMore}
          >
            {En?"Load More":"Xem thêm"}
          </button>
        </div>
      )}
    </div>
  );
};

export default PromotionProduct;
