export const foxLangugeEn = [
  {
    LabelCode: "ProductName",
    LabelValue: "Product name",
  },
  {
    LabelCode: "C.SpecialOffers.SpecialOffer",
    LabelValue: "Special Offers",
  },
  {
    LabelCode: "C.HeaderBottom.ShopByCategory",
    LabelValue: "Shop by Category",
  },
  {
    LabelCode: "C.BestSellers.BestSeller",
    LabelValue: "Our Best Sellers",
  },
  {
    LabelCode: "C.MultiComponent.AddToCart",
    LabelValue: "Add To Cart",
  },
  {
    LabelCode: "C.Product.ViewDetail",
    LabelValue: "View details",
  },
  {
    LabelCode: "C.Product.AddToWishList",
    LabelValue: "Add To Wish List",
  },
  {
    LabelCode: "P.Signup.FullName",
    LabelValue: "Full name",
  },
  {
    LabelCode: "P.Signup.Email",
    LabelValue: "Email",
  },
  {
    LabelCode: "P.Signup.Phone",
    LabelValue: "Phone Number",
  },
  {
    LabelCode: "P.Signup.Password",
    LabelValue: "Password",
  },
  {
    LabelCode: "P.Signup.Address",
    LabelValue: "Address",
  },
  {
    LabelCode: "P.Signup.City",
    LabelValue: "City",
  },
  {
    LabelCode: "P.Signup.Country",
    LabelValue: "Country",
  },
  {
    LabelCode: "P.Signup.Zip",
    LabelValue: "Zip/Postal code",
  },
  {
    LabelCode: "P.Signup.Agree",
    LabelValue: "I agree to the Fox",
  },
  {
    LabelCode: "P.Signup.CreateAccountButton",
    LabelValue: "Create Account",
  },
  {
    LabelCode: "P.Signup.NoAccount",
    LabelValue: "Create Account",
  },

  {
    LabelCode: "P.Signup.FullName.Plh",
    LabelValue: "Full name",
  },
  {
    LabelCode: "P.Signup.Email.Plh",
    LabelValue: "Email",
  },
  {
    LabelCode: "P.Signup.Phone.Plh",
    LabelValue: "Phone Number",
  },
  {
    LabelCode: "P.Signup.Password.Plh",
    LabelValue: "Password",
  },
  {
    LabelCode: "P.Signup.Address.Plh",
    LabelValue: "Address",
  },
  {
    LabelCode: "P.Signup.City.Plh",
    LabelValue: "City",
  },
  {
    LabelCode: "P.Signup.Country.Plh",
    LabelValue: "Country",
  },
  {
    LabelCode: "P.Signup.Zip.Plh",
    LabelValue: "Zip/Postal code",
  },
  {
    LabelCode: "C.HeaderBottom.SearchInput.Plh",
    LabelValue: "Search your products here",
  },
  {
    LabelCode: "T.Toast.AddToCart",
    LabelValue: "Product add to cart",
  },
  {
    LabelCode: "T.Toast.RemoveFromCart",
    LabelValue: "Product remove from cart",
  },
  {
    LabelCode: "P.Signup.Header",
    LabelValue: "Create your account",
  },
  {
    LabelCode: "P.Signup.FullName",
    LabelValue: "Full name",
  },
  {
    LabelCode: "P.Signup.Email",
    LabelValue: "Email",
  },
  {
    LabelCode: "P.Signup.Phone",
    LabelValue: "Phone Number",
  },
  {
    LabelCode: "P.Signup.Password",
    LabelValue: "Password",
  },
  {
    LabelCode: "P.Signup.Address",
    LabelValue: "Address",
  },
  {
    LabelCode: "P.Signup.City",
    LabelValue: "City",
  },
  {
    LabelCode: "P.Signup.Country",
    LabelValue: "Country",
  },
  {
    LabelCode: "P.Signup.Zip",
    LabelValue: "Zip/Postal code",
  },
  {
    LabelCode: "P.Signup.Agree",
    LabelValue: "I agree to the Fox",
  },
  {
    LabelCode: "P.Signup.CreateAccountButton",
    LabelValue: "Create Account",
  },
  {
    LabelCode: "P.Signup.NoAccount",
    LabelValue: "Create Account",
  },
  {
    LabelCode: "P.CheckoutProcess.Header",
    LabelValue: "Receiver's information",
  },
  {
    LabelCode: "P.CheckoutProcess.FullName",
    LabelValue: "Full name",
  },
  {
    LabelCode: "P.CheckoutProcess.Email",
    LabelValue: "Email",
  },
  {
    LabelCode: "P.CheckoutProcess.Phone",
    LabelValue: "Phone",
  },
  {
    LabelCode: "P.CheckoutProcess.Address",
    LabelValue: "Address",
  },
  {
    LabelCode: "P.CheckoutProcess.City",
    LabelValue: "City",
  },
  {
    LabelCode: "P.CheckoutProcess.Country",
    LabelValue: "Country",
  },
  {
    LabelCode: "P.CheckoutProcess.Note",
    LabelValue: "Note",
  },
  {
    LabelCode: "P.CheckoutProcess.Note.Plh",
    LabelValue: "Mang theo tiền lẻ",
  },
  {
    LabelCode: "P.CheckoutProcess.FullName.Plh",
    LabelValue: "Full name",
  },
  {
    LabelCode: "P.CheckoutProcess.Email.Plh",
    LabelValue: "Email",
  },
  {
    LabelCode: "P.CheckoutProcess.Phone.Plh",
    LabelValue: "Phone Number",
  },
  {
    LabelCode: "P.CheckoutProcess.Password.Plh",
    LabelValue: "Password",
  },
  {
    LabelCode: "P.CheckoutProcess.Address.Plh",
    LabelValue: "Address",
  },
  {
    LabelCode: "P.CheckoutProcess.City.Plh",
    LabelValue: "City",
  },
  {
    LabelCode: "P.CheckoutProcess.Country.Plh",
    LabelValue: "Country",
  },
  {
    LabelCode: "P.CheckoutProcess.Zip.Plh",
    LabelValue: "703445",
  },
  {
    LabelCode: "P.CheckoutProcess.Zip",
    LabelValue: "Zip/Postal code",
  },
  {
    LabelCode: "P.CheckoutProcess.CheckOut",
    LabelValue: "Confirm",
  },
  {
    LabelCode: "P.CheckoutProcess.Agree",
    LabelValue: "I agree with",
  },
  {
    LabelCode: "C.Multi.ProductName",
    LabelValue: "Product name",
  },
  {
    LabelCode: "C.Multi.Price",
    LabelValue: "Price",
  },
  {
    LabelCode: "C.Multi.Quantity",
    LabelValue: "Quantity",
  },
  {
    LabelCode: "C.Multi.SubTotal",
    LabelValue: "Sub Total",
  },
  {
    LabelCode: "C.Multi.ApplyCoupon",
    LabelValue: "Apply Coupon",
  },
  {
    LabelCode: "C.OrderConfirm.UserInformation",
    LabelValue: "Receiver's infomation",
  },

  {
    LabelCode: "C.OrderConfirm.FullName",
    LabelValue: "Full name",
  },

  {
    LabelCode: "C.OrderConfirm.Email",
    LabelValue: "Email",
  },

  {
    LabelCode: "C.OrderConfirm.Phone",
    LabelValue: "Phone",
  },

  {
    LabelCode: "C.OrderConfirm.Address",
    LabelValue: "Address",
  },

  {
    LabelCode: "C.OrderConfirm.City",
    LabelValue: "City",
  },

  {
    LabelCode: "C.OrderConfirm.Pricing",
    LabelValue: "Pricing",
  },

  {
    LabelCode: "C.OrderConfirm.TotalAmount",
    LabelValue: "Total Amount",
  },

  {
    LabelCode: "C.OrderConfirm.ShippingCharge",
    LabelValue: "Shipping Charge",
  },
  {
    LabelCode: "C.OrderConfirm.GrandTotal",
    LabelValue: "Grand Total",
  },

  {
    LabelCode: "C.OrderConfirm.Note",
    LabelValue: "Noted",
  },
  {
    LabelCode: "C.OrderConfirm.SaveChanges",
    LabelValue: "Save changes",
  },
  {
    LabelCode: "C.OrderConfirm.EditCheckout",
    LabelValue: "Update receiver's infomation",
  },
  {
    LabelCode: "C.OrderConfirm.CompleteOrder",
    LabelValue: "Confirm",
  },
  {
    LabelCode: "C.OrderConfirm.Title",
    LabelValue: "Complete Order",
  },
  {
    LabelCode: "C.OrderCompleted.Title",
    LabelValue: "Order success",
  },
  {
    LabelCode: "C.OrderCompleted.Title",
    LabelValue: " You completed order",
  },
  {
    LabelCode: "C.OrderCompleted.OrderNumber",
    LabelValue: "Order No. ",
  },
  {
    LabelCode: "C.OrderCompleted.Message",
    LabelValue: "we are going to deliver  this order to you ",
  },
  {
    LabelCode: "C.OrderCompleted.Continue",
    LabelValue: "Continue Shopping",
  },
  {
    LabelCode: "C.OrderCompleted.OrderHistory",
    LabelValue: "Order history ",
  },
  {
    LabelCode: "P.Signin.SigninButton",
    LabelValue: "Send OTP ",
  },
  {
    LabelCode: "P.Signin.Header",
    LabelValue: "Login",
  },
  {
    LabelCode: "P.Signin.Phone",
    LabelValue: "Phone No.",
  },
  {
    LabelCode: "P.OtpPage.Header",
    LabelValue: "Input  OTP",
  },
  {
    LabelCode: "P.OtpPage.Verify",
    LabelValue: "Verify",
  },
  {
    LabelCode: "P.OtpPage.TimeRemaining",
    LabelValue: "Time Remaining ",
  },
  {
    LabelCode: "P.OtpExpired.Header",
    LabelValue: "Otp has expired",
  },
  {
    LabelCode: "P.OtpExpired.RenewOtp",
    LabelValue: "Renew OTP",
  },
  {
    LabelCode: "P.OtpExpired.BackToHome",
    LabelValue: "Back to home",
  },
  {
    LabelCode: "C.OrderConfirm.CreateOrder",
    LabelValue: "Create Order",
  },
  {
    LabelCode: "C.HeaderBottom.ClickShowMap",
    LabelValue: "Click to show routing ",
  },
  {
    LabelCode: "P.CheckoutProcess.District",
    LabelValue: "District",
  },
  {
    LabelCode: "P.CheckoutProcess.Ward",
    LabelValue: "Ward",
  },
  {
    LabelCode: "C.OrderHistory.OrderId",
    LabelValue: "Order Id",
  },
  {
    LabelCode: "C.OrderHistory.Header.Total",
    LabelValue: "Total",
  },
  {
    LabelCode: "C.OrderHistory.Header.OrderDate",
    LabelValue: "Date",
  },
  {
    LabelCode: "C.OrderHistory.Header.OrderStatus",
    LabelValue: "Order Status",
  },
  {
    LabelCode: "C.OrderHistory.Header.Detail",
    LabelValue: "Detail",
  },

  {
    LabelCode: "C.OrderHistory.Header.Title",
    LabelValue: "List of orders",
  },

  {
    LabelCode: "C.OrderHistory.Header.Address",
    LabelValue: "Delivery address",
  },
  {
    LabelCode: "C.OrderDetail.Title",
    LabelValue: "Order details",
  },
  {
    LabelCode: "C.OrderDetail.Unit",
    LabelValue: "Unit",
  },
  {
    LabelCode: "C.OrderDetail.OrderDate",
    LabelValue: "Order Date",
  },
  {
    LabelCode: "C.OrderDetail.OrderNumber",
    LabelValue: "Order No.",
  },
  {
    LabelCode: "C.OrderDetail.OrderItems",
    LabelValue: "Order Items",
  },
  {
    LabelCode: "C.OrderDetail.RowTotal",
    LabelValue: "Total",
  },
  {
    LabelCode: "C.OrderDetail.PaymentDetails",
    LabelValue: "Payment Details",
  },
  {
    LabelCode: "C.OrderDetail.InvoiceInformation",
    LabelValue: "Invoice infomation",
  },
  {
    LabelCode: "C.OrderDetail.Url",
    LabelValue: "Access",
  },
  {
    LabelCode: "C.OrderDetail.Secret",
    LabelValue: "Access code",
  },
  {
    LabelCode: "C.OrderDetail.InvoiceNumber",
    LabelValue: "Invoice No.",
  },
  
  //

  {
    LabelCode: "C.OrderDetail.DeliveryInfomation",
    LabelValue: "Delivery Infomation",
  },
  {
    LabelCode: "C.OrderDetail.TotalExVat",
    LabelValue: "Excluding VAT",
  },
  {
    LabelCode: "C.OrderDetail.TotalVat",
    LabelValue: "Total VAT",
  },
  {
    LabelCode: "C.OrderDetail.GrandTotal",
    LabelValue: "Grand total",
  },
  {
    LabelCode: "P.CheckoutProcess.DeliveryDate",
    LabelValue: "Delivery date",
  },
  {
    LabelCode: "P.CheckoutProcess.DeliveryFromTime",
    LabelValue: "Delivery  period",
  },
  {
    LabelCode: "P.CheckoutProcess.DeliveryToTime",
    LabelValue: "Delivery to time",
  },
  {
    LabelCode: "P.CheckoutProcess.ClientInfo",
    LabelValue: "Receiver",
  },
  {
    LabelCode: "P.CheckoutProcess.AddressDetails",
    LabelValue: "Delivery address",
  },
  {
    LabelCode: "P.CheckoutProcess.DeliveryDateTime",
    LabelValue: "​Desired delivery time",
  },
  {
    LabelCode: "C.OrderConfirm.DeliExpectation",
    LabelValue: "​Desired delivery time",
  },
  {
    LabelCode: "C.HeaderBottom.Logout",
    LabelValue: "Logout",
  },
  {
    LabelCode: "C.HeaderBottom.Profile",
    LabelValue: "Profile",
  },
  {
    LabelCode: "C.HeaderBottom.Orders",
    LabelValue: "Orders",
  },
  {
    LabelCode: "P.Cart.CartTotal",
    LabelValue: "Total",
  },
  {
    LabelCode: "P.Cart.Total",
    LabelValue: "Total",
  },
  {
    LabelCode: "P.Cart.ProceedToCheckout",
    LabelValue: "Proceed To Checkout",
  },
  {
    LabelCode: "C.HeaderBottom.Price",
    LabelValue: "Price",
  },
  //C.AddressInput.Address
  //C.AddressInput.City
  // /C.AddressInput.District
  //C.AddressInput.Ward
  {
    LabelCode: "C.AddressInput.Address",
    LabelValue: "Address",
  },
  {
    LabelCode: "C.AddressInput.City",
    LabelValue: "Province",
  },
  {
    LabelCode: "C.AddressInput.District",
    LabelValue: "District",
  },
  {
    LabelCode: "C.AddressInput.Ward",
    LabelValue: "Ward",
  },
  {
    LabelCode: "P.EditProfile.BirthDate",
    LabelValue: "DoB",
  },
  {
    LabelCode: "P.Cart.Product",
    LabelValue: "Product ",
  },
  {
    LabelCode: "P.Cart.Header.Price",
    LabelValue: "Price",
  },
  {
    LabelCode: "P.Cart.Header.Quantity",
    LabelValue: "Quantity",
  },
  {
    LabelCode: "P.Cart.Header.Unit",
    LabelValue: "Unit",
  },
  {
    LabelCode: "P.Cart.Header.SubTotal",
    LabelValue: "Sub Total",
  },
  {
    LabelCode: "P.Cart.Header.SubTotal",
    LabelValue: "Sub Total",
  },
  {
    LabelCode: "P.Card.Title",
    LabelValue: "Your cart",
  },
  {
    LabelCode: "P.EditProfile.BackToHome",
    LabelValue: "Back to Home page",
  },
  {
    LabelCode: "P.EditProfile.Confirm",
    LabelValue: "Confirm",
  },
  {
    LabelCode: "P.EditProfile.Header",
    LabelValue: "Update personal info ",
  },
  {
    LabelCode: "P.EditProfile.FullName",
    LabelValue: "Fullname",
  },
  {
    LabelCode: "P.EditProfile.Email",
    LabelValue: "Email",
  },
  {
    LabelCode: "P.EditProfile.Phone",
    LabelValue: "Phone",
  },
  {
    LabelCode: "C.Multi.BackToHome",
    LabelValue: "Back to home page",
  },
  {
    LabelCode: "C.OrderHistory.OrderNo",
    LabelValue: "Delivery No",
  },
  {
    LabelCode: "C.OrderHistory.FilterButton",
    LabelValue: "Apply Filter",
  },
  {
    LabelCode: "C.OrderHistory.OrderStatus",
    LabelValue: "Order status",
  },
  {
    LabelCode: "C.OrderHistory.OrderFromDate",
    LabelValue: "Orders from date",
  },
  {
    LabelCode: "C.OrderHistory.OrderStatus",
    LabelValue: "Trạng thái đơn hàng",
  },
  {
    LabelCode: "P.Cart.P1",
    LabelValue: "Your Cart feels lonely",
  },
  {
    LabelCode: "P.Cart.P2",
    LabelValue:
      "Your Shopping cart lives to serve. Give it purpose - fill it with books, electronics, videos, etc. and make it happy.",
  },
  {
    LabelCode: "P.Cart.ContinueShopping",
    LabelValue: "ContinueShopping",
  },
  {
    LabelCode: "C.HeaderBottom.Login",
    LabelValue: "Login",
  },
  {
    LabelCode: "C.HeaderBottom.SignUp",
    LabelValue: "Sign Up",
  },
  {
    LabelCode: "C.OrderDetail.FullName",
    LabelValue: "Full name",
  },
  {
    LabelCode: "C.OrderDetail.Email",
    LabelValue: "Email",
  },
  {
    LabelCode: "C.OrderDetail.Phone",
    LabelValue: "Phone",
  },
  {
    LabelCode: "C.OrderDetail.Address",
    LabelValue: "Address",
  },
  {
    LabelCode: "C.OrderDetail.Note",
    LabelValue: "Note",
  },
  {
    LabelCode: "C.OrderDetail.OrderStatus",
    LabelValue: "Order status",
  },
  {
    LabelCode: "C.OrderDetail.Total",
    LabelValue: "Total",
  },
  {
    LabelCode: "C.OrderDetail.ShippingFee",
    LabelValue: "​Delivery fee",
  },
  {
    LabelCode: "Error.CheckOutProcess.EmptyName",
    LabelValue: "Please input the name",
  },
  {
    LabelCode: "Error.CheckOutProcess.EmptyEmail",
    LabelValue: "Please input the email",
  },
  {
    LabelCode: "Error.CheckOutProcess.EmptyPhone",
    LabelValue: "Vui lòng nhập số điện thoại",
  },
  {
    LabelCode: "Error.CheckOutProcess.EmptyAddress",
    LabelValue: "Please input the phone number",
  },
  {
    LabelCode: "Error.CheckOutProcess.InvalidDeliveryDate",
    LabelValue: "Delivery date must be after current date and time",
  },
  {
    LabelCode: "Error.CheckOutProcess.InvalidDeliveryFromTime",
    LabelValue: "Delivery date must be after current date and time",
  },
  {
    LabelCode: "Error.CheckOutProcess.EmptyPeriod",
    LabelValue: "Please select the delivery period",
  },
  {
    LabelCode: "Error.CheckOutProcess.ToastMsg",
    LabelValue: "Please fill and correct all the required fields",
  },
  {
    LabelCode: "Error.Phone.Format",
    LabelValue: "Phone is incorrect format",
  },
  {
    LabelCode: "Error.Phone.Empty",
    LabelValue: "Please input phone number",
  },
  {
    LabelCode: "P.EditProfile.FullName.Plh",
    LabelValue: "John Doe",
  },
  {
    LabelCode: "P.EditProfile.Email.Plh",
    LabelValue: "example@email.com",
  },
  {
    LabelCode: "P.CheckOutProcess.SlotInputPlh",
    LabelValue: "select period",
  },
  {
    LabelCode: "C.OrderDetail.PaidAmount",
    LabelValue: "Paid amount",
  },
  {
    LabelCode: "C.Multi.PayLater",
    LabelValue: "Pay later",
  },
  {
    LabelCode: "C.Multi.PayNow",
    LabelValue: "Pay now with Momo",
  },
  {
    LabelCode: "C.Multi.Pay",
    LabelValue: "Pay ",
  },
  //C.OrderDetail.PaidAMount:

];
