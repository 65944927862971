import React , { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RiShoppingCart2Fill } from "react-icons/ri";
import { MdSwitchAccount, MdArrowUpward } from "react-icons/md";
import { useSelector } from "react-redux";

const SpecialCase = () => {
  const products = useSelector((state) => state.FoxReducer.products);

  const [showScrollTop, setShowScrollTop] = useState(false);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Show the scroll-to-top button only when scrolled more than 1/3 of screen height
  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = window.innerHeight / 3;
      setShowScrollTop(window.scrollY > scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="fixed top-1/2 right-1 z-30 flex flex-col gap-4">
      <Link to="/edit-profile">
        <div className="bg-white w-12 h-12 rounded-md flex flex-col gap-1 text-[#33475b] justify-center items-center shadow-testShadow overflow-x-hidden group cursor-pointer">
          <div className="flex justify-center items-center">
            <MdSwitchAccount className="text-xl -translate-x-12 group-hover:translate-x-3 transition-transform duration-200" />

            <MdSwitchAccount className="text-xl -translate-x-3 group-hover:translate-x-12 transition-transform duration-200" />
          </div>
          <p className="text-xs font-semibold font-titleFont">Profile</p>
        </div>
      </Link>
      <Link to="/cart">
        <div className="bg-white w-12 h-12 rounded-md flex flex-col gap-1 text-[#33475b] justify-center items-center shadow-testShadow overflow-x-hidden group cursor-pointer relative">
          <div className="flex justify-center items-center">
            <RiShoppingCart2Fill className="text-xl -translate-x-12 group-hover:translate-x-3 transition-transform duration-200" />

            <RiShoppingCart2Fill className="text-xl -translate-x-3 group-hover:translate-x-12 transition-transform duration-200" />
          </div>
          <p className="text-xs font-semibold font-titleFont">Buy</p>
          {products.length > 0 && (
            <p className="absolute top-1 right-2 bg-primeColor text-white text-xs w-4 h-4 rounded-full flex items-center justify-center font-semibold">
              {products.length}
            </p>
          )}
        </div>
      </Link>
      {/* Go to Top Button - shown only when `showScrollTop` is true */}
      {showScrollTop && (
        <div
          onClick={scrollToTop}
          className="bg-white w-12 h-12 rounded-md flex justify-center items-center text-[#33475b] shadow-testShadow cursor-pointer fixed bottom-4 right-1 z-30"
        >
          <MdArrowUpward   className="text-xl font-thin" />
        </div>
      )}
    </div>
  );
};

export default SpecialCase;
