import React, { useState,useEffect } from "react";
import NavTitle from "./NavTitle";
import { useDispatch, useSelector } from "react-redux";
import { toggleCategory } from "../../../../redux/FoxSlice";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import BackendApi from "../../../../common.js/backendApi";
import { fetchData, fetchDataV2 } from "../../../../common.js/http";
import { forEach } from "lodash";
import { CONST_DATA } from "../../../../common.js/constData";
//import { categoryList } from "../../../../common.js/db";
const Category = ({cate_code1}) => {
  const [showSubCatOne, setShowSubCatOne] = useState(true);
  //const checkedCategorys = useSelector((state) => state.FoxReducer.checkedCategorys);
  const checkedCategory = useSelector((state) => state.FoxReducer.checkedCategory); // Giả sử state lưu giá trị category đã chọn, không phải mảng
  const En = useSelector((state) => state.FoxReducer.En);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cate_list, setCateList] = useState([]);

  function convertToSlug(str) {
    str = str.toLowerCase();
    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    str = str.replace(/[^a-z0-9\s-]/g, '')  
             .replace(/\s+/g, '-')           
             .replace(/-+/g, '-');           
  
    return str;
  }
  
  const fetchCate = async () => {
    try {
      let props = {
        url: `${BackendApi.allCategories.url}/${CONST_DATA.CHANNEL}?page=1&size=100`,
        method: BackendApi.allCategories.method,
      }
      const data1 = await fetchData(props);
      if (data1.success) {
        var list_cate_level1 = data1.results[0].child_categories;
        list_cate_level1.forEach(cate1 => {
          cate1.child_categories.forEach(cate2 => {
            if(cate2.category_code == cate_code1)
            {
              setCateList(cate1.child_categories);
            }
          });
        });
        
      }
      else
      setCateList(null);
    } catch (err) {
    } 
  };
  

  useEffect(() => {
    fetchCate();
  }, [En,cate_code1]);
  let cate_list1 = cate_list;

  const handleToggleCategory = (category,cate_list1) => {
    dispatch(toggleCategory(category));
    var childName = En ? convertToSlug(category.name_en) +"_" + category.category_code : convertToSlug(category.name_local)+"_" + category.category_code;
    navigate(`/category/${childName}`,{ state: { child_categories: cate_list1 }});
  };  

  return (
    <div className="w-full">
      <div
        onClick={() => setShowSubCatOne(!showSubCatOne)}
        className="cursor-pointer"
      >
      <NavTitle title={En ? "Shop by Category" : "Mua sắm theo danh mục"} icons={true} isMenuOpen={showSubCatOne} />
      </div>
      { cate_list1 && cate_list1.length > 0 && showSubCatOne && (
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
        <ul className="flex flex-col gap-4 text-sm lg:text-base textthirdTextColor">
          
          {cate_list1.map((cat) => (
            <li style={{cursor:'pointer'}}
              key={cat.category_code}
              className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2 hover:text-primeColor hover:border-bsmBoder duration-300"
              onClick={() => handleToggleCategory(cat,cate_list1)}
            >
              <input
                type="radio"
                radioGroup="r_cate"
                id={cat.category_code}
                //checked={checkedCategorys.some((b) => b.category_code === cat.category_code)}
                checked={cate_code1 === cat.category_code} // So sánh với giá trị của radio button được chọn
                // onChange={() => handleToggleCategory(cat,cate_list1)}
              />
              {En?cat.name_en:cat.name_local}
              {}
            </li>
          ))}
        </ul>
      </motion.div>
    )}
    </div>
  );
};

export default Category;
