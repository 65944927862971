import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { HiMenuAlt2 } from "react-icons/hi"; //hamburger menu icon
import { motion } from "framer-motion";
import { logo, logoLight,logoV2, vi, uk } from "../../../assets/images";
import Image from "../../designLayouts/Image";
import { navBarList } from "../../../constants"; //header not included category/brand
import Flex from "../../designLayouts/Flex";
import { useDispatch } from "react-redux";
import { toggleLanguage, toggleCategory, resetCategory } from "../../../redux/FoxSlice";
import { brandList } from "../../../common.js/db";
import { FoxBrand, FoxCat } from "../../../common.js/transformation";
import BackendApi from "../../../common.js/backendApi";
import {fetchData}  from  "../../../common.js/http";
import { Loading } from "../../Load/Loading";
import { useSelector } from "react-redux";
import { getCustByPhone } from "../../../services/AccountServices";
import slug from "../../../utils/slug";
import {CONST_DATA} from "../../../common.js/constData";

const Header = () => {
  const [showMenu, setShowMenu] = useState(true);
  const [sidenav, setSidenav] = useState(false);
  const [category, setCategory] = useState(false);
  const [brand, setBrand] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [En, setEn] = useState(false);
  const dispatch = useDispatch();
  const isEn = useSelector((state) => state.FoxReducer.En);
  //const [isLoading , setIsLoading] = useState(false);
  const location = useLocation();
  const isLoading = useSelector(
    (state) => state.FoxReducer.isLoading
  );
  const fetchCategory = async () => {
    try {
      
      let props = {
        url:`${BackendApi.allCategories.url}?page=1&size=10&channel_code=${CONST_DATA.CHANNEL}`,
        method: BackendApi.allCategories.method,
      }
     
      const data = await fetchData(props);
      if (data.success) {
        setCategoryList(data.results);
      }
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
  };

  useEffect(() => {
    // fetchCategory();
    const ResponsiveMenu = () => {
      if (window.innerWidth < 667) {
        setShowMenu(false);
      } else {
        setShowMenu(true);
      }
    };

    ResponsiveMenu(); // show|hide hamburger menu
    window.addEventListener("resize", ResponsiveMenu);
    
    setEn(isEn);
    return () => {
      window.removeEventListener("resize", ResponsiveMenu);
    };
  }, [dispatch,En,isLoading]);

  // Handle language toggle
  const handleLanguageToggle = async() => {
    const newLanguage = En ? "vi" : "en";
  
    setEn(!En);
    dispatch(toggleLanguage(newLanguage));
  };

  // Handle Category toggle
  const handleToggleCategory = (category) => {
    dispatch(resetCategory());
    dispatch(toggleCategory(category));
  };

  return (
    <div className="w-full h-14 sticky top-0 z-50 border-b-[1px] border-bsmBG bg-bsmBG">
       {isLoading && (
                    <Loading className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                  )}
      <nav className="h-full pr-4 mx-auto relative bg-bsmBG">
        <Flex className="flex items-center justify-between h-full">
          <Link to="/">
            <div>
              <Image className="h-14 object-cover" imgSrc={logoV2} />
            </div>
          </Link>

          <div>
            {/* Shop by hamburger menu including navBarList, CategoryList and Brands */}
            {showMenu && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="flex items-center w-auto z-50 p-0 gap-2"
              >
                {navBarList.map(({ id, title, title_en, link }) => (
                  <NavLink
                    key={id}
                    className="flex font-normal hover:font-bold w-auto h-6 justify-center items-center px-5 text-base textthirdTextColor hover:underline underline-offset-[4px] decoration-[1px] hover:text-primeTextColor md:border-r-[2px] border-r-bsmBG hoverEffect last:border-r-0"
                    to={link}
                    state={{ data: location.pathname.split("/")[1] }}
                  >
                    <li>{En ? title_en : title}</li>
                  </NavLink>
                ))}

                <div className="pl-5" onClick={handleLanguageToggle}>
                  <Image className="w-6 h-6 object-cover cursor-pointer" imgSrc={En ? uk : vi} />
                </div>
              </motion.ul>
            )}
            <HiMenuAlt2
              onClick={() => setSidenav(!sidenav)}
              className="inline-block md:hidden cursor-pointer w-8 h-6 absolute top-6 right-4"
            />
            {sidenav && (
              <div className="fixed top-0 left-0 w-full h-screen bg-black text-cartBGLight bg-opacity-80 z-50">
                <motion.div
                  initial={{ x: -300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="w-[80%] h-full relative"
                >
                  <div className="w-full h-full bg-primeColor p-6">
                    <img className="w-28 mb-6" src={logoLight} alt="logoLight" />

                    <ul className="text-cartBGLight flex flex-col gap-2">
                      {navBarList.map((nav) => (
                        <li
                          className="font-normal hover:font-bold items-center text-lg text-cartBGLight hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-bsmBG hoverEffect last:border-r-0"
                          key={nav.id}
                        >
                          <NavLink
                            to={nav.link}
                            state={{ data: location.pathname.split("/")[1] }}
                            onClick={() => setSidenav(false)}
                          >
                            {En ? nav.title_en : nav.title}
                          </NavLink>
                        </li>
                      ))}

                      <li
                        className="flex font-normal hover:font-bold items-center text-lg text-cartBGLight hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-bsmBG hoverEffect last:border-r-0"
                        key="-1"
                        onClick={handleLanguageToggle}
                      >
                        {En ? (
                          <>
                            Language: <Image className="pl-2 w-6 h-4 object-cover cursor-pointer" imgSrc={uk} />
                          </>
                        ) : (
                          <>
                            Ngôn ngữ: <Image className="pl-2 w-6 h-4 object-cover cursor-pointer" imgSrc={vi} />
                          </>
                        )}
                      </li>
                    </ul>

                    {/* Category section */}
                    {/* <div className="mt-4">
                      <h1
                        onClick={() => setCategory(!category)}
                        className="flex justify-between text-base cursor-pointer items-center font-titleFont mb-2"
                      >
                        {En ? "Shop by Category" : "Mua sắm theo danh mục"}
                        <span className="text-lg">{category ? "-" : "+"}</span>
                      </h1>
                      {category && categoryList.length > 0 && (
                        <motion.ul
                          initial={{ y: 15, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ duration: 0.4 }}
                          className="text-sm flex flex-col gap-1"
                        >
                          {categoryList[0].child_categories.map((data) => { 
                            let cat = FoxCat(data, En)
                            return (
                            <li
                              key={cat.category_code}
                              className="headerSideNavLi"
                              onClick={() => handleToggleCategory(cat)}
                            >
                              <Link
                                to={`category/${slug(En ? slug(cat.name) + "_" + cat.category_code : slug(cat.name) + "_" + cat.category_code)}`}
                                state={{ data: location.pathname.split("/")[1] }}
                                onClick={() => setSidenav(false)}
                              >
                                {cat.name}
                              </Link>
                            </li>
                          )}
                        )}
                        </motion.ul>
                      )}
                    </div> */}

                    {/* Brand section */}
                    {/* <div className="mt-4">
                      <h1
                        onClick={() => setBrand(!brand)}
                        className="flex justify-between text-base cursor-pointer items-center font-titleFont mb-2"
                      >
                        {En ? "Shop by Brand" : "Mua sắm theo thương hiệu"}
                        <span className="text-lg">{brand ? "-" : "+"}</span>
                      </h1>

                      {brand && (
                        <motion.ul
                          initial={{ y: 15, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ duration: 0.4 }}
                          className="text-sm flex flex-col gap-1"
                        >
                          {brandList.map((data) => {
                            let brand = FoxBrand(data, En);
                            return (
                              <li className="headerSideNavLi" key={brand.id}>
                                {brand.name}
                              </li>
                            );
                          })}
                        </motion.ul>
                      )}
                    </div> */}
                  </div>
                  <span
                    onClick={() => setSidenav(false)}
                    className="w-8 h-8 border-[1px] border-bsmBG absolute top-2 -right-10 text-bsmBG text-2xl flex justify-center items-center cursor-pointer hover:border-red-500 hover:text-red-500 duration-300"
                  >
                    <MdClose />
                  </span>
                </motion.div>
              </div>
            )}
          </div>
        </Flex>
      </nav>
    </div>
  );
};

export default Header;
