import React, { useState, useEffect, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import slug from "../../utils/slug";
import BackendApi from "../../common.js/backendApi";
import { fetchData } from "../../common.js/http";

// Lazy load the slider and image components
const Slider = lazy(() => import("react-slick"));
const Image = lazy(() => import("../designLayouts/Image"));

const CustomSlideV2 = ({ item_banner }) => {
  const En = useSelector((state) => state.FoxReducer.En);
  const navigate = useNavigate();

  const Subtext = En ? item_banner.content_en : item_banner.content;
  const imgSrc = item_banner.url;
  const text = En ? item_banner.title_en : item_banner.title;
  const buttonLink = item_banner.hyperlink ?? "";
  const buttonText = En ? item_banner.action_en : item_banner.action;
  const isExternal = buttonLink.startsWith("http://") || buttonLink.startsWith("https://");

  // Preload the image when imgSrc is available
  useEffect(() => {
    if (imgSrc) {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = imgSrc;
      document.head.appendChild(link);

      // Cleanup to avoid duplicates on re-renders
      return () => {
        document.head.removeChild(link);
      };
    }
  }, [imgSrc]);

  const handleProductDetails = () => {
    navigate(`/promotion/${slug(En ? item_banner.title_en : item_banner.title)}_${item_banner.id}`);
  };

  return (
    <div className="relative flex flex-col md:flex-row items-center justify-center p-6">
      <div className="max-w-full md:max-w-lg mb-6 md:mb-0 md:mr-10 text-center md:text-left">
        <h2 className="mb-4 text-2xl md:text-5xl font-bold">{text}</h2>
        <p className="mb-6 text-base md:text-2xl">{Subtext}</p>
        {isExternal ? (
          <a target="_blank" href={buttonLink} rel="noopener noreferrer">
            <button className="bg-primeButtonColor text-secondTextColor text-sm md:text-lg font-bodyFont w-[150px] md:w-[185px] h-[40px] md:h-[50px] duration-300 font-bold">
              {buttonText}
            </button>
          </a>
        ) : (
          <button onClick={handleProductDetails} className="bg-primeColor text-secondTextColor text-sm md:text-lg font-bodyFont w-[150px] md:w-[185px] h-[40px] md:h-[50px] hover:bg-primeColorDark duration-300 font-bold">
            {buttonText}
          </button>
        )}
      </div>
      <div className="w-full md:w-auto flex justify-center md:ml-10">
        <Suspense fallback={<div style={{ width: "100%", maxWidth: "645px", height: "auto", backgroundColor: "#f3f4f6" }} />}>
          <Image imgSrc={imgSrc} width="645" height="343" />
        </Suspense>
      </div>
    </div>
  );
};

const Banner = () => {
  const [dotActive, setDotActive] = useState(0);
  const [slides, setSlides] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const En = useSelector((state) => state.FoxReducer.En);

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const props = {
          url: `${BackendApi.getHomeBannerV2.url}?page=1&size=10`,
          method: BackendApi.getHomeBannerV2.method,
        };
        const data = await fetchData(props);
        if (data.success) setSlides(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchSlides();
  }, [En]);

  if (loading) {
    return <div className="spinner">Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000, // 10 seconds delay    
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    beforeChange: (prev, next) => setDotActive(next),
    appendDots: (dots) => (
      <div style={{ position: "absolute", top: "50%", left: "1%", transform: "translateY(-50%)" }}>
        <ul style={{ margin: "0px" }}>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={
          i === dotActive
            ? { width: "30px", color: "#262626", borderRight: "3px #262626 solid", padding: "8px 0", cursor: "pointer" }
            : { width: "30px", color: "transparent", borderRight: "3px white solid", padding: "8px 0", cursor: "pointer" }
        }
      >
        0{i + 1}
      </div>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: true,
          appendDots: (dots) => (
            <div style={{ position: "absolute", top: "50%", left: "2%", transform: "translateY(-50%)" }}>
              <ul style={{ margin: "0px" }}>{dots}</ul>
            </div>
          ),
          customPaging: (i) => (
            <div
              style={
                i === dotActive
                  ? { width: "25px", color: "#262626", borderRight: "3px #262626 solid", cursor: "pointer", fontSize: "12px" }
                  : { width: "25px", color: "transparent", borderRight: "3px white solid", cursor: "pointer", fontSize: "12px" }
              }
            >
              0{i + 1}
            </div>
          ),
        },
      },
    ],
  };

  return (
    <div className="w-full bg-white pt-6">
      <Suspense fallback={<div>Loading Slider...</div>}>
        {slides && slides.results && slides.results.length > 0 ? (
          <Slider {...settings}>
            {slides.results.map((slide, index) => (
              <CustomSlideV2 key={index} item_banner={slide} />
            ))}
          </Slider>
        ) : (
          <p>No banners available</p>
        )}
      </Suspense>
    </div>
  );
};

export default Banner;
