import React from "react";
import { useDispatch } from "react-redux";
import { resetCart } from "../../redux/FoxSlice";
import { LableItem } from "../LabelLanguge/LabelItem";

const CheckoutModal = ({
  isOpen,
  onClose,
  fullName,
  setFullName,
  email,
  setEmail,
  phone,
  setPhone,
  address,
  setAddress,
  note,
  setNote,
  city,
  setCity,
  handleConfirmOrder,
}) => {
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    handleConfirmOrder(); // Handle confirmation and close modal
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex justify-center items-center bg-black bg-opacity-50">
          <div className="relative bg-white rounded-lg shadow-lg max-w-md w-full p-6">
            <div className="absolute top-0 right-0 p-2">
              <button
                className="bg-primeColor text-thirdTextColor hover:text-thirdTextColor"
                onClick={onClose}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <h2 className="text-2xl font-semibold mb-4">
              <LableItem code="C.OrderConfirm.EditCheckout"></LableItem>
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-lg font-semibold mb-2">
                  <LableItem code="C.OrderConfirm.FullName"></LableItem>
                </label>
                <input
                  className="w-full h-10 px-3 border border-bsmBG rounded-lg focus:outline-none focus:border-primeColor"
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-lg font-semibold mb-2">
                  <LableItem code="C.OrderConfirm.Email"></LableItem>
                </label>
                <input
                  className="w-full h-10 px-3 border border-bsmBG rounded-lg focus:outline-none focus:border-primeColor"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-lg font-semibold mb-2">
                  <LableItem code="C.OrderConfirm.Phone"></LableItem>
                </label>
                <input
                  className="w-full h-10 px-3 border border-bsmBG rounded-lg focus:outline-none focus:border-primeColor"
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-lg font-semibold mb-2">
                  <LableItem code="C.OrderConfirm.Address"></LableItem>
                </label>
                <input
                  className="w-full h-10 px-3 border border-bsmBG rounded-lg focus:outline-none focus:border-primeColor"
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-lg font-semibold mb-2">
                  <LableItem code="C.OrderConfirm.City"></LableItem>
                </label>
                <input
                  className="w-full h-10 px-3 border border-bsmBG rounded-lg focus:outline-none focus:border-primeColor"
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-lg font-semibold mb-2">
                  <LableItem code="C.OrderConfirm.Note"></LableItem>
                </label>
                <textarea
                  className="w-full px-3 py-2 border border-bsmBG rounded-lg focus:outline-none focus:border-primeColor"
                  rows="4"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                ></textarea>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="w-40 h-10 bg-primeColor text-white hover:bg-black duration-300 rounded-lg"
                >
                  <LableItem code="C.OrderConfirm.SaveChanges"></LableItem>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckoutModal;
