import { store } from "../redux/store";
import { CONST_DATA } from "./constData";
import {createHeaders, refreshToken} from './http'
import BizError from "./BizError";
import { toast } from "react-toastify";
class httpFactory {
  constructor(props) {
    this.props = props;
    
    this.getLanguage();
    this.getStore();
  }
  addStore() {
    this.getStore();
    if (this.isGetMethod()) {
      if (this.props.url.includes("?")) {
        if (!this.props.url.includes("store_id")) {
          this.props.url = `${this.props.url}&store_id=${this.store_code}`;
        }
      } else {
        if (!this.props.url.includes("store_id")) {
          this.props.url = `${this.props.url}?store_id=${this.store_code}`;
        }
      }
    }
    return this;
  }
  addChannel() {
    if (this.isGetMethod())
      if (!this.props.url.includes("channel")) {
        if (this.props.url.includes("?")) {
          this.props.url = `${this.props.url}&channel=${CONST_DATA.CHANNEL}`;
        } else {
          this.props.url = `${this.props.url}?channel=${CONST_DATA.CHANNEL}`;
        }
      }
    return this;
  }
  
  async fetchData() {
    this.getLanguage();
    
    const headers = createHeaders();
    
    try {
      const response = await fetch(this.props.url, {
        method: this.props.method,
        //credentials: "include",
        headers: headers,
        body: JSON.stringify(this.props.body),
      });
      if (response.status === 401) {
         await refreshToken();
         return await this.fetchData();
      }
      if (response.status === 400) {
        const errorData = await response.json();
        return errorData;
      }
      const data = await response.json();
      return data;
    } catch (error) {
      if (error instanceof BizError) {
        toast.error(error.message);
        throw error;
      }
      else{
        throw new Error("Network response was not ok.");
      }
     
    }
  }
  getStore() {
    const state = store.getState();
   
    this.store_code = CONST_DATA.STORE_DEFAULT;
  }
  getLanguage() {
    const state = store.getState();
    this.language = state.FoxReducer.En
      ? CONST_DATA.EN_LANGUAGE
      : CONST_DATA.VN_LANGUAGE;
  }
 
  isGetMethod() {
    if (this.props.method === "get" || this.props.method === "GET") {
      return true;
    }
    return false;
  }
  isPostMethod() {
    if (this.props.method === "post" || this.props.method === "POST") {
      return true;
    }
    return false;
  }
}
export default httpFactory;
