import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import useSEO from "../../hooks/useSEO";
import { useSelector } from "react-redux";
import BackendApi from "../../common.js/backendApi";
import { fetchData } from "../../common.js/http";
import { toast } from "react-toastify";

const Contact = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  const isEn = useSelector((state) => state.FoxReducer.En);
  const En = useSelector((state) => state.FoxReducer.En);
  useSEO({
    title: "Contact",
    description: "Fox Commerce Contact page",
    keywords: ["ecommerce", "Fox", "contact"],
  });

  useEffect(() => {
    if (location.state && location.state.data) {
      setPrevLocation(location.state.data);
    } else {
      setPrevLocation("Home");
    }
  }, [location]);

  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [messages, setMessages] = useState("");
  const [subject, setSubject] = useState("");

  const [errClientName, setErrClientName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errMessages, setErrMessages] = useState("");
  const [errSubject, setErrSubject] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const handleName = (e) => {
    setClientName(e.target.value);
    setErrClientName("");
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };
  const handleMessages = (e) => {
    setMessages(e.target.value);
    setErrMessages("");
  };
  const handleSubject = (e) => {
    setSubject(e.target.value);
    setErrSubject("");
  };

  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };

  const handlePost = async (e) => {
    e.preventDefault();
    if (!clientName) {
      setErrClientName(isEn ? "Enter your Name" : "Nhập tên của bạn");
    }
    if (!email) {
      setErrEmail(isEn ? "Enter your Email" : "Nhập Email của bạn");
    } else {
      if (!EmailValidation(email)) {
        setErrEmail(isEn ? "Enter a Valid Email" : "Nhập Email hợp lệ");
      }
    }
    if (!messages) {
      setErrMessages(isEn ? "Enter your Messages" : "Nhập tin nhắn của bạn");
    }
    if (!subject) {
      setErrSubject(isEn ? "Enter your Subject" : "Nhập tiêu đề của bạn");
    }

    try {
      let props_api = {
        url: `${BackendApi.sendMail.url}`,
        method: BackendApi.sendMail.method,
        body: {
          "from": clientName,
          "mail_cc": email,
          "subject": subject,
          "msg": messages
        }
      };
      const data1 = await fetchData(props_api);
      if (data1.success) {
        setSuccessMsg(
          isEn
            ? `Thank you dear ${clientName}, your messages have been received successfully. Further details will be sent to you by your email at ${email}.`
            : `Cảm ơn ${clientName}, tin nhắn của bạn đã được nhận thành công. Chi tiết sẽ được gửi đến bạn qua email: ${email}.`
        );
      } else {
        toast.error(data1.message);
      }
    } catch (err) {
      toast.error(En ? "Add error" : "Thêm lỗi");
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4">
      <Breadcrumbs title={isEn ? "Contact" : "Liên hệ"} prevLocation={prevLocation} />
      <p className="text-lg mb-4 -mt-8">
        {isEn
          ? "For inquiries, call us at 1800646878. Our hours are Monday to Saturday, 8 AM to 8 PM, and Sunday & holidays, 8:30 AM to 5:30 PM."
          : "Để biết thêm chi tiết, vui lòng liên hệ 1800646878. Giờ làm việc từ thứ 2 đến thứ 7, từ 8g sáng đến 8g tối, chủ nhật và ngày lễ từ 8g30 sáng đến 17g30."
        }
      </p>
      {successMsg ? (
        <p className="pb-20 max-w-lg text-green-500">{successMsg}</p>
      ) : (
        <form className="pb-20 max-w-lg left-align flex flex-col gap-6">
          <h1 className="font-titleFont font-semibold text-3xl">
            {isEn ? "Fill up a Form" : "Điền vào form mẫu"}
          </h1>
          <div className="flex flex-col gap-6">
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                {isEn ? "Name" : "Tên"}
              </p>
              <input
                onChange={handleName}
                value={clientName}
                className="w-full py-2 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder={isEn ? "Enter your name here" : "Nhập tên của bạn ở đây"}
              />
              {errClientName && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2">
                  {errClientName}
                </p>
              )}
            </div>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                {isEn ? "Email" : "Email"}
              </p>
              <input
                onChange={handleEmail}
                value={email}
                className="w-full py-2 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="email"
                placeholder={isEn ? "Enter your email here" : "Nhập email của bạn ở đây"}
              />
              {errEmail && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2">
                  {errEmail}
                </p>
              )}
            </div>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                {isEn ? "Subject" : "Tiêu đề"}
              </p>
              <input
                onChange={handleSubject}
                value={subject}
                className="w-full py-2 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                placeholder={isEn ? "Enter your subject here" : "Nhập tiêu đề của bạn ở đây"}
              />
              {errSubject && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2">
                  {errSubject}
                </p>
              )}
            </div>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                {isEn ? "Messages" : "Tin nhắn"}
              </p>
              <textarea
                onChange={handleMessages}
                value={messages}
                rows="2"
                className="w-full py-2 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor resize-none"
                placeholder={isEn ? "Enter your message here" : "Nhập tin nhắn của bạn ở đây"}
              ></textarea>
              {errMessages && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2">
                  {errMessages}
                </p>
              )}
            </div>
            <button
              onClick={handlePost}
              className="w-full md:w-44 bg-primeButtonColor text-white h-10 font-titleFont text-base tracking-wide font-semibold hover:bg-black duration-200"
            >
              {isEn ? "Post" : "Gửi"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Contact;
