/*prototype*/

import React, { useEffect, useState } from "react";
import { BsGridFill } from "react-icons/bs";
import { ImList } from "react-icons/im";
import { GoTriangleDown } from "react-icons/go";
import { useSelector } from "react-redux";
import BackendApi from "../../../common.js/backendApi";
import { fetchData } from "../../../common.js/http";

const ProductBanner = ({ itemsPerPageFromBanner, itemsPerSortFromBanner,itemsPerTypeFromBanner,producttype }) => {
  // eslint-disable-next-line no-unused-vars
  //const [selected, setSelected] = useState(""); //TODO sortBy
  const [girdViewActive, setGridViewActive] = useState(true);
  const [listViewActive, setListViewActive] = useState(false);
  const En = useSelector(
    (state) => state.FoxReducer.En
  );
  const [data, setData] = useState([]);

  

  useEffect(() => {
    //TODO change view
    const gridView = document.querySelector(".gridView");
    const listView = document.querySelector(".listView");

    gridView.addEventListener("click", () => {
      setListViewActive(false);
      setGridViewActive(true);
    });
    listView.addEventListener("click", () => {
      setGridViewActive(false);
      setListViewActive(true);
    });
  }, [girdViewActive, listViewActive]);

  

  
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let props = {
          url: `${BackendApi.ListAttributes.url}?page=1&size=10`,
          method: BackendApi.ListAttributes.method,
        }
        const data1 = await fetchData(props);
        if (data1.success) {
          setData(data1.results);
        }
        else
          setData(null);

      } catch (err) {
      } finally {
      }
    }
    fetchProducts();
  }, [En]);

  let filteredData = data;

  useEffect(() => {
    producttype = producttype?? '0';
    if (producttype !== null) {
      const selectElement = document.getElementById("ItemPerType");
      if (selectElement) {
        selectElement.value = producttype;
        // Trigger the callback to inform the parent component
        itemsPerTypeFromBanner(producttype);
      }
    }
  }, [producttype]);

  
  return (
    <div className="w-full flex flex-col md:flex-row md:items-center justify-between">
      {/* =========================================================
                            Left Part Start here
        ======================================================== */}

      <div className="flex items-center gap-4" style={{ display: "none" }}>
        <span
          className={`${girdViewActive
              ? "bg-primeColor text-white"
              : "border-[1px] border-bsmBG text-[#737373]"
            } w-8 h-8 text-lg flex items-center justify-center cursor-pointer gridView`}
        >
          <BsGridFill />
        </span>
        <span
          className={`${listViewActive
              ? "bg-primeColor text-white"
              : "border-[1px] border-bsmBG text-[#737373]"
            } w-8 h-8 text-base flex items-center justify-center cursor-pointer listView`}
        >
          <ImList />
        </span>
      </div>
      {/* =========================================================
                            Left Part End here
        ======================================================== */}
      {/* =========================================================
                            Right Part STart here
        ======================================================== */}
      <div className="flex flex-wrap items-center gap-2 md:gap-6 mt-4 md:mt-0">
  <div className="flex items-center gap-2 text-base textthirdTextColor relative">
    <label className="block">{En ? "Sort By" : "Xếp theo"}</label>
    <select
      onChange={(e) => itemsPerSortFromBanner(e.target.value)}
      id="SortBy"
      className="w-32 md:w-52 border-[1px] border-cartBGLight py-1 px-4 cursor-pointer text-primeColor text-base block dark:placeholder-bsmBoder appearance-none focus-within:outline-none focus-visible:border-primeColor"
    >
      <option value="">{En ? "Default" : "Mặc định"}</option>
      <option value="DESC">{En ? "Price decreasing" : "Giá giảm dần"}</option>
      <option value="ASC">{En ? "Price from low to high" : "Giá tăng dần"}</option>
    </select>
    <span className="absolute text-sm right-2 md:right-4 top-2.5">
      <GoTriangleDown />
    </span>
  </div>

  <div className="flex items-center gap-2 textthirdTextColor relative">
    <label className="block">{En ? "Show:" : "Hiển thị:"}</label>
    <select
      onChange={(e) => itemsPerPageFromBanner(+e.target.value)} //+convert to number
      id="ItemPerPage"
      className="w-16 md:w-20 border-[1px] border-cartBGLight py-1 px-4 cursor-pointer text-primeColor text-base block dark:placeholder-bsmBoder appearance-none focus-within:outline-none focus-visible:border-primeColor"
    >
      <option value="12">12</option>
      <option value="24">24</option>
    </select>
    <span className="absolute text-sm right-3 top-2.5">
      <GoTriangleDown />
    </span>
  </div>

  <div className="flex items-center gap-4 textthirdTextColor relative">
    <label className="block">{En ? "Type:" : "Loại:"}</label>
    <select
      value={producttype}
      onChange={(e) => itemsPerTypeFromBanner(+e.target.value)} // +convert to number
      id="ItemPerType"
      className="w-48 md:w-60 border-[1px] border-cartBGLight py-1 px-4 cursor-pointer text-primeColor text-base block dark:placeholder-bsmBoder appearance-none focus-within:outline-none focus-visible:border-primeColor"
    >
      <option value="0">{En ? "Default" : "Mặc định"}</option>
      {filteredData.map((data) => (
        <option key={data.code} value={data.code}>
          {En ? data.name_en : data.name_local}
        </option>
      ))}
    </select>
    <span className="absolute text-sm right-3 top-2.5">
      <GoTriangleDown />
    </span>
  </div>
</div>

      {/* =========================================================
                            Right Part End here
        ======================================================== */}
    </div>
  );
};

export default ProductBanner;
