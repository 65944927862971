import { FaBan, FaBox, FaBoxOpen, FaCheckCircle, FaClock, FaFileInvoice, FaShoppingBag, FaTimesCircle, FaTrash, FaTruck } from "react-icons/fa";

const getStatusIcon = (status, size = "size-6") => {
  switch (status) {
    case "10":
      return (
        <>
          <FaClock className={`text-yellow-500 ${size}`} />{" "}
        </>
      );
    case "20":
      return <FaCheckCircle className={`text-blue-500 ${size}`} />;
    case "30":
      return <FaBoxOpen className={`text-purple-500 ${size}`} />;
    case "40":
      return <FaBox className={`text-indigo-500 ${size}`} />;
    case "50":
      return <FaTruck className={`text-blue-600 ${size}`} />;
    case "60":
      return <FaFileInvoice className={`text-green-600 ${size}`} />;
    case "70":
      return <FaCheckCircle className={`text-green-500 ${size}`} />;
    case "90":
      return <FaTrash className={`text-red-500 ${size}`} />;
    case "98":
      return <FaTimesCircle className={`text-red-500 ${size}`} />;
    case "99":
      return <FaBan className={`text-red-600 ${size}`} />;
    default:
      return <FaShoppingBag className={`text-gray-500 ${size}`} />;
  }
};

  export {getStatusIcon}
