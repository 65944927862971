import React, { useState } from "react";
import Pagination from "../../components/pageProps/shopPage/Pagination";
import ProductBanner from "../../components/pageProps/shopPage/ProductBanner";
import useSEO from "../../hooks/useSEO";
import { useParams, useLocation } from 'react-router-dom';
import Brand from "../../components/pageProps/shopPage/shopBy/Brand";
import Category from "../../components/pageProps/shopPage/shopBy/Category";
import Price from "../../components/pageProps/shopPage/shopBy/Price";
import { FiFilter } from 'react-icons/fi'; // Example filter icon
import { IoClose } from 'react-icons/io5'; // Close icon

const Shop = () => {
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const itemsPerPageFromBanner = (itemsPerPage) => {
    setItemsPerPage(itemsPerPage);
  };

  const [itemsPerSort, setItemsPerSort] = useState(null);
  const itemsPerSortFromBanner = (itemsPerSort) => {
    setItemsPerSort(itemsPerSort);
  };
  const { producttype } = useParams();
  const [itemsPerType, setItemsPerType] = useState(producttype ?? 0);
  const itemsPerTypeFromBanner = (itemsPerType) => {
    setItemsPerType(itemsPerType);
  };

  const [itemsPrice, setItemsPrice] = useState(-1);
  const itemsPerPrice = (itemsPrice) => {
    setItemsPrice(itemsPrice);
  };

  const [itemsBrand, setItemsBrand] = useState(null);
  const itemsPerBrand = (itemsBrand) => {
    setItemsBrand(itemsBrand);
  };

  useSEO({ title: "Shop", description: "Fox Commerce Shopping", keywords: ["ecomnerce", "Fox", "shopping"] })

  const { category } = useParams();
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
  };
  return (
    <div className="max-w-container mx-auto px-4 pt-6 md:pt-6">
      {/* Toggle button only visible on mobile */}
    
      {/* <Breadcrumbs title="Products" /> */}
      {/* ================= Products Start here =================== */}
      <br />
      <div className="w-full h-full flex pb-20">
        {/* Toggle button only visible on mobile */}
        <div className="relative">
          <div className="hidden mdl:inline-flex w-full flex-col gap-6 h-full">
            {category && category !== undefined ? (
              <>
                <Category cate_code1={category.split('_')[1]} />
                <Brand cate_code1={category.split('_')[1]} itemsPerBrand={itemsPerBrand} />
              </>
            ) : null}
            <Price itemsPerPrice={itemsPerPrice} cate_code1={category} />
          </div>
          

          {/* Filter Dialog (only visible on mobile when toggled) */}
            <div className={`${isFilterVisible ? 'visible' : 'hidden'} fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center`}>
              <div className="bg-white w-[90%] max-w-md p-6 rounded-lg shadow-lg relative max-h-[70vh] overflow-scroll">
                {/* Close Button */}
                <button
                  onClick={toggleFilterVisibility}
                  className="absolute top-2 right-2 text-2xl text-thirdTextColor hover:text-red-600 transition duration-300"
                >
                  <IoClose />
                </button>

                {/* Filter Content */}
                <div className="flex flex-col gap-6 pt-5">
                  {category && category !== undefined ? (
                    <>
                      <Category cate_code1={category.split('_')[1]} />
                      <Brand
                        cate_code1={category.split('_')[1]}
                        itemsPerBrand={itemsPerBrand}
                      />
                    </>
                  ) : null}
                  <Price itemsPerPrice={itemsPerPrice} cate_code1={category} />
                </div>
              </div>
            </div>
          
        </div>

        <div className="w-full mdl:w-[80%] lgl:w-[75%] h-full flex flex-col gap-10 lgl:ml-8 mdl:ml-8">
          <table>
            <tr>
              <td> <ProductBanner producttype={producttype} itemsPerPageFromBanner={itemsPerPageFromBanner} itemsPerSortFromBanner={itemsPerSortFromBanner} itemsPerTypeFromBanner={itemsPerTypeFromBanner} /></td>
              <td><div className="mdl:hidden flex items-center">
            <button onClick={toggleFilterVisibility} className="text-xl">
              <FiFilter />
            </button>
          </div></td>
            </tr>
          </table>
         
                    
          {/* product list */}
          <Pagination itemsPerType={itemsPerType} itemsPerSort={itemsPerSort} itemsBrand={itemsBrand} itemsPerPage={itemsPerPage} itemsPrice={itemsPrice} cate_code={category == null || category == undefined ? "0" : category.split('_')[1]} />
        </div>
      </div>
      {/* ================= Products End here ===================== */}
    </div>
  );
};

export default Shop;
