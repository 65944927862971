import React, { useState, useEffect } from "react";
import { vi, uk } from "../../assets/images";
import { LableItem } from "../../components/LabelLanguge/LabelItem";
import { useDispatch, useSelector } from "react-redux";
import { toggleLanguage, updateOrderDelivery } from "../../redux/FoxSlice";
import Image from "../../components/designLayouts/Image";
import { GetContentLangByCode } from "../../components/contentLanguage/ContentFactory";
import Select from "react-select";

import {
  getAllProvince,
  getDistrictByProvinceCode,
  getWardByDistrictCode,
} from "../../services/MasterDataServices";
import { Loading } from "../Load/Loading";
import { Link, useNavigate } from "react-router-dom";
import {
  isNullOrUndefinedOrStringEmpty,
  EmailValidation,
} from "../../utils/CheckObjectHelper";
import {
  CompareTime,
  IsMoreThenDate,
  IsMoreThenDateTime,
  IsValidTimeFromTo,
} from "../../utils/DatetimeHelper";
import _ from "lodash";
import { toast } from "react-toastify";
import { DeliveryTimeSlots } from "../../services/OrderServices";
const CheckOutProcess = () => {
  // Form state
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [province, setProvince] = useState(null);
  const [district, setDistrict] = useState("");

  const [checked, setChecked] = useState(false);
  const [note, setNote] = useState("");

  // Error state
  const [errClientName, setErrClientName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errPhone, setErrPhone] = useState("");
  const [errAddress, setErrAddress] = useState("");
  const [errCity, setErrCity] = useState("");
  const [errDistrict, setErrDistrict] = useState("");

  const [errNote, setErrNote] = useState("");
  const [provinceOptions, setProvinceOptions] = useState([]);

  const [ward, setWard] = useState(null);
  const [districtOptions, setDistricOptions] = useState([]);
  const [errWard, setErrWard] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryFromTime, setDeliveryFromTime] = useState("");
  const [errDeliveryDate, setErrDeliveryDate] = useState("");
  const [errDeliveryTime, setErrDeliveryTime] = useState("");
  const [errDeliveryFromTime, setErrDeliveryFromTime] = useState("");
  const [errDeliveryToTime, setErrDeliveryToTime] = useState("");
  const [deliveryToTime, setDeliveryToTime] = useState("");
  const [timesSlot, setTimesSlot] = useState([]);
  const [slotSelected, setSlotSelected] = useState(null);
  const [deliTimesSlot, SetDeliTimesSlot] = useState([]);
  // Loading state
  const [isLoading, setIsLoading] = useState(false);
  const [En, setEn] = useState(false);
  const [slotInputPlh, setSlotInputPlh] = useState(GetContentLangByCode("P.CheckOutProcess.SlotInputPlh"))
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Selectors
  const isEn = useSelector((state) => state.FoxReducer.En);
  const useInfo = useSelector((state) => state.FoxReducer.userInfo);
  const customer = useInfo.customers[0];
  const [wardOptions, setWardOptions] = useState([]);
  const deliveryInfo = useSelector(
    (state) => state.FoxReducer.deliveryInfomation
  );

  const wardSelected = deliveryInfo?.ward;
  const districtSelected = deliveryInfo?.district;
  const provinceSelected = deliveryInfo?.city;

  const isEmptyObject = (obj) => {
    try{
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    }
    catch(error){
      return true;
    }
    
  };
  const handleLanguageToggle = () => {
    const newLanguage = En ? "vi" : "en";
    setEn(!En);
    dispatch(toggleLanguage(newLanguage));
  };

  const handleChangeWard = (option) => {
    if (_.isNil(option)) {
      option = wardOptions[1];
    }
    setWard(option);
  };

  const handleChangeSlot = (option) => {
    // if (_.isNil(option)) {
    //   option = timesSlot[0];
    // }
    setSlotSelected(option);
    if(!_.isNil(option)){
      let timeSelected = deliTimesSlot.find(
        (item) => item.slot_code === option.value
      );
  
      setDeliveryFromTime(timeSelected.from_time);
      setDeliveryToTime(timeSelected.to_time);
    }
    
  };

  const handleChangeProvince = async (option) => {
    if (_.isNil(option)) {
      option = provinceOptions[1];
    }
    setProvince(option);
    setErrCity("");

    let districtsData = await getDistrictByProvinceCode(option.value);

    if (districtsData.length > 0) {
      setDistricOptions(districtsData);
      setDistrict(districtsData[0]);
      await handleChangeDistrict(districtsData[0]);
    }
  };
  const handleChangeDistrict = async (option) => {
    if (_.isNil(option)) {
      option = districtOptions[1];
    }
    setDistrict(option);
    setErrDistrict("");
    let wardsData = await getWardByDistrictCode(option.value);

    setWardOptions(wardsData);
    await handleChangeWard(wardsData[0]);
  };
  const SetDeliveryTimesSlot = async () => {
    let response = await DeliveryTimeSlots();
    let rs = response.map((item) => ({
      label: isEn ? item.slot_name_en : item.slot_name_local,
      value: item.slot_code,
    }));

    setTimesSlot(rs);
    SetDeliTimesSlot(response);

    if (!isEmptyObject(deliveryInfo)) {
      let slotSel = response.find(
        (o) =>
          o.from_time === deliveryInfo.delivery_time_from &&
          o.to_time === deliveryInfo.delivery_time_to
      );
      if (!isEmptyObject(slotSel)) {
        setSlotSelected({
          value: slotSel.slot_code,
          label: isEn ? slotSel.slot_name_en : slotSel.slot_name_local,
        });
      }
    }
  };
  useEffect(() => {
    const defaultLanguage = isEn ? "en" : "vi";
    dispatch(toggleLanguage(defaultLanguage));
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (isEmptyObject(deliveryInfo)) {
          if(!customer.email.startsWith("Null_")){
            setClientName(customer.customer_name);
            setEmail(customer.email);
          }
         
          setPhone(customer.phone);
          setAddress(customer.address_delivery);
        } else {
          
          if(!deliveryInfo.email.startsWith("Null_")){
            setClientName(deliveryInfo.fullName);
             setEmail(deliveryInfo.email);
          }

          
          setPhone(deliveryInfo.phone);
          setAddress(deliveryInfo.address);
          setNote(deliveryInfo.note);
          setDeliveryDate(deliveryInfo.delivery_date);
          setDeliveryFromTime(deliveryInfo.delivery_time_from);
          setDeliveryToTime(deliveryInfo.delivery_time_to);
        }
      } catch (error) {
        console.error("Error fetching customer data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    const getLocations = async () => {
      try {
        let provinces = await getAllProvince();
        setProvinceOptions(provinces);
        let localProvinceSelected;

        if (provinces.length > 0) {
          if (provinceSelected) {
            setProvince(provinceSelected);
            localProvinceSelected = provinceSelected;
          } else {
            setProvince(provinces[0]);
            localProvinceSelected = provinces[0];
          }
          let districts = await getDistrictByProvinceCode(
            localProvinceSelected.value
          );
          setDistricOptions(districts);
          if (districts.length > 0) {
            let localDistrictSeleted;
            localDistrictSeleted = districtSelected ?? districts[0];
            setDistrict(localDistrictSeleted);
            let listWards = await getWardByDistrictCode(
              localDistrictSeleted.value
            );
            if (listWards.length > 0) {
              let localWardSeleted = wardSelected ?? listWards[0];
              setWard(localWardSeleted);
            }

            setWardOptions(listWards);
          }
        }
      } catch (error) {
        console.error("Error fetching provinces data:", error);
      }
    };
    getLocations();
    setSlotInputPlh(GetContentLangByCode("P.CheckOutProcess.SlotInputPlh"));
    SetDeliveryTimesSlot();
  }, [dispatch, En]);

  const resetError = () => {
    setErrDeliveryTime("");
    setErrDeliveryFromTime("");
    setErrDeliveryToTime("");
  };
  const handleCheckout = (e) => {
    e.preventDefault();
    let isValid = true;
    resetError();
    if (isNullOrUndefinedOrStringEmpty(clientName)) {
      //setErrClientName("Enter your name");
      setErrClientName(GetContentLangByCode("Error.CheckOutProcess.EmptyName"));
      isValid = false;
    }
    if (!email) {
      //setErrEmail("Enter your email");
      setErrEmail(GetContentLangByCode("Error.CheckOutProcess.EmptyEmail"));
      isValid = false;
    } else if (!EmailValidation(email)) {
      //setErrEmail("Enter a valid email");
      setErrEmail(GetContentLangByCode("Error.CheckOutProcess.InvalidEmail"));
     // setErrEmail("Enter a valid email");
      isValid = false;
    }
    if (isNullOrUndefinedOrStringEmpty(phone)) {
      setErrPhone(GetContentLangByCode("Error.CheckOutProcess.EmptyPhone"));
      //setErrPhone("Enter your phone number");
      isValid = false;
    }
    if (isNullOrUndefinedOrStringEmpty(address)) {
      setErrAddress(GetContentLangByCode("Error.CheckOutProcess.EmptyAddress"));
      //setErrAddress("Enter your address");
      isValid = false;
    }
    if (!province) {
      setErrCity(GetContentLangByCode("Error.CheckOutProcess.EmptyProvince"));
     // setErrCity("Enter your city name");
      isValid = false;
    }
    let currentDate = new Date();
    // if (!IsMoreThenDate(deliveryDate, currentDate)) {
    //   setErrDeliveryDate("Delivery date must be after current date");
    //   isValid = false;
    // }

    let deliveryDateTimeFrom = `${deliveryDate} ${deliveryFromTime}`
    console.log(deliveryDateTimeFrom);
    console.log(new Date(deliveryDateTimeFrom));
    if (!IsMoreThenDateTime(deliveryDateTimeFrom, currentDate)) {
      setErrDeliveryDate(GetContentLangByCode("Error.CheckOutProcess.InvalidDeliveryDate"))
     // setErrDeliveryDate("Delivery date must be after current date or delivery time must be after current time");
      isValid = false;
    }

    if (!IsValidTimeFromTo(deliveryFromTime, deliveryToTime)) {
      setErrDeliveryFromTime(GetContentLangByCode("Error.CheckOutProcess.InvalidDeliveryFromTime")
       // "Delivery time from must be before delivery time to"
      );
      isValid = false;
    }
    if(isEmptyObject(slotSelected)){
      setErrDeliveryFromTime( GetContentLangByCode("Error.CheckOutProcess.EmptyPeriod") )
      //setErrDeliveryFromTime( "Delivery period time is required")
      isValid =false;
    }
    
    if (!isValid) {
      
      toast.error(GetContentLangByCode("Error.CheckOutProcess.ToastMsg"));
    }

    if (isValid && checked) {
      const checkOutInformation = {
        fullName: clientName,
        phone: phone,
        address: address,
        city: province,
        district: district,
        ward: ward,
        email: email,
        note: note,
        delivery_date: deliveryDate,
        delivery_time_from: deliveryFromTime,
        delivery_time_to: deliveryToTime,
      };

      dispatch(updateOrderDelivery(checkOutInformation));
      navigate("/order-confirmation-v2", { state: { checkOutInformation } });
    }
  };

  return (
    <div className=" h-full flex items-center justify-center  bg-cartBGLight ">
      {isLoading && (
        <Loading className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      )}
      <div className="max-w-6xl w-full px-8 py-6 bg-white shadow-lg rounded-lg">
        <form className=" h-full flex flex-col space-y-8">
          <div className="flex justify-end mb-1">
            <div
              className="relative top-4 right-4"
              onClick={handleLanguageToggle}
            >
              <Image
                className="w-8 h-8 object-cover cursor-pointer"
                imgSrc={isEn ? uk : vi}
              />
            </div>
          </div>

          <h1 className="text-2xl font-bold mb-6 text-center text-thirdTextColor">
            <LableItem code="P.CheckoutProcess.Header" />
          </h1>

          {/* Section 1: Client Information */}
          <div className="bg-bsmBG p-6 rounded-lg shadow-md space-y-6">
            <h2 className="text-xl font-bold mb-4 text-thirdTextColor">
              <LableItem code="P.CheckoutProcess.ClientInfo" />
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Client Name */}
              <div className="flex flex-col">
                <label className="text-sm font-medium text-thirdTextColor mb-1">
                  <LableItem code="P.CheckoutProcess.FullName" />
                </label>
                <input
                  onChange={(e) => {
                    setClientName(e.target.value);
                    setErrClientName("");
                  }}
                  value={clientName ?? ""}
                  className="w-full h-10 px-3 text-sm font-medium rounded-lg border border-bsmBG shadow-sm outline-none focus:ring-2 focus:ring-blue-500"
                  type="text"
                  placeholder={GetContentLangByCode(
                    "P.CheckoutProcess.FullName.Plh"
                  )}
                />
                {errClientName && (
                  <p className="text-xs text-red-500 mt-1">
                    <span className="italic mr-1">!</span>
                    {errClientName}
                  </p>
                )}
              </div>

              {/* Email */}
              <div className="flex flex-col">
                <label className="text-sm font-medium text-thirdTextColor mb-1">
                  <LableItem code="P.CheckoutProcess.Email" />
                </label>
                <input
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setErrEmail("");
                  }}
                  value={email ?? ""}
                  className="w-full h-10 px-3 text-sm font-medium rounded-lg border border-bsmBG shadow-sm outline-none focus:ring-2 focus:ring-blue-500"
                  type="email"
                  placeholder={GetContentLangByCode(
                    "P.CheckoutProcess.Email.Plh"
                  )}
                />
                {errEmail && (
                  <p className="text-xs text-red-500 mt-1">
                    <span className="italic mr-1">!</span>
                    {errEmail}
                  </p>
                )}
              </div>

              {/* Phone Number */}
              <div className="flex flex-col">
                <label className="text-sm font-medium text-thirdTextColor mb-1">
                  <LableItem code="P.CheckoutProcess.Phone" />
                </label>
                <input
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setErrPhone("");
                  }}
                  value={phone ?? ""}
                  className="w-full h-10 px-3 text-sm font-medium rounded-lg border border-bsmBG shadow-sm outline-none focus:ring-2 focus:ring-blue-500"
                  type="text"
                  placeholder={GetContentLangByCode(
                    "P.CheckoutProcess.Phone.Plh"
                  )}
                />
                {errPhone && (
                  <p className="text-xs text-red-500 mt-1">
                    <span className="italic mr-1">!</span>
                    {errPhone}
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* Section 2: Address Details */}
          <div className="bg-bsmBG p-6 rounded-lg shadow-md space-y-6">
            <h2 className="text-xl font-bold mb-4 text-thirdTextColor">
              {" "}
              <LableItem code="P.CheckoutProcess.AddressDetails" />
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {/* Address */}
              <div className="flex flex-col">
                <label className="text-sm font-medium text-thirdTextColor mb-1">
                  <LableItem code="P.CheckoutProcess.Address" />
                </label>
                <input
                  onChange={(e) => {
                    setAddress(e.target.value);
                    setErrAddress("");
                  }}
                  value={address ?? ""}
                  className="w-full h-10 px-3 text-sm font-medium rounded-lg border border-bsmBG shadow-sm outline-none focus:ring-2 focus:ring-blue-500"
                  type="text"
                  placeholder={GetContentLangByCode(
                    "P.CheckoutProcess.Address.Plh"
                  )}
                />
                {errAddress && (
                  <p className="text-xs text-red-500 mt-1">
                    <span className="italic mr-1">!</span>
                    {errAddress}
                  </p>
                )}
              </div>

              {/* City */}
              <div className="flex flex-col">
                <label className="text-sm font-medium text-thirdTextColor mb-1">
                  <LableItem code="P.CheckoutProcess.City" />
                </label>
                <Select
                  value={province}
                  options={provinceOptions}
                  onChange={handleChangeProvince}
                  isClearable={false} // Allows the user to clear the selection
                  className="w-full"
                  classNamePrefix="select"
                  
                />
                {errDistrict && (
                  <p className="text-xs text-red-500 mt-1">
                    <span className="italic mr-1">!</span>
                    {errDistrict}
                  </p>
                )}
                {errCity && (
                  <p className="text-xs text-red-500 mt-1">
                    <span className="italic mr-1">!</span>
                    {errCity}
                  </p>
                )}
              </div>

              {/* District */}
              <div className="flex flex-col">
                <label className="text-sm font-medium text-thirdTextColor mb-1">
                  <LableItem code="P.CheckoutProcess.District" />
                </label>

                <Select
                  value={district}
                  onChange={handleChangeDistrict}
                  options={districtOptions}
                  isClearable={false} // Allows the user to clear the selection
                  className="w-full"
                  classNamePrefix="select"
                />
                {errDistrict && (
                  <p className="text-xs text-red-500 mt-1">
                    <span className="italic mr-1">!</span>
                    {errDistrict}
                  </p>
                )}
              </div>

              {/* Ward */}
              <div className="flex flex-col">
                <label className="text-sm font-medium text-thirdTextColor mb-1">
                  <LableItem code="P.CheckoutProcess.Ward" />
                </label>
                <Select
                  value={ward}
                  onChange={handleChangeWard}
                  options={wardOptions}
                  isClearable={false} // Allows the user to clear the selection
                  className="w-full"
                  classNamePrefix="select"
                />
                {errWard && (
                  <p className="text-xs text-red-500 mt-1">
                    <span className="italic mr-1">!</span>
                    {errWard}
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* Section 3: Delivery Information and Notes */}
          <div className="bg-bsmBG p-6 rounded-lg shadow-md space-y-6">
            <h2 className="text-xl font-bold mb-4 text-thirdTextColor">
              <LableItem code="P.CheckoutProcess.DeliveryDateTime" />
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {/* Delivery Date */}
              <div className="flex flex-col">
                <label className="text-sm font-medium text-thirdTextColor mb-1">
                  <LableItem code="P.CheckoutProcess.DeliveryDate" />
                </label>
                <input
                  onChange={(e) => {
                    setDeliveryDate(e.target.value);
                    setErrDeliveryDate("");
                  }}
                  value={deliveryDate}
                  className="w-full h-10 px-3 text-sm font-medium rounded-lg border border-bsmBG shadow-sm outline-none focus:ring-2 focus:ring-blue-500"
                  type="date"
                />
                {errDeliveryDate && (
                  <p className="text-xs text-red-500 mt-1">
                    <span className="italic mr-1">!</span>
                    {errDeliveryDate}
                  </p>
                )}
              </div>

              {/* Delivery From Time */}
              <div className="flex flex-col">
                <label className="text-sm font-medium text-thirdTextColor mb-1">
                  <LableItem code="P.CheckoutProcess.DeliveryFromTime" />
                </label>

                <Select
                  value={slotSelected}
                  onChange={handleChangeSlot}
                  options={timesSlot}
                  isClearable={true} // Allows the user to clear the selection
                  className="w-full"
                  classNamePrefix="select"
                  placeholder={slotInputPlh}
                />
                {errDeliveryFromTime && (
                  <p className="text-xs text-red-500 mt-1">
                    <span className="italic mr-1">!</span>
                    {errDeliveryFromTime}
                  </p>
                )}
              </div>
            </div>

            {/* Note */}
            <div className="flex flex-col">
              <label className="text-sm font-medium text-thirdTextColor mb-1">
                <LableItem code="P.CheckoutProcess.Note" />
              </label>
              <textarea
                onChange={(e) => {
                  setNote(e.target.value);
                  setErrNote("");
                }}
                value={note}
                className="w-full px-3 py-2 text-sm font-medium rounded-lg border border-bsmBG shadow-sm outline-none focus:ring-2 focus:ring-blue-500"
                placeholder={GetContentLangByCode("P.CheckoutProcess.Note.Plh")}
                rows="4"
              />
              {errNote && (
                <p className="text-xs text-red-500 mt-1">
                  <span className="italic mr-1">!</span>
                  {errNote}
                </p>
              )}
            </div>
          </div>

          {/* Checkbox */}
          <div className="flex items-start gap-2">
            <input
              onChange={() => setChecked(!checked)}
              className="w-5 h-5 mt-1 cursor-pointer"
              type="checkbox"
            />
            <p className="text-xs text-blue-500">
              <LableItem code="P.CheckoutProcess.Agree" />{" "}
              <a href="/chinh-sach-bao-mat" target="_blank" rel="noopener noreferrer">
                {isEn?(<><span className="text-blue-500">Terms of Service</span> and{"  "}
                  <span className="text-blue-500">Privacy Policy</span>.</>): 
                  (<>
                      <span className="text-blue-500">Điều khoản sử dụng</span> và {"  "}
                      <span className="text-blue-500">Quyền riêng tư</span>.
                  </>)
                  }
                
              </a>
              
            </p>
          </div>

          {/* Submit Button */}
          <button
            onClick={handleCheckout}
            className={`${
              checked
                ? "bg-primeColor hover:bg-primeColor text-white cursor-pointer"
                : "bg-bsmBoder text-cartBGLight cursor-not-allowed"
            } w-1/3 text-lg font-semibold h-12 rounded-lg transition-colors duration-300`}
          >
            <LableItem code="P.CheckoutProcess.CheckOut" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default CheckOutProcess;
