import React from "react";

const Rating = ({ rating = 0, totalStars = 5 }) => {
  // Function to render stars
  const renderStars = () => {
    let stars = [];
    for (let i = 1; i <= totalStars; i++) {
      stars.push(
        <span key={i} style={{ color: i <= rating ? "#FFD700" : "#e4e5e9",fontSize:"20px" }}>
          ★
        </span>
      );
    }
    return stars;
  };

  return <div>{renderStars()}</div>;
};

export default Rating;
