import React from 'react';

const Loading = () => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-thirdTextColor bg-opacity-50 z-50">
           <div className="flex items-center justify-center h-screen">
                 <div className="w-16 h-16 border-4 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
            </div>
        </div>
    );
};

export {Loading};
