import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Heading from "../../home/Products/Heading";
import ProductV2 from "../../home/Products/ProductV2";
import { useSelector } from "react-redux";
import SampleNextArrow from "../../home/NewArrivals/SampleNextArrow";
import SamplePrevArrow from "../../home/NewArrivals/SamplePrevArrow";

// import { productList } from "../../../common.js/db"
import { FoxProduct_Home } from "../../../common.js/transformation";
import BackendApi from "../../../common.js/backendApi";
import { fetchData } from "../../../common.js/http";
import { CONST_DATA } from "../../../common.js/constData";
const RelatedProducts = ({productInfo}) => {
  const En = useSelector((state) => state.FoxReducer.En);
  const store_code = useSelector((state) => state.FoxReducer.useSelectedStore);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!productInfo.cat) {
      return;
    }
    const fetchProducts = async () => {
      try {
        let props = {
          url: `${BackendApi.allProducts.url}?page=1&size=10&active=true&published=true&store_id=${CONST_DATA.STORE_DEFAULT}&category_code=${productInfo.cat}&scopes=["ProductOrigin","ProductCategory","ProductAttribute","ProductPrice","ProductImage"]`,
          method: BackendApi.allProducts.method,
        };
        const data1 = await fetchData(props);
        if (data1.success) {
          setData(data1.results);
        } else setData(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
  }, [store_code, En,productInfo.cat]);
  let filteredData = data;
  return (
    <div className="w-full pb-16">
      <Heading heading={En ? "Related Products" : "Sản phẩm liên quan"} />

      {filteredData && filteredData.length > 0 ? (
        <Slider {...settings}>
          {filteredData.map((itemData) => {
            let item = FoxProduct_Home(itemData, En);
            return (
              <div className="px-2" key={item.product_code}>
                <ProductV2
                  key={item.product_code}
                  product_code={item.product_code}
                  slug={item.slug}
                  sku={item.sku}
                  image={item.image}
                  name={item.name}
                  price={item.price}
                  retail_price={item.retail_price}
                  price_ex_vat={item.price_ex_vat}
                  retail_price_ex_vat={item.retail_price_ex_vat}
                  vat_code={item.vat_code}
                  vat_rate={item.vat_rate}
                  color={item.color}
                  badge={item.badge}
                  des={item.des}
                  cat={item.cat}
                  cat_name={item.cat_name}
                  unit={item.unit}
                />
              </div>
            );
          })}
        </Slider>
      ) : (
        <div>{En ? "No products available" : "Không có sản phẩm nào khả dụng"}</div>
      )}
    </div>
  );
};


export default RelatedProducts;
