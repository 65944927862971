
export const categoryList = [
    {
        "category_code": "15",
        "channel_code": "1",
        "seq": 1,
        "parent_code": "1",
        "name_en": "Fresh products",
        "name_local": "Sản Phẩm Tươi Sống",
        "image": null,
        "active": true,
        "start_date": "2024-02-29T17:00:00.000Z",
        "end_date": "2049-12-30T17:00:00.000Z",
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "origin_categories": [],
        "parent_category": {
            "category_code": "1",
            "channel_code": "1",
            "seq": 1,
            "parent_code": null,
            "name_en": "BSM",
            "name_local": "BSM",
            "image": null,
            "active": true,
            "start_date": "2024-02-29T17:00:00.000Z",
            "end_date": "2049-12-30T17:00:00.000Z",
            "created_by": "foxcms",
            "updated_by": null,
            "created_at": "2024-03-12T10:14:41.531Z",
            "updated_at": "2024-03-12T10:14:41.531Z"
        },
        "child_categories": [
            {
                "category_code": "1530",
                "channel_code": "1",
                "seq": 2,
                "parent_code": "15",
                "name_en": "frozen",
                "name_local": "Đông lạnh",
                "image": null,
                "active": true,
                "start_date": "2024-06-22T17:00:00.000Z",
                "end_date": "2049-12-30T17:00:00.000Z",
                "created_by": null,
                "updated_by": null,
                "created_at": null,
                "updated_at": null
            },
            {
                "category_code": "1520",
                "channel_code": "1",
                "seq": 2,
                "parent_code": "15",
                "name_en": "Fish",
                "name_local": "Cá",
                "image": null,
                "active": true,
                "start_date": "2024-02-29T17:00:00.000Z",
                "end_date": "2049-12-30T17:00:00.000Z",
                "created_by": "foxcms",
                "updated_by": null,
                "created_at": "2024-03-12T10:18:17.634Z",
                "updated_at": "2024-03-12T10:18:17.635Z"
            },
            {
                "category_code": "1510",
                "channel_code": "1",
                "seq": 1,
                "parent_code": "15",
                "name_en": "Meat",
                "name_local": "Thịt",
                "image": null,
                "active": true,
                "start_date": "2024-02-29T17:00:00.000Z",
                "end_date": "2049-12-30T17:00:00.000Z",
                "created_by": "foxcms",
                "updated_by": "API",
                "created_at": "2024-03-12T10:16:16.985Z",
                "updated_at": "2024-03-12T10:16:16.985Z"
            }
        ]
    }
]

export const brandList = [
    {
        "id": 1,
        "code": 0,
        "type_id": 1,
        "name_en": "NO BRAND",
        "name_local": "NO BRAND",
        "active": true,
        "created_by": "AUTO_SYNC",
        "updated_by": null,
        "created_at": "2024-03-17T09:14:19.000Z",
        "updated_at": "2024-03-17T09:14:19.000Z",
        "BrandType": {
            "id": 1,
            "type_code": "BRAN",
            "name_en": "Supplier Brand",
            "name_local": "Supplier Brand",
            "brand_desc": "Supplier Brand",
            "active": true,
            "created_by": "API",
            "updated_by": "API",
            "created_at": "2024-03-12T10:01:52.338Z",
            "updated_at": "2024-03-12T10:01:52.338Z"
        }
    },
    {
        "id": 2,
        "code": 1,
        "type_id": 1,
        "name_en": "0DO",
        "name_local": "0DO",
        "active": true,
        "created_by": "AUTO_SYNC",
        "updated_by": null,
        "created_at": "2024-03-17T09:14:19.000Z",
        "updated_at": "2024-03-17T09:14:19.000Z",
        "BrandType": {
            "id": 1,
            "type_code": "BRAN",
            "name_en": "Supplier Brand",
            "name_local": "Supplier Brand",
            "brand_desc": "Supplier Brand",
            "active": true,
            "created_by": "API",
            "updated_by": "API",
            "created_at": "2024-03-12T10:01:52.338Z",
            "updated_at": "2024-03-12T10:01:52.338Z"
        }
    },
    {
        "id": 3,
        "code": 10,
        "type_id": 1,
        "name_en": "AJI MAYO",
        "name_local": "AJI MAYO",
        "active": true,
        "created_by": "AUTO_SYNC",
        "updated_by": null,
        "created_at": "2024-03-17T09:14:19.000Z",
        "updated_at": "2024-03-17T09:14:19.000Z",
        "BrandType": {
            "id": 1,
            "type_code": "BRAN",
            "name_en": "Supplier Brand",
            "name_local": "Supplier Brand",
            "brand_desc": "Supplier Brand",
            "active": true,
            "created_by": "API",
            "updated_by": "API",
            "created_at": "2024-03-12T10:01:52.338Z",
            "updated_at": "2024-03-12T10:01:52.338Z"
        }
    }
]

export const productList = [
    {
        "product_code": "100631",
        "channel_code": "1",
        "category_code": "1530",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "100631",
            "sku": "21006319",
            "slug_url": "xoai-xanh-ngu-qua-chung-tet-100631",
            "keywords": "new",
            "parent_code": null,
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "MANGO WITH BRANCH",
            "name_local": "XOAI XANH NGU QUA-CHUNG TET",
            "short_desc_en": "MANGO WITH BRANCH",
            "short_desc_local": "XOAI XANH NGU QUA-CH",
            "full_desc_en": "MANGO WITH BRANCH",
            "full_desc_local": "XOAI XANH NGU QUA-CHUNG TET",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5545,
                    "product_code": "100631",
                    "attribute_code": "4",
                    "attribute_value": "hot deal",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.725Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1530",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "frozen",
                    "name_local": "Đông lạnh",
                    "image": null,
                    "active": true,
                    "start_date": "2024-06-22T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": null,
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null,
                    "ProductCategory": {
                        "product_code": "100631",
                        "channel_code": "1",
                        "category_code": "1530",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 437446,
                    "product_code": "100631",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 34190,
                    "normal_price_in_vat": 35900,
                    "promotion_price_ex_vat": 24190,
                    "promotion_price_in_vat": 25900,
                    "promotion_start": "2017-12-01T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 290401,
                    "product_code": "100631",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/100631.jpg",
                    "title": "XOAI XANH NGU QUA-CH",
                    "alternate": "XOAI XANH NGU QUA-CHUNG TET",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "100683",
        "channel_code": "1",
        "category_code": "1530",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "100683",
            "sku": "8935132556137",
            "slug_url": "rau-nhut-chua-w-a-fresh-100gr-100683",
            "keywords": "new",
            "parent_code": "335839",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "SORREL-W.A.FRESH-100GR",
            "name_local": "RAU NHUT CHUA W.A.FRESH-100GR",
            "short_desc_en": "SORREL-W.A.FRESH-100",
            "short_desc_local": "RAU NHUT CHUA W.A.FR",
            "full_desc_en": "SORREL-W.A.FRESH-100GR",
            "full_desc_local": "RAU NHUT CHUA W.A.FRESH-100GR",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5546,
                    "product_code": "100683",
                    "attribute_code": "4",
                    "attribute_value": "hot deal",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.725Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1530",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "frozen",
                    "name_local": "Đông lạnh",
                    "image": null,
                    "active": true,
                    "start_date": "2024-06-22T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": null,
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null,
                    "ProductCategory": {
                        "product_code": "100683",
                        "channel_code": "1",
                        "category_code": "1530",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 85797,
                    "product_code": "100683",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 24762,
                    "normal_price_in_vat": 26000,
                    "promotion_price_ex_vat": 14762,
                    "promotion_price_in_vat": 16000,
                    "promotion_start": "2021-07-07T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 290615,
                    "product_code": "100683",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/100683.jpg",
                    "title": "RAU NHUT CHUA W.A.FR",
                    "alternate": "RAU NHUT CHUA W.A.FRESH-100GR",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "101387",
        "channel_code": "1",
        "category_code": "1520",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "101387",
            "sku": "21013874",
            "slug_url": "so-huyet-song-60-80-con-kg-101387",
            "keywords": "new",
            "parent_code": "375340",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "LIVE RED CLEANED COCKLE",
            "name_local": "SO HUYET (SONG) 60-80 CON/KG",
            "short_desc_en": "LIVE RED CLEANED COC",
            "short_desc_local": "SO HUYET (SONG) 60-8",
            "full_desc_en": "LIVE RED CLEANED COCKLE",
            "full_desc_local": "SO HUYET (SONG) 60-80 CON/KG",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5549,
                    "product_code": "101387",
                    "attribute_code": "5",
                    "attribute_value": "shocking price",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.725Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1520",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "Fish",
                    "name_local": "Cá",
                    "image": null,
                    "active": true,
                    "start_date": "2024-02-29T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": "foxcms",
                    "updated_by": null,
                    "created_at": "2024-03-12T10:18:17.634Z",
                    "updated_at": "2024-03-12T10:18:17.635Z",
                    "ProductCategory": {
                        "product_code": "101387",
                        "channel_code": "1",
                        "category_code": "1520",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 477245,
                    "product_code": "101387",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 219048,
                    "normal_price_in_vat": 230000,
                    "promotion_price_ex_vat": 119048,
                    "promotion_price_in_vat": 130000,
                    "promotion_start": "2021-04-02T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291359,
                    "product_code": "101387",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/101387.jpg",
                    "title": "SO HUYET (SONG) 60-8",
                    "alternate": "SO HUYET (SONG) 60-80 CON/KG",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "102609",
        "channel_code": "1",
        "category_code": "1530",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "102609",
            "sku": "21026096",
            "slug_url": "chanh-khong-hat-loai-1-102609",
            "keywords": "new",
            "parent_code": "332123",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "SEEDLESS LEMON-GRADE 1",
            "name_local": "CHANH KHONG HAT-LOAI 1",
            "short_desc_en": "SEEDLESS LEMON-GRADE",
            "short_desc_local": "CHANH KHONG HAT-LOAI",
            "full_desc_en": "SEEDLESS LEMON-GRADE 1",
            "full_desc_local": "CHANH KHONG HAT-LOAI 1",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5552,
                    "product_code": "102609",
                    "attribute_code": "5",
                    "attribute_value": "shocking price",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.725Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1530",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "frozen",
                    "name_local": "Đông lạnh",
                    "image": null,
                    "active": true,
                    "start_date": "2024-06-22T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": null,
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null,
                    "ProductCategory": {
                        "product_code": "102609",
                        "channel_code": "1",
                        "category_code": "1530",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 195863,
                    "product_code": "102609",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 20000,
                    "normal_price_in_vat": 21000,
                    "promotion_price_ex_vat": 10000,
                    "promotion_price_in_vat": 11000,
                    "promotion_start": "2021-07-15T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291306,
                    "product_code": "102609",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/102609.jpg",
                    "title": "CHANH KHONG HAT-LOAI",
                    "alternate": "CHANH KHONG HAT-LOAI 1",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "103528",
        "channel_code": "1",
        "category_code": "1530",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "103528",
            "sku": "21035289",
            "slug_url": "nam-bao-ngu-dalat-103528",
            "keywords": "new",
            "parent_code": "335874",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "DALAT OYSTER MUSHROOM",
            "name_local": "NAM BAO NGU DALAT",
            "short_desc_en": "DALAT OYSTER MUSHROO",
            "short_desc_local": "NAM BAO NGU DALAT",
            "full_desc_en": "DALAT OYSTER MUSHROOM",
            "full_desc_local": "NAM BAO NGU DALAT",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5561,
                    "product_code": "103528",
                    "attribute_code": "4",
                    "attribute_value": "hot deal",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.726Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1530",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "frozen",
                    "name_local": "Đông lạnh",
                    "image": null,
                    "active": true,
                    "start_date": "2024-06-22T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": null,
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null,
                    "ProductCategory": {
                        "product_code": "103528",
                        "channel_code": "1",
                        "category_code": "1530",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 264313,
                    "product_code": "103528",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 45714,
                    "normal_price_in_vat": 48000,
                    "promotion_price_ex_vat": 45714,
                    "promotion_price_in_vat": 48000,
                    "promotion_start": "2021-07-07T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291025,
                    "product_code": "103528",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/103528.jpg",
                    "title": "NAM BAO NGU DALAT",
                    "alternate": "NAM BAO NGU DALAT",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "104601",
        "channel_code": "1",
        "category_code": "1530",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "104601",
            "sku": "21046018",
            "slug_url": "ot-sung-xanh-dalat-104601",
            "keywords": "new",
            "parent_code": "336680",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "DALAT GREEN CHILI PEPPER",
            "name_local": "OT SUNG XANH DALAT",
            "short_desc_en": "DALAT GREEN CHILI PE",
            "short_desc_local": "OT SUNG XANH DALAT",
            "full_desc_en": "DALAT GREEN CHILI PEPPER",
            "full_desc_local": "OT SUNG XANH DALAT",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5565,
                    "product_code": "104601",
                    "attribute_code": "4",
                    "attribute_value": "hot deal",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.726Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1530",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "frozen",
                    "name_local": "Đông lạnh",
                    "image": null,
                    "active": true,
                    "start_date": "2024-06-22T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": null,
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null,
                    "ProductCategory": {
                        "product_code": "104601",
                        "channel_code": "1",
                        "category_code": "1530",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 405070,
                    "product_code": "104601",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 60952,
                    "normal_price_in_vat": 64000,
                    "promotion_price_ex_vat": 60952,
                    "promotion_price_in_vat": 64000,
                    "promotion_start": "2021-07-10T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291037,
                    "product_code": "104601",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/104601.jpg",
                    "title": "OT SUNG XANH DALAT",
                    "alternate": "OT SUNG XANH DALAT",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "104603",
        "channel_code": "1",
        "category_code": "1530",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "104603",
            "sku": "8938535082906",
            "slug_url": "toi-ly-son-500g-tui-luoi-104603",
            "keywords": "new",
            "parent_code": "203988",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "LY SON GARLIC 500G (NET BAG)",
            "name_local": "TOI LY SON-500G (TUI LUOI)",
            "short_desc_en": "LY SON GARLIC 500G (",
            "short_desc_local": "TOI LY SON-500G (TUI",
            "full_desc_en": "LY SON GARLIC 500G (NET BAG)",
            "full_desc_local": "TOI LY SON-500G (TUI LUOI)",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5566,
                    "product_code": "104603",
                    "attribute_code": "4",
                    "attribute_value": "hot deal",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.726Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1530",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "frozen",
                    "name_local": "Đông lạnh",
                    "image": null,
                    "active": true,
                    "start_date": "2024-06-22T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": null,
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null,
                    "ProductCategory": {
                        "product_code": "104603",
                        "channel_code": "1",
                        "category_code": "1530",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 266184,
                    "product_code": "104603",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 42857,
                    "normal_price_in_vat": 45000,
                    "promotion_price_ex_vat": 42857,
                    "promotion_price_in_vat": 45000,
                    "promotion_start": "2021-03-17T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291045,
                    "product_code": "104603",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/104603.jpg",
                    "title": "TOI LY SON-500G (TUI",
                    "alternate": "TOI LY SON-500G (TUI LUOI)",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "104631",
        "channel_code": "1",
        "category_code": "1530",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "104631",
            "sku": "21046315",
            "slug_url": "cai-thao-hoa-tien-dalat-104631",
            "keywords": "new",
            "parent_code": "384132",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "ROKET CABBAGE",
            "name_local": "CAI THAO HOA TIEN DALAT",
            "short_desc_en": "ROKET CABBAGE",
            "short_desc_local": "CAI THAO HOA TIEN DA",
            "full_desc_en": "ROKET CABBAGE",
            "full_desc_local": "CAI THAO HOA TIEN DALAT",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5567,
                    "product_code": "104631",
                    "attribute_code": "4",
                    "attribute_value": "hot deal",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.726Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1530",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "frozen",
                    "name_local": "Đông lạnh",
                    "image": null,
                    "active": true,
                    "start_date": "2024-06-22T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": null,
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null,
                    "ProductCategory": {
                        "product_code": "104631",
                        "channel_code": "1",
                        "category_code": "1530",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 2689901,
                    "product_code": "104631",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 24762,
                    "normal_price_in_vat": 26000,
                    "promotion_price_ex_vat": 24762,
                    "promotion_price_in_vat": 26000,
                    "promotion_start": "2024-02-20T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-25T04:17:39.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 297515,
                    "product_code": "104631",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/104631.jpg",
                    "title": "CAI THAO HOA TIEN DA",
                    "alternate": "CAI THAO HOA TIEN DALAT",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "105139",
        "channel_code": "1",
        "category_code": "1530",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "105139",
            "sku": "21051395",
            "slug_url": "nho-do-khong-hat-my-xx-my-105139",
            "keywords": "new",
            "parent_code": "368128",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "CRIMSON SEEDLESS GRAPES - USA",
            "name_local": "NHO DO KHONG HAT MY (XX MY)",
            "short_desc_en": "CRIMSON SEEDLESS GRA",
            "short_desc_local": "NHO DO KHONG HAT MY ",
            "full_desc_en": "CRIMSON SEEDLESS GRAPES - USA",
            "full_desc_local": "NHO DO KHONG HAT MY (XX MY)",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5571,
                    "product_code": "105139",
                    "attribute_code": "4",
                    "attribute_value": "hot deal",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.726Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1530",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "frozen",
                    "name_local": "Đông lạnh",
                    "image": null,
                    "active": true,
                    "start_date": "2024-06-22T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": null,
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null,
                    "ProductCategory": {
                        "product_code": "105139",
                        "channel_code": "1",
                        "category_code": "1530",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 470069,
                    "product_code": "105139",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 111429,
                    "normal_price_in_vat": 117000,
                    "promotion_price_ex_vat": 111429,
                    "promotion_price_in_vat": 117000,
                    "promotion_start": "2020-12-29T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291174,
                    "product_code": "105139",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/105139.jpg",
                    "title": "NHO DO KHONG HAT MY ",
                    "alternate": "NHO DO KHONG HAT MY (XX MY)",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "105499",
        "channel_code": "1",
        "category_code": "1520",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "105499",
            "sku": "8935067794307",
            "slug_url": "muc-ong-nguyen-6-8cm-500gr-105499",
            "keywords": "new",
            "parent_code": null,
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "WHOLE SQUID SIZE (6-8CM) 50GR",
            "name_local": "MUC ONG NGUYEN (6-8CM) 500GR",
            "short_desc_en": "WHOLE SQUID SIZE (6-",
            "short_desc_local": "MUC ONG NGUYEN (6-8C",
            "full_desc_en": "WHOLE SQUID SIZE (6-8CM) 50GR",
            "full_desc_local": "MUC ONG NGUYEN (6-8CM) 500GR",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5576,
                    "product_code": "105499",
                    "attribute_code": "5",
                    "attribute_value": "shocking price",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.726Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1520",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "Fish",
                    "name_local": "Cá",
                    "image": null,
                    "active": true,
                    "start_date": "2024-02-29T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": "foxcms",
                    "updated_by": null,
                    "created_at": "2024-03-12T10:18:17.634Z",
                    "updated_at": "2024-03-12T10:18:17.635Z",
                    "ProductCategory": {
                        "product_code": "105499",
                        "channel_code": "1",
                        "category_code": "1520",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 384154,
                    "product_code": "105499",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 83143,
                    "normal_price_in_vat": 87300,
                    "promotion_price_ex_vat": 83143,
                    "promotion_price_in_vat": 87300,
                    "promotion_start": "2018-03-20T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291908,
                    "product_code": "105499",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/105499.jpg",
                    "title": "MUC ONG NGUYEN (6-8C",
                    "alternate": "MUC ONG NGUYEN (6-8CM) 500GR",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "105722",
        "channel_code": "1",
        "category_code": "1530",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "105722",
            "sku": "8938508700097",
            "slug_url": "dua-xuan-thu-ngo-sen-500g-105722",
            "keywords": "best sellers",
            "parent_code": "332625",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "VEGETABLE PICKLES- XUAN THU",
            "name_local": "DUA XUAN THU-NGO SEN-500G",
            "short_desc_en": "VEGETABLE PICKLES- X",
            "short_desc_local": "DUA XUAN THU-NGO SEN",
            "full_desc_en": "VEGETABLE PICKLES- XUAN THU",
            "full_desc_local": "DUA XUAN THU-NGO SEN-500G",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5577,
                    "product_code": "105722",
                    "attribute_code": "3",
                    "attribute_value": "flash sale",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.726Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1530",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "frozen",
                    "name_local": "Đông lạnh",
                    "image": null,
                    "active": true,
                    "start_date": "2024-06-22T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": null,
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null,
                    "ProductCategory": {
                        "product_code": "105722",
                        "channel_code": "1",
                        "category_code": "1530",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 338955,
                    "product_code": "105722",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 31019,
                    "normal_price_in_vat": 33500,
                    "promotion_price_ex_vat": 31019,
                    "promotion_price_in_vat": 33500,
                    "promotion_start": "2022-01-26T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291529,
                    "product_code": "105722",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/105722.jpg",
                    "title": "DUA XUAN THU-NGO SEN",
                    "alternate": "DUA XUAN THU-NGO SEN-500G",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "105726",
        "channel_code": "1",
        "category_code": "1530",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "105726",
            "sku": "8938508700080",
            "slug_url": "dua-xuan-thu-kim-chi-ca-phao-400g-105726",
            "keywords": "best sellers",
            "parent_code": "332627",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "VEGETABLE PICKLES- XUAN THU",
            "name_local": "DUA XUAN THU-KIM CHI CA PHAO-400G",
            "short_desc_en": "VEGETABLE PICKLES- X",
            "short_desc_local": "DUA XUAN THU-KIM CHI",
            "full_desc_en": "VEGETABLE PICKLES- XUAN THU",
            "full_desc_local": "DUA XUAN THU-KIM CHI CA PHAO-400G",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5578,
                    "product_code": "105726",
                    "attribute_code": "1",
                    "attribute_value": "hot promotion",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.726Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1530",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "frozen",
                    "name_local": "Đông lạnh",
                    "image": null,
                    "active": true,
                    "start_date": "2024-06-22T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": null,
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null,
                    "ProductCategory": {
                        "product_code": "105726",
                        "channel_code": "1",
                        "category_code": "1530",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 67072,
                    "product_code": "105726",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 24074,
                    "normal_price_in_vat": 26000,
                    "promotion_price_ex_vat": 24074,
                    "promotion_price_in_vat": 26000,
                    "promotion_start": "2021-12-31T17:00:00.000Z",
                    "promotion_end": "2022-01-18T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 297578,
                    "product_code": "105726",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/105726.jpg",
                    "title": "DUA XUAN THU-KIM CHI",
                    "alternate": "DUA XUAN THU-KIM CHI CA PHAO-400G",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "106321",
        "channel_code": "1",
        "category_code": "1520",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "106321",
            "sku": "8938535652321",
            "slug_url": "tom-su-pd-hap-camimex-61-70-500gr-106321",
            "keywords": "best sellers",
            "parent_code": null,
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "SUGPO PRAWN 500GR",
            "name_local": "TOM SU PD HAP CAMIMEX 61-70 500GR",
            "short_desc_en": "SUGPO PRAWN 500GR",
            "short_desc_local": "TOM SU PD HAP CAMIME",
            "full_desc_en": "SUGPO PRAWN 500GR",
            "full_desc_local": "TOM SU PD HAP CAMIMEX 61-70 500GR",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5582,
                    "product_code": "106321",
                    "attribute_code": "3",
                    "attribute_value": "flash sale",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.726Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1520",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "Fish",
                    "name_local": "Cá",
                    "image": null,
                    "active": true,
                    "start_date": "2024-02-29T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": "foxcms",
                    "updated_by": null,
                    "created_at": "2024-03-12T10:18:17.634Z",
                    "updated_at": "2024-03-12T10:18:17.635Z",
                    "ProductCategory": {
                        "product_code": "106321",
                        "channel_code": "1",
                        "category_code": "1520",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 350285,
                    "product_code": "106321",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 140190,
                    "normal_price_in_vat": 147200,
                    "promotion_price_ex_vat": 140190,
                    "promotion_price_in_vat": 147200,
                    "promotion_start": "2020-10-07T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291456,
                    "product_code": "106321",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/106321.jpg",
                    "title": "TOM SU PD HAP CAMIME",
                    "alternate": "TOM SU PD HAP CAMIMEX 61-70 500GR",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "106979",
        "channel_code": "1",
        "category_code": "1520",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "106979",
            "sku": "8936201250611",
            "slug_url": "tom-su-pd-hap-camimex100-200-500g-106979",
            "keywords": "best sellers",
            "parent_code": null,
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "TOM SU PD HAP SIZE 100/200 500GR",
            "name_local": "TOM SU PD HAP CAMIMEX100-200 500G",
            "short_desc_en": "TOM SU PD HAP SIZE 1",
            "short_desc_local": "TOM SU PD HAP CAMIME",
            "full_desc_en": "TOM SU PD HAP SIZE 100/200 500GR",
            "full_desc_local": "TOM SU PD HAP CAMIMEX100-200 500G",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5584,
                    "product_code": "106979",
                    "attribute_code": "2",
                    "attribute_value": "best selling",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.726Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1520",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "Fish",
                    "name_local": "Cá",
                    "image": null,
                    "active": true,
                    "start_date": "2024-02-29T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": "foxcms",
                    "updated_by": null,
                    "created_at": "2024-03-12T10:18:17.634Z",
                    "updated_at": "2024-03-12T10:18:17.635Z",
                    "ProductCategory": {
                        "product_code": "106979",
                        "channel_code": "1",
                        "category_code": "1520",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 425893,
                    "product_code": "106979",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 111905,
                    "normal_price_in_vat": 117500,
                    "promotion_price_ex_vat": 111905,
                    "promotion_price_in_vat": 117500,
                    "promotion_start": "2020-10-07T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291389,
                    "product_code": "106979",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/106979.jpg",
                    "title": "TOM SU PD HAP CAMIME",
                    "alternate": "TOM SU PD HAP CAMIMEX100-200 500G",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "107994",
        "channel_code": "1",
        "category_code": "1530",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "107994",
            "sku": "21079948",
            "slug_url": "dua-hau-hmn-107994",
            "keywords": "best sellers",
            "parent_code": "331882",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "WATER MELON-GRADE 2",
            "name_local": "DUA HAU HMN",
            "short_desc_en": "WATER MELON-GRADE 2",
            "short_desc_local": "DUA HAU HMN",
            "full_desc_en": "WATER MELON-GRADE 2",
            "full_desc_local": "DUA HAU HMN",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5595,
                    "product_code": "107994",
                    "attribute_code": "2",
                    "attribute_value": "best selling",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.727Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1530",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "frozen",
                    "name_local": "Đông lạnh",
                    "image": null,
                    "active": true,
                    "start_date": "2024-06-22T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": null,
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null,
                    "ProductCategory": {
                        "product_code": "107994",
                        "channel_code": "1",
                        "category_code": "1530",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 467732,
                    "product_code": "107994",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 13333,
                    "normal_price_in_vat": 14000,
                    "promotion_price_ex_vat": 13333,
                    "promotion_price_in_vat": 14000,
                    "promotion_start": "2021-07-15T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291046,
                    "product_code": "107994",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/107994.jpg",
                    "title": "DUA HAU HMN",
                    "alternate": "DUA HAU HMN",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "107999",
        "channel_code": "1",
        "category_code": "1530",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "107999",
            "sku": "21079993",
            "slug_url": "dua-hau-ruot-vang-loai-1-107999",
            "keywords": "best sellers",
            "parent_code": "331883",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "WATER-MELON-YELLOW FLESH(GRADE 1)",
            "name_local": "DUA HAU RUOT VANG LOAI 1",
            "short_desc_en": "WATER-MELON-YELLOW F",
            "short_desc_local": "DUA HAU RUOT VANG LO",
            "full_desc_en": "WATER-MELON-YELLOW FLESH(GRADE 1)",
            "full_desc_local": "DUA HAU RUOT VANG LOAI 1",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5596,
                    "product_code": "107999",
                    "attribute_code": "4",
                    "attribute_value": "hot deal",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.727Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1530",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "frozen",
                    "name_local": "Đông lạnh",
                    "image": null,
                    "active": true,
                    "start_date": "2024-06-22T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": null,
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null,
                    "ProductCategory": {
                        "product_code": "107999",
                        "channel_code": "1",
                        "category_code": "1530",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 459339,
                    "product_code": "107999",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 17143,
                    "normal_price_in_vat": 18000,
                    "promotion_price_ex_vat": 17143,
                    "promotion_price_in_vat": 18000,
                    "promotion_start": "2021-07-08T17:00:00.000Z",
                    "promotion_end": "2049-12-29T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291248,
                    "product_code": "107999",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/107999.jpg",
                    "title": "DUA HAU RUOT VANG LO",
                    "alternate": "DUA HAU RUOT VANG LOAI 1",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "108147",
        "channel_code": "1",
        "category_code": "1520",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "108147",
            "sku": "21081477",
            "slug_url": "tom-the-tuoi-40-60-con-kg-108147",
            "keywords": "best sellers",
            "parent_code": "371261",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "FRESH WHITE SHRIMP 40-60 PC/KG",
            "name_local": "TOM THE (TUOI) 40-60 CON/KG",
            "short_desc_en": "FRESH WHITE SHRIMP 4",
            "short_desc_local": "TOM THE (TUOI) 40-60",
            "full_desc_en": "FRESH WHITE SHRIMP 40-60 PC/KG",
            "full_desc_local": "TOM THE (TUOI) 40-60 CON/KG",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5599,
                    "product_code": "108147",
                    "attribute_code": "3",
                    "attribute_value": "flash sale",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.727Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1520",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "Fish",
                    "name_local": "Cá",
                    "image": null,
                    "active": true,
                    "start_date": "2024-02-29T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": "foxcms",
                    "updated_by": null,
                    "created_at": "2024-03-12T10:18:17.634Z",
                    "updated_at": "2024-03-12T10:18:17.635Z",
                    "ProductCategory": {
                        "product_code": "108147",
                        "channel_code": "1",
                        "category_code": "1520",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 404721,
                    "product_code": "108147",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 232190,
                    "normal_price_in_vat": 243800,
                    "promotion_price_ex_vat": 232190,
                    "promotion_price_in_vat": 243800,
                    "promotion_start": "2021-04-20T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291762,
                    "product_code": "108147",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/108147.jpg",
                    "title": "TOM THE (TUOI) 40-60",
                    "alternate": "TOM THE (TUOI) 40-60 CON/KG",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "109921",
        "channel_code": "1",
        "category_code": "1520",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "109921",
            "sku": "8935067792785",
            "slug_url": "coi-so-diep-tuoi-20-40-1kg-109921",
            "keywords": "best sellers",
            "parent_code": null,
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "FROZEN SCALLOP",
            "name_local": "COI SO DIEP TUOI (20/40) 1KG",
            "short_desc_en": "FROZEN SCALLOP",
            "short_desc_local": "COI SO DIEP TUOI (20",
            "full_desc_en": "FROZEN SCALLOP",
            "full_desc_local": "COI SO DIEP TUOI (20/40) 1KG",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5608,
                    "product_code": "109921",
                    "attribute_code": "1",
                    "attribute_value": "hot promotion",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.736Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1520",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "Fish",
                    "name_local": "Cá",
                    "image": null,
                    "active": true,
                    "start_date": "2024-02-29T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": "foxcms",
                    "updated_by": null,
                    "created_at": "2024-03-12T10:18:17.634Z",
                    "updated_at": "2024-03-12T10:18:17.635Z",
                    "ProductCategory": {
                        "product_code": "109921",
                        "channel_code": "1",
                        "category_code": "1520",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 343207,
                    "product_code": "109921",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 561905,
                    "normal_price_in_vat": 590000,
                    "promotion_price_ex_vat": 561905,
                    "promotion_price_in_vat": 590000,
                    "promotion_start": "2019-08-21T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291448,
                    "product_code": "109921",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/109921.jpg",
                    "title": "COI SO DIEP TUOI (20",
                    "alternate": "COI SO DIEP TUOI (20/40) 1KG",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "113973",
        "channel_code": "1",
        "category_code": "1530",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "113973",
            "sku": "21139734",
            "slug_url": "nho-red-globe-uc-x-xu-uc-113973",
            "keywords": "best sellers",
            "parent_code": "253116",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "RED GLOBE GRAPE ( AUS )",
            "name_local": "NHO RED GLOBE UC (X.XU UC)",
            "short_desc_en": "RED GLOBE GRAPE ( AU",
            "short_desc_local": "NHO RED GLOBE UC (X.",
            "full_desc_en": "RED GLOBE GRAPE ( AUS )",
            "full_desc_local": "NHO RED GLOBE UC (X.XU UC)",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5630,
                    "product_code": "113973",
                    "attribute_code": "2",
                    "attribute_value": "best selling",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.737Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1530",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "frozen",
                    "name_local": "Đông lạnh",
                    "image": null,
                    "active": true,
                    "start_date": "2024-06-22T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": null,
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null,
                    "ProductCategory": {
                        "product_code": "113973",
                        "channel_code": "1",
                        "category_code": "1530",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 328998,
                    "product_code": "113973",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 151429,
                    "normal_price_in_vat": 159000,
                    "promotion_price_ex_vat": 151429,
                    "promotion_price_in_vat": 159000,
                    "promotion_start": "2021-06-08T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291091,
                    "product_code": "113973",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/113973.jpg",
                    "title": "NHO RED GLOBE UC (X.",
                    "alternate": "NHO RED GLOBE UC (X.XU UC)",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "114143",
        "channel_code": "1",
        "category_code": "1530",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "114143",
            "sku": "21141430",
            "slug_url": "thom-son-dua-do-ngu-qua-600g-114143",
            "keywords": "best sellers",
            "parent_code": "346699",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "DRAGON - PHOENIX PINEAPPLE",
            "name_local": "THOM SON (DUA DO) NGU QUA 600G",
            "short_desc_en": "DRAGON - PHOENIX PIN",
            "short_desc_local": "THOM SON (DUA DO) NG",
            "full_desc_en": "DRAGON - PHOENIX PINEAPPLE",
            "full_desc_local": "THOM SON (DUA DO) NGU QUA 600G",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5631,
                    "product_code": "114143",
                    "attribute_code": "2",
                    "attribute_value": "best selling",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.737Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1530",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "frozen",
                    "name_local": "Đông lạnh",
                    "image": null,
                    "active": true,
                    "start_date": "2024-06-22T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": null,
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null,
                    "ProductCategory": {
                        "product_code": "114143",
                        "channel_code": "1",
                        "category_code": "1530",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 394334,
                    "product_code": "114143",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 33238,
                    "normal_price_in_vat": 34900,
                    "promotion_price_ex_vat": 33238,
                    "promotion_price_in_vat": 34900,
                    "promotion_start": "2018-02-06T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 290879,
                    "product_code": "114143",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/114143.jpg",
                    "title": "THOM SON (DUA DO) NG",
                    "alternate": "THOM SON (DUA DO) NGU QUA 600G",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "116599",
        "channel_code": "1",
        "category_code": "1530",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "116599",
            "sku": "8938505055107",
            "slug_url": "ot-chuong-baby-w-a-f-500gr-116599",
            "keywords": "special offers",
            "parent_code": "336550",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "BABY CAPSICUM W.A.F-500GR",
            "name_local": "OT CHUONG BABY W.A.F-500GR",
            "short_desc_en": "BABY CAPSICUM W.A.F-",
            "short_desc_local": "OT CHUONG BABY W.A.F",
            "full_desc_en": "BABY CAPSICUM W.A.F-500GR",
            "full_desc_local": "OT CHUONG BABY W.A.F-500GR",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5657,
                    "product_code": "116599",
                    "attribute_code": "1",
                    "attribute_value": "hot promotion",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.737Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1530",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "frozen",
                    "name_local": "Đông lạnh",
                    "image": null,
                    "active": true,
                    "start_date": "2024-06-22T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": null,
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null,
                    "ProductCategory": {
                        "product_code": "116599",
                        "channel_code": "1",
                        "category_code": "1530",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 153476,
                    "product_code": "116599",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 50476,
                    "normal_price_in_vat": 53000,
                    "promotion_price_ex_vat": 40476,
                    "promotion_price_in_vat": 43000,
                    "promotion_start": "2021-07-12T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291391,
                    "product_code": "116599",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/116599.jpg",
                    "title": "OT CHUONG BABY W.A.F",
                    "alternate": "OT CHUONG BABY W.A.F-500GR",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "119232",
        "channel_code": "1",
        "category_code": "1530",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "119232",
            "sku": "21192326",
            "slug_url": "kiwi-vang-xx-newzealand-119232",
            "keywords": "special offers",
            "parent_code": "333870",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "KIWI GOLDEN-ZESPRI (XX NZ)",
            "name_local": "KIWI VANG (XX NEWZEALAND)",
            "short_desc_en": "KIWI GOLDEN-ZESPRI (",
            "short_desc_local": "KIWI VANG (XX NEWZEA",
            "full_desc_en": "KIWI GOLDEN-ZESPRI (XX NZ)",
            "full_desc_local": "KIWI VANG (XX NEWZEALAND)",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5685,
                    "product_code": "119232",
                    "attribute_code": "5",
                    "attribute_value": "shocking price",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.738Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1530",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "frozen",
                    "name_local": "Đông lạnh",
                    "image": null,
                    "active": true,
                    "start_date": "2024-06-22T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": null,
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null,
                    "ProductCategory": {
                        "product_code": "119232",
                        "channel_code": "1",
                        "category_code": "1530",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 299088,
                    "product_code": "119232",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 150000,
                    "normal_price_in_vat": 157500,
                    "promotion_price_ex_vat": 100000,
                    "promotion_price_in_vat": 110000,
                    "promotion_start": "2022-10-25T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291335,
                    "product_code": "119232",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/119232.jpg",
                    "title": "KIWI VANG (XX NEWZEA",
                    "alternate": "KIWI VANG (XX NEWZEALAND)",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "119678",
        "channel_code": "1",
        "category_code": "1530",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "119678",
            "sku": "21196782",
            "slug_url": "cam-navel-uc-xx-uc-119678",
            "keywords": "special offers",
            "parent_code": "205876",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "NAVEL ORANGE - AUS",
            "name_local": "CAM NAVEL UC (XX UC)",
            "short_desc_en": "NAVEL ORANGE - AUS",
            "short_desc_local": "CAM NAVEL UC (XX UC)",
            "full_desc_en": "NAVEL ORANGE - AUS",
            "full_desc_local": "CAM NAVEL UC (XX UC)",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5689,
                    "product_code": "119678",
                    "attribute_code": "2",
                    "attribute_value": "best selling",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.739Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1530",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "frozen",
                    "name_local": "Đông lạnh",
                    "image": null,
                    "active": true,
                    "start_date": "2024-06-22T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": null,
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null,
                    "ProductCategory": {
                        "product_code": "119678",
                        "channel_code": "1",
                        "category_code": "1530",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 236520,
                    "product_code": "119678",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 56190,
                    "normal_price_in_vat": 59000,
                    "promotion_price_ex_vat": 50000,
                    "promotion_price_in_vat": 51000,
                    "promotion_start": "2020-11-30T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291764,
                    "product_code": "119678",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/119678.jpg",
                    "title": "CAM NAVEL UC (XX UC)",
                    "alternate": "CAM NAVEL UC (XX UC)",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "120923",
        "channel_code": "1",
        "category_code": "1530",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "120923",
            "sku": "21209239",
            "slug_url": "dau-phung-vo-tuoi-tui-luoi-120923",
            "keywords": "special offers",
            "parent_code": "336418",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "FRESH PEANUT",
            "name_local": "DAU PHUNG VO TUOI (TUI LUOI)",
            "short_desc_en": "FRESH PEANUT",
            "short_desc_local": "DAU PHUNG VO TUOI (T",
            "full_desc_en": "FRESH PEANUT",
            "full_desc_local": "DAU PHUNG VO TUOI (TUI LUOI)",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5702,
                    "product_code": "120923",
                    "attribute_code": "4",
                    "attribute_value": "hot deal",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.739Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1530",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "frozen",
                    "name_local": "Đông lạnh",
                    "image": null,
                    "active": true,
                    "start_date": "2024-06-22T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": null,
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null,
                    "ProductCategory": {
                        "product_code": "120923",
                        "channel_code": "1",
                        "category_code": "1530",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 249748,
                    "product_code": "120923",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 65714,
                    "normal_price_in_vat": 69000,
                    "promotion_price_ex_vat": 60000,
                    "promotion_price_in_vat": 61000,
                    "promotion_start": "2021-07-13T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291176,
                    "product_code": "120923",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/120923.jpg",
                    "title": "DAU PHUNG VO TUOI (T",
                    "alternate": "DAU PHUNG VO TUOI (TUI LUOI)",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "121723",
        "channel_code": "1",
        "category_code": "1520",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "121723",
            "sku": "21217234",
            "slug_url": "tom-the-tuoi-100-120-con-kg-121723",
            "keywords": "special offers",
            "parent_code": "348732",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "FRESH WHITE SHRIMP",
            "name_local": "TOM THE (TUOI) 100-120 CON/KG",
            "short_desc_en": "FRESH WHITE SHRIMP",
            "short_desc_local": "TOM THE (TUOI) 100-1",
            "full_desc_en": "FRESH WHITE SHRIMP",
            "full_desc_local": "TOM THE (TUOI) 100-120 CON/KG",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5711,
                    "product_code": "121723",
                    "attribute_code": "4",
                    "attribute_value": "hot deal",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.739Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1520",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "Fish",
                    "name_local": "Cá",
                    "image": null,
                    "active": true,
                    "start_date": "2024-02-29T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": "foxcms",
                    "updated_by": null,
                    "created_at": "2024-03-12T10:18:17.634Z",
                    "updated_at": "2024-03-12T10:18:17.635Z",
                    "ProductCategory": {
                        "product_code": "121723",
                        "channel_code": "1",
                        "category_code": "1520",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 104971,
                    "product_code": "121723",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 146286,
                    "normal_price_in_vat": 153600,
                    "promotion_price_ex_vat": 100000,
                    "promotion_price_in_vat": 110000,
                    "promotion_start": "2021-05-31T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291813,
                    "product_code": "121723",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/121723.jpg",
                    "title": "TOM THE (TUOI) 100-1",
                    "alternate": "TOM THE (TUOI) 100-120 CON/KG",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    },
    {
        "product_code": "123616",
        "channel_code": "1",
        "category_code": "1530",
        "active": true,
        "created_by": "foxcms",
        "updated_by": null,
        "created_at": null,
        "updated_at": null,
        "product_details": {
            "product_code": "123616",
            "sku": "8936041043282",
            "slug_url": "nam-linh-chi-tuoi-trang-150g-123616",
            "keywords": "product of the year",
            "parent_code": "336026",
            "active": true,
            "deleted": false,
            "published": false,
            "is_parent": false,
            "model": null,
            "name_en": "FRESH WHITE CARB MUSHROOM-150G",
            "name_local": "NAM LINH CHI TUOI(TRANG) 150G",
            "short_desc_en": "FRESH WHITE CARB MUS",
            "short_desc_local": "NAM LINH CHI TUOI(TR",
            "full_desc_en": "FRESH WHITE CARB MUSHROOM-150G",
            "full_desc_local": "NAM LINH CHI TUOI(TRANG) 150G",
            "unit_category_code": 187,
            "unit_code": 1,
            "created_by": "API",
            "updated_by": "AUTO_SYNC",
            "created_at": null,
            "updated_at": "2024-03-11T04:44:44.000Z",
            "ProductAttributes": [
                {
                    "id": 5721,
                    "product_code": "123616",
                    "attribute_code": "4",
                    "attribute_value": "hot deal",
                    "created_by": "foxcms",
                    "updated_by": "FOXCMS",
                    "start_date": "2024-06-23T03:42:19.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_at": "2024-06-23T03:42:19.254Z",
                    "updated_at": "2024-06-23T03:45:13.740Z"
                }
            ],
            "Categories": [
                {
                    "category_code": "1530",
                    "channel_code": "1",
                    "seq": 2,
                    "parent_code": "15",
                    "name_en": "frozen",
                    "name_local": "Đông lạnh",
                    "image": null,
                    "active": true,
                    "start_date": "2024-06-22T17:00:00.000Z",
                    "end_date": "2049-12-30T17:00:00.000Z",
                    "created_by": null,
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null,
                    "ProductCategory": {
                        "product_code": "123616",
                        "channel_code": "1",
                        "category_code": "1530",
                        "active": true,
                        "created_by": "foxcms",
                        "updated_by": null,
                        "created_at": null,
                        "updated_at": null
                    }
                }
            ],
            "ProductPrices": [
                {
                    "id": 418840,
                    "product_code": "123616",
                    "channel_code": "GP",
                    "store_code": "10010",
                    "normal_price_ex_vat": 29524,
                    "normal_price_in_vat": 31000,
                    "promotion_price_ex_vat": 29524,
                    "promotion_price_in_vat": 31000,
                    "promotion_start": "2020-02-13T17:00:00.000Z",
                    "promotion_end": "2049-12-30T17:00:00.000Z",
                    "exclusive_price_ex_vat": null,
                    "exclusive_price_in_vat": null,
                    "exclusive_start": null,
                    "exclusive_end": null,
                    "flash_price_ex_vat": null,
                    "flash_price_in_vat": null,
                    "flash_start": null,
                    "flash_end": null,
                    "created_by": "AUTO_SYNC",
                    "updated_by": "AUTO_SYNC",
                    "created_at": "2024-03-21T10:43:04.000Z",
                    "updated_at": null
                }
            ],
            "ProductImages": [
                {
                    "id": 291619,
                    "product_code": "123616",
                    "seq": 1,
                    "image": "https://api-test.mmvietnam.com:5000/fox/assets/products/123616.jpg",
                    "title": "NAM LINH CHI TUOI(TR",
                    "alternate": "NAM LINH CHI TUOI(TRANG) 150G",
                    "created_by": "SEEK",
                    "updated_by": null,
                    "created_at": null,
                    "updated_at": null
                }
            ]
        }
    }
]

export const priceList = [
    {
        _id: 0,
        priceOne: 0,
        priceTwo: 0,
        label_text_vn: "Tất cả",
        label_text_en: "All"
    },
    {
        _id: 950,
        priceOne: 0.0,
        priceTwo: 99.99,
        label_text_vn: "Dưới 100k VND",
        label_text_en: "Under 100k VND"
    },
    {
        _id: 952,
        priceOne: 100.0,
        priceTwo: 299.99,
        label_text_vn: "100k - 300k VND",
        label_text_en: "100k - 300k VND"
    },
    {
        _id: 953,
        priceOne: 299.0,
        priceTwo: 599.99,
        label_text_vn: "300k - 600k VND",
        label_text_en: "300k - 600k VND"
    },
    {
        _id: 955,
        priceOne: 600.0,
        priceTwo: 1000.0,
        label_text_vn: "600k - 1 triệu VND",
        label_text_en: "600k - 1 mil VND"
    },
    {
        _id: 956,
        priceOne: 1000.0,
        priceTwo: -1,
        label_text_vn: "Trên 1 triệu VND",
        label_text_en: "Greater than 1 mil VND"
    },
];