import React, { useState, useEffect } from "react";
import WishListProduct from "../../components/WishList/WishListProduct";
import useSEO from "../../hooks/useSEO";
import BackendApi from "../../common.js/backendApi";
import { fetchData } from "../../common.js/http";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FoxProduct_Home } from "../../common.js/transformation";
import { CONST_DATA } from "../../common.js/constData";
const WishList = () => {
  const navigate = useNavigate();
  const [slides, setCate] = useState(null);
  const En = useSelector((state) => state.FoxReducer.En);
  const store_code = useSelector((state) => state.FoxReducer.useSelectedStore);
  const wishlist = useSelector((state) => state.FoxReducer.wishlist) || [];
  const [plist, setListProducts] = useState(null);

  const [productCodeList, setproductCodeList] = useState(null);
  //const productCodeList = wishlist.map(item => item.product_code).join(',');

  useEffect(() => {
    if (wishlist != null && wishlist.length > 0 &&   wishlist[0] != null) {
      setproductCodeList( wishlist.map(item => item.product_code).join(','));
    }
    else
    {
      setproductCodeList(null);
      setListProducts(null); // Clear product list when wishlist is empty
      setCate(null); // Clear categories when wishlist is empty
    }
  }, [wishlist]);
  

  const fetchProducts = async () => {
    if (productCodeList != null && productCodeList != "") {
      try {
        let props = {
          url: `${BackendApi.allProductsAndCount.url}?page=${1}&size=${100}&active=true&published=true&store_id=${CONST_DATA.STORE_DEFAULT}&scopes=["ProductOrigin","ProductCategory","ProductAttribute","ProductPrice","ProductImage"]&product_list=${productCodeList}`,
          method: BackendApi.allProductsAndCount.method,
        };
        const data1 = await fetchData(props);
        if (data1.success) {
          var plist_cusom = [];
          data1.results.forEach((item) => {
            let item_p = FoxProduct_Home(item, En);
            plist_cusom.push(item_p);
          });
          setListProducts(plist_cusom);
        } else {
        }
      } catch (err) {
      } 
    }
  };

  useSEO({ title: "Home", description: "Fox Commerce HomePage", keywords: ["ecomnerce", "Fox", "shopping"] })

  useEffect(() => {
    fetchProducts();
  }, [En, store_code,productCodeList]);

  useEffect(() => {
    if (plist != null && plist.length > 0) {
      const list_cate = plist.reduce((acc, item) => {
        const exists = acc.find(
          (cate) => cate.cat === item.cat && cate.cat_name === item.cat_name
        );
        if (!exists) {
          acc.push({ cat: item.cat, cat_name: item.cat_name });
        }
        return acc;
      }, []);
      setCate(list_cate);
    }
    else
      setCate(null);
  }, [En, plist]);
  const go_home = () => {
    navigate(`/`);
  };
  return (
    <div className="w-full mx-auto pt-6 md:pt-10">

      <div className="max-w-container mx-auto px-4">
        <div className="xl:-mt-10 -mt-7">
          {/* <Breadcrumbs title="" prevLocation={prevLocation} /> */}
          <div className="w-full py-10 xl:py-10 flex flex-col gap-3" style={{ paddingTop: "50px" }}>
            <p className="text-sm font-normal text-lightText capitalize flex items-center">
              <span className="capitalize font-semibold text-primeColor" onClick={() => go_home()} style={{ cursor: "pointer" }}>
                {En ? "Home" : "Trang chủ"}
              </span>
              <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: "5px", marginRight: "5px" }}><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
              <span className="capitalize font-semibold text-primeColor" style={{ cursor: "pointer" }}>
                {En ? "WishList" : "Danh mục yêu thích"}
              </span>
            </p>
          </div>
        </div>
        {slides && slides.length > 0 ? (
          slides.map((data) => {
            return (
              <WishListProduct key={data.cat} cate_code={data.cat} cate_name={data.cat_name} wishlist={plist} />
            );
          })
        ) : (
          <div>{En ? "No products available." : "Không có sản phẩm nào khả dụng."}</div>
        )}
      </div>
    </div>
  );
};

export default WishList;
