
const PDFViewer = ({ pdfUrl }) => {
  //let pdfUrl = 'https://online-test.bsmartvina.com/huong-dan-mua-hang-vi.pdf'
  return (
    <div className="w-full h-screen overflow-hidden">
      <iframe
        src={`https://docs.google.com/gview?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
        className="w-full h-screen"
        style={{ border: 'none', overflow: 'hidden' }}
        title="PDF Viewer"
      />
    </div>
  );
};

export default PDFViewer;
