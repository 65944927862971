import React, { useEffect, useRef, useState } from "react";

const Image = ({ imgSrc, className, width, height }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return (
    <>
      {isVisible ? (
        <img
          ref={imgRef}
          className={className}
          src={imgSrc}
          alt=""
          width={width}
          height={height}
          style={{
            maxWidth: width,
            maxHeight: height,
            objectFit: "cover",
            border: "0",
          }}
        />
      ) : (
        <div
          ref={imgRef}
          style={{
            width: width,
            height: height,
            backgroundColor: "#f3f4f6", // Placeholder color
            border: "0",
          }}
        />
      )}
    </>
  );
};

export default Image;
