
import React from 'react';
import _ from 'lodash';
import {  useSelector } from "react-redux";

const CurrencyComponent = (props) => {
  
    const formatToVND = (number) => {
        return number.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })
        //number? number.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' }):"Error";
      };

  if(props===undefined || props.price===undefined){
    
    return<></>
  }
  if(props.price===null){
    
    return<></>
  }
  // Format price to VND format string
  const formattedPrice = formatToVND(props.price);

  return (
    
      <> {formattedPrice}</>
    
  );
};

export default CurrencyComponent;

