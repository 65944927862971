// =================== NavBarList Start here ====================
export const navBarList = [
  {
    id: 1001,
    title: "Trang chủ",
    title_en: "Home",
    link: "/",
  },
  // {
  //   id: 1002,
  //   title: "Mua sắm",
  //   title_en: "Shop",
  //   link: "/shop",
  // },
  {
    id: 1003,
    title: "Về chúng tôi",
    title_en: "About",
    link: "/about",
  },
  {
    id: 1004,
    title: "Liên hệ",
    title_en: "Contact",
    link: "contact",
  },
  // {
  //   id: 1005,
  //   title: "Journal",
  //   link: "/journal",
  // },
];

// =================== categoryList Start here ====================
export const categoryList = [
  {
    category_code: "1001",
    name: "Fresh",
    link: "/Fresh",
  },
  {
    category_code: "1002",
    name: "Fish",
    link: "/Fish",
  },  
]

// =================== brandList Start here ====================
export const brandList = [
  {
    id: 1,
    name: "YOMOST",
  },
  {
    id: 2,
    name: "0DO",
  },  
]

// =================== productList Start here ==================
export const productList = [
  {
    product_code: 1,
    sku: 1,
    name:'Product Name 1',
    slug: 'product-name-1',
    quantity: 1,
    image: 'https://res.cloudinary.com/daoia7xg5/image/upload/v1718877879/cvmx2vzwsloebcem9use.jpg',
    badge: false,
    price: 100,
    retail_price: 100,
    color: 'mixed',
    brand: 'YOMOST',
    cat: '1001',
    subcat: '100101',
    des: 'long product description here',
  },
  {
    product_code: 2,
    sku: 1,
    name:'Product Name 2',
    slug: 'product-name-2',
    quantity: 1,
    image: 'https://res.cloudinary.com/daoia7xg5/image/upload/v1718877845/p4nmsxwc9hi3rnhtyveb.jpg',
    badge: false,
    price: 100,
    retail_price: 100,
    color: 'mixed',
    brand: 'YOMOST',
    cat: '1001',
    subcat: '100101',
    des: 'long product description here',
  },
  {
    product_code: 3,
    sku: 1,
    name:'Product Name 3',
    slug: 'product-name-3',
    quantity: 1,
    image: 'https://res.cloudinary.com/daoia7xg5/image/upload/v1718702319/fa9zohpbsa359mc9lbqp.jpg',
    badge: false,
    price: 100,
    retail_price: 100,
    color: 'mixed',
    brand: 'YOMOST',
    cat: '1001',
    subcat: '100101',
    des: 'long product description here',
  },  
  {
    product_code: 4,
    sku: 1,
    name:'Product Name 4',
    slug: 'product-name-4',
    quantity: 1,
    image: 'https://res.cloudinary.com/daoia7xg5/image/upload/v1718702293/hy5fv4pdsjcgyxbmfxmo.jpg',
    badge: false,
    price: 100,
    retail_price: 100,
    color: 'mixed',
    brand: 'YOMOST',
    cat: '1001',
    subcat: '100101',
    des: 'long product description here',
  },    
]