import BackendApi from "../common.js/backendApi";

import { fetchData } from "../common.js/http";
import { CONST_DATA } from "../common.js/constData";

async function addWishList(data) {
    let props = {
        url: BackendApi.AddToWishListByCust.url,
        method: BackendApi.AddToWishListByCust.method,
        body: data,
    };
    return await fetchData(props);
}

async function removeWishList(product_code) {
    let props_api = {
        url: `${BackendApi.RemoveFromWishListByCust.url}`,
        method: BackendApi.RemoveFromWishListByCust.method,
        body: {
            product_code: product_code,
            channel_code: CONST_DATA.CHANNEL
        }
    };
    return await fetchData(props_api);
}

async function updateWishList(data) {
    try{
        let props = {
            url: BackendApi.UpdateWishListToCust.url,
            method: BackendApi.UpdateWishListToCust.method,
            body: data,
        };
        return await fetchData(props);
    }
    catch(error){
        console.log(error)
    }
    
}
async function getWishList(page, size) {
    let props = {
        url: BackendApi.GetWishListByCust.url+"?page=" +page + "&size=" + size,
        method: BackendApi.GetWishListByCust.method
    };
    return await fetchData(props);
}

export { addWishList, removeWishList, updateWishList, getWishList };