import { fetchData, fetchDataExcludeStore } from "../common.js/http";
import { CONST_DATA } from "../common.js/constData";
import BackendApi from "../common.js/backendApi";
import httpFactory from "../common.js/httpFactoty";
import { RetrieveProduct } from "./SearchServices";
import { method } from "lodash";
//const baseUrl = "https://172.26.16.188:3030";
const baseUrl = CONST_DATA.FOX;
async function CreateCart(customer_no, store_id) {
  let customer = {
    customer_no: customer_no,
    channel_code: CONST_DATA.CHANNEL,
    store_id: store_id,
  };
  let url = `${BackendApi.CreateCart.url}`;
  let props = {
    url: url,
    method: BackendApi.CreateCart.method,

    body: customer,
  };
  return await fetchData(props);
}
async function GetCurrentCart(customer_no, store_id) {
  //let url = `${baseUrl}/api/v1/Carts?channel=1&store_id=${store_id}&customer_no=${customer_no}&scopes=["ProductOrigin","ProductCategory","ProductPrice","ProductImages"]`;
  let url = `${BackendApi.GetCurrentCart.url}?customer_no=${customer_no}&scopes=["ProductOrigin","ProductCategory","ProductPrice","ProductImages"]`;
  let props = {
    url: url,
    method: BackendApi.GetCurrentCart.method,
  };
  const fact = new httpFactory(props);
  //let response  =await fact.addStore().addChannel().fetchData();
  return await fact.addStore().addChannel().fetchData();
  //return await fetchDataExcludeStore(props);
}
async function GetQuantityItemInCart(customer_no, store_id, product_code) {
  let url = `${BackendApi.GetQuantityItemInCart.url}?store_id=${store_id}&customer_no=${customer_no}&scopes=["ProductOrigin","ProductCategory","ProductPrice"]`;
  let props = {
    url: url,
    method: BackendApi.GetQuantityItemInCart.method,
  };

  const response = await fetchData(props);

  if (response.results.length > 0) {
    let items = response.results[0].CartItems;
    let exists = items.find((item) => item.product_code === product_code);

    if (exists === undefined) {
      return 0;
    } else return exists.quantity ? exists.quantity : 0;
  }
  return 0;
}
async function AddToCart(cart) {
  // let url = `${BackendApi.AddToCart.url}/${cart.cart_id}`;
  // console.log(BackendApi.AddToCart);
  // let props = {
  //   url: url,
  //   method: BackendApi.AddToCart.method,
  //   //body:data
  //   body: cart,
  // };
  // return await fetchData(props);
  return await  AddOrUpdateCart(cart.customer_no, cart.product_code, CONST_DATA.STORE_DEFAULT);
}
async function UpdateCardItem(cart) {
  let url = `${BackendApi.UpdateCardItem.url}`;
  let props = {
    url: url,
    method: BackendApi.UpdateCardItem.method,
    //body:data
    body: cart,
  };
  return await fetchData(props);
}
async function ChangeCartOwner(customer_no) {
  let url = `${BackendApi.ChangeCartOwner.url}`;
  let props = {
    url: url,
    method: BackendApi.ChangeCartOwner.method,

    body: {
      customer_no: customer_no,
    },
  };
  return await fetchData(props);
}
async function GetNewOwnerCart(customer_no, store_id) {
  try{
    let url = `${BackendApi.GetNewOwnerCart.url}?channel=1&store_id=${store_id}&customer_no=${customer_no}&scopes=["ProductOrigin","ProductCategory","ProductPrice","ProductImages"]`;
  let props = {
    url: url,
    method: BackendApi.GetNewOwnerCart.method,
  };

  return  await fetchData(props);
 
  }
  catch(error)
  {
    console.error(error);
  }

  //return data;
}

async function GetCartByCust(customer_no, token) {
  let url = `${BackendApi.GetCartByCust.url}?channel=${CONST_DATA.CHANNEL}&customer_no=${customer_no}&scopes=["ProductOrigin","ProductCategory","ProductPrice","ProductImages"]`;

  let props = {
    url: url,
    method: BackendApi.GetCartByCust.method,
  };

  const response = await fetchData(props);

  return response;
}
async function RemoveCartItem(customer_no, product_code) {
  let url = `${BackendApi.RemoveCartItem.url}`;
  
  let body = {
    customer_no: customer_no,
    channel_code: CONST_DATA.CHANNEL.toString(),
    product_code: product_code,
  };
  let props = {
    url: url,
    method: BackendApi.RemoveCartItem.method,
    body: body,
  };
  return await fetchData(props);
}
async function RemoveCartItemsAnyway(customer_no, product_codes) {
  try {
    for (let i = 0; i < product_codes.length; i++) {
      await RemoveCartItem(customer_no, product_codes[i]);
    }
  } catch (error) {
    console.log(error);
  }
}
async function MergeCartDummnyToIdentityCustomer(params) {
  try{
    let cart = await CreateCartAnyway(params.customer_no, params.token);
 
    if (cart != null) {
      let cart_id = cart.id;
      for (let i = 0; i < params.products.length; i++) {
        let item = { cart_id, ...params.products[i] };
        await AddToCartAnyway(cart.id, item, params.token);
      }
    }
  }
  catch(error){
    console.error(error)
  }
  
}
async function CreateCartAnyway(customer_no, token) {
  try {
    let customer = {
      customer_no: customer_no,
      channel_code: CONST_DATA.CHANNEL,
      store_id: CONST_DATA.STORE_DEFAULT,
    };
    let props = {
      url: BackendApi.CreateCart.url,
      method: BackendApi.CreateCart.url.method,
    };

    const response = fetchData(props);

    if (response.success) {
      return response.results[0];
    }
  } catch (error) {}
  try {
    let getcart = await GetCartByCust(customer_no, token);
    if (getcart.success) {
      return getcart.results[0];
    }
  } catch (error) {}

  return null;
}
async function AddToCartAnyway(cart_id, cart, token) {
  let url = `${BackendApi.AddToCart.url}/${cart_id}`;

  let props = {
    url: url,
    method: BackendApi.AddToCart.method,
    body: cart,
  };

  await fetchData(props);
}
async function AddOrUpdateCart(customer_no, product_code, store_id) {
  let product = await RetrieveProduct(product_code);
  if (product === null) {
    throw new Error("This product is not available");
  } else {
    let price =
      product.ProductPrices[0].promotion_price_in_vat ??
      product.ProductPrices[0].normal_price_in_vat;
    let vat_amount = (price * product.ProductPrices[0].vat_rate) / 100;
    let cartItem = {
      product_code: product_code,
      customer_no: customer_no,
      store_id: store_id,
      channel_code: CONST_DATA.CHANNEL.toString(),
      quantity: 1,
      price: price,
      discount: 0,
      discount_info: null,
      vat_rate: product.ProductPrices[0].vat_rate,
      vat_code: product.ProductPrices[0].vat_code,
      vat_amount: vat_amount,
      total: price,
    };
    let params = {
      body: cartItem,
    };

    return await AddToCartByCust(params);
  }
}
async function AddToCartByCust(params) {
  let props = {
    url: BackendApi.AddToCartByCust.url,
    method: BackendApi.AddToCartByCust.method,
    body: params.body,
  };
  let fact = new httpFactory(props);
  return await fact.fetchData();
}

export {
  CreateCart,
  //WaitingTest,
  GetCurrentCart,
  AddToCart,
  GetQuantityItemInCart,
  UpdateCardItem,
  ChangeCartOwner,
  GetNewOwnerCart,
  RemoveCartItem,
  MergeCartDummnyToIdentityCustomer,
  AddToCartByCust,
  AddOrUpdateCart,
  RemoveCartItemsAnyway
};
