import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';

//import { useAuth } from './Auth';
export const AuthorizedPage = ({ children }) => {
    //const { isAuthenticated } = useAuth();
    let authenticated =false;
    const userInfo = useSelector((state) => state.FoxReducer.userInfo);
    
    if(userInfo!==null){
        authenticated=true;
    }
  
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (!authenticated) {
            navigate('/signin', { state: { returnUrl: location.pathname } });
        }
    }, [authenticated, navigate, location]);

    if (authenticated) {
        return <>{children}</>;
    } else {
        return <>Redirecting to login...</>;
    }
  };
