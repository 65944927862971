import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import BackendApi from "../../../common.js/backendApi";
import { fetchData } from "../../../common.js/http";
import { CONST_DATA } from "../../../common.js/constData";

const YearProduct = () => {
  const En = useSelector((state) => state.FoxReducer.En);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let props = {
          url: `${BackendApi.allProducts.url}?page=1&size=1&active=true&published=true&store_id=${CONST_DATA.STORE_DEFAULT}&attribute_code=7&scopes=["ProductOrigin","ProductCategory","ProductAttribute","ProductPrice","ProductImage"]`,
          method: BackendApi.allProducts.method,
        };

        const data1 = await fetchData(props);

        if (data1.success) {
          setData(data1.results);
        } else setData([]);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
  }, [En]);

  return (
    <>
      {!loading && data.length > 0 && (
        <Link to="/producttype/7">
          <div
            className="bg-cover bg-center h-auto py-24 px-10 object-fill"
            style={{ backgroundImage: `url(${window.location.origin}/bg_poy.png)` }}
          >
            <div className="md:w-1/2">
              <p className="font-bold text-sm uppercase">
                {En ? "Product of The Year" : "Sản phẩm của năm"}
              </p>
              <p className="text-3xl font-bold">
                {En ? data[0].short_desc_en : data[0].full_desc_local}
              </p>
              <p className="text-2xl mb-10 leading-none">
                {En ? data[0].full_desc_en : data[0].full_desc_local}
              </p>
              <a
                href="#"
                className="bg-primeColor py-4 px-8 text-white font-bold uppercase text-xs rounded hover:bg-primeColorDark hover:text-white"
              >
                Xem chi tiết
              </a>
            </div>
          </div>
        </Link>
      )}
    </>
  );
};

export default YearProduct;
