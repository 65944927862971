import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import ProductV2 from "../Products/ProductV2";
import { useSelector } from "react-redux";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";
import { FoxProduct_Home } from "../../../common.js/transformation";
import BackendApi from "../../../common.js/backendApi";
import { fetchData } from "../../../common.js/http";
import { CONST_DATA } from "../../../common.js/constData";

const NewArrivals = () => {
  const En = useSelector((state) => state.FoxReducer.En);
  const store_code = useSelector((state) => state.FoxReducer.useSelectedStore);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: data.length < 6 ? data.length :6 ,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: data.length < 4 ? data.length : 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: data.length < 2 ? data.length : 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: data.length < 1 ? data.length : 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let props = {
          url: `${BackendApi.allProducts.url}?page=1&size=12&active=true&published=true&store_id=${CONST_DATA.STORE_DEFAULT}&attribute_code=6&scopes=["ProductOrigin","ProductCategory","ProductAttribute","ProductPrice","ProductImage"]`,
          method: BackendApi.allProducts.method,
        };

        const data1 = await fetchData(props);

        if (data1.success) {
          setData(data1.results);
        } else setData([]);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
  }, [store_code, En]);

  return (
    <>
      {!loading && data && data.length > 0 && (    
        <div className="w-full pb-6 pt-6">
          <Heading heading={En ? "New Arrivals" : "Sản phẩm mới"} />
          <Slider {...settings}>
            {data.map((itemData) => {
              let item = FoxProduct_Home(itemData, En);
              return (
                <div className="px-2" key={item.product_code}>
                  <ProductV2
                    key={item.product_code}
                    product_code={item.product_code}
                    slug={item.slug}
                    sku={item.sku}
                    image={item.image}
                    name={item.name}
                    price={item.price}
                    retail_price={item.retail_price}
                    price_ex_vat={item.price_ex_vat}
                    retail_price_ex_vat={item.retail_price_ex_vat}
                    vat_code={item.vat_code}
                    vat_rate={item.vat_rate}
                    color={item.color}
                    badge={item.badge}
                    des={item.des}
                    cat={item.cat}
                    cat_name={item.cat_name}
                    unit={item.unit}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
      )}
    </>    
  );
};

export default NewArrivals;
