// Check if storage is available
const isStorageAvailable = () => {
    try {
      const testKey = '__test__';
      localStorage.setItem(testKey, 'test');
      localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  };
  export default isStorageAvailable;  