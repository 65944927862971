import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";
import { FaDownload } from "react-icons/fa";
import RelatedProducts from "../../components/pageProps/productDetails/RelatedProducts";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import BackendApi from "../../common.js/backendApi";
import { fetchData, fetchDataV2 } from "../../common.js/http";
import Rating from "../../components/pageProps/productDetails/Rating";
import RatingDialog from "../../components/pageProps/productDetails/RatingDialog";
import { toast } from "react-toastify";
import { FoxProduct_Home } from "../../common.js/transformation";
import { CONST_DATA }  from "../../common.js/constData";
import { Helmet } from 'react-helmet';

const tabs = [
  // {
  //   id: "Fiche Technique",
  //   label: "Fiche Technique",
  // },
  {
    id: "Description",
    label: "Description",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  // {
  //   id: "Video",
  //   label: "Video",
  //   content: (
  //     <iframe
  //       width="560"
  //       height="315"
  //       src="https://www.youtube.com/embed/AltnFUpobRI"
  //       title="YouTube Video"
  //       allowFullScreen
  //     ></iframe>
  //   ),
  // },
  // Add more tabs as needed
];

const ProductDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  const [productInfo, setProductInfo] = useState([]);
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const En = useSelector((state) => state.FoxReducer.En);
  const [data, setData] = useState([]);
  const [dataRating, setDataRating] = useState([]);
  const [dialogReset, setDialogReset] = useState(false);
  const userInfo = useSelector((state) => state.FoxReducer.userInfo);
  const store_code = useSelector((state) => state.FoxReducer.useSelectedStore);
  const [ProRating, setRatingPro] = useState([5]);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const fetchCategory = async () => {
    try {
      let props = {
        url: `${BackendApi.allCategories.url}/${CONST_DATA.CHANNEL}?page=1&size=100`,
        method: BackendApi.allCategories.method,
      }
      const data1 = await fetchData(props);
      if (data1.success && data1.results != null && data1.results[0] != null && data1.results[0].child_categories != null) {
        setData(data1.results[0].child_categories);
      }
      else
        setData(null);
    } catch (err) {
      alert('fetchCategory:' + err);
    } finally {
    }
  }
  const fetchRating = async () => {
    if (productInfo && productInfo.product_code) {
      try {
        let props = {
          url: `${BackendApi.allRating.url}/${productInfo.product_code}?page=1&size=30`,
          method: BackendApi.allRating.method,
        }
        const data1 = await fetchData(props);
        if (data1.success) {
          setDataRating(data1.results);
          setRatingPro(data1.average);
        }
        else
          setDataRating(null);
      } catch (err) {
        alert(err);
      } finally {
      }
    }
  }
  const insertRating = async (rating, comment) => {
    try {
      let props = {
        url: `${BackendApi.createRating.url}`,
        method: BackendApi.createRating.method,
        body: {
          "customer_code": userInfo?.customers[0]?.customer_no,
          "product_code": productInfo.product_code,
          "rating": rating,
          "comment": comment
        }
      }
      const data1 = await fetchDataV2(props);
      if (data1.success) {
        toast.success(En ? "Save success" : "Lưu thành công");
        setDataRating([{ "rating": rating, "comment": comment, "customer_code": userInfo?.customers[0]?.customer_no }, ...dataRating]);
      }
      else {
        toast.error(data1.message);
      }

    } catch (err) {
      toast.error(JSON.stringify(err) ?? "Error");
    } finally {
    }
  }
  const extractProductId = (pathname) => {
    const segments = pathname.split('-');
    return segments[segments.length - 1];
  };
  const fetchProducts = async () => {
    var p_code = extractProductId(location.pathname);
    try {
      let props = {
        url: `${BackendApi.allProductsAndCount.url}?page=${1}&size=${1}&active=true&published=true&store_id=${CONST_DATA.STORE_DEFAULT}&scopes=["ProductOrigin","ProductCategory","ProductAttribute","ProductPrice","ProductImage"]&product_list=${p_code}`,
        method: BackendApi.allProductsAndCount.method,
      };

      const data1 = await fetchData(props);
      if (data1.success) {
        var _pitem = FoxProduct_Home( data1.results[0],En);
        console.log(_pitem);
        setProductInfo(_pitem);
      } 
    } catch (err) {
    } 
  };

  useEffect(() => {
    //setProductInfo(location.state?.item ?? {});
    fetchProducts();
    setPrevLocation(location.pathname);
    fetchCategory();
  }, [location.state, location.pathname]);

  useEffect(() => {
    fetchRating();
  }, [productInfo, location.state, location.pathname]);


  // Find the parent code of the category with code 1420
  const getParentCode = (categories, categoryCode) => {
    for (const category of categories) {
      const child = category.child_categories.find(cat => cat.category_code === categoryCode);
      if (child) {
        return category.category_code;
      }
    }
    return null;
  };

  // Get all categories that have the same parent_code
  const siblingCategories = (categories, parentCode) => {
    const parentCategory = categories.find(category => category.category_code === parentCode);
    return parentCategory ? parentCategory.child_categories : [];
  };

  const go_home = () => {
    navigate(`/`);
  };
  const go_shop = () => {
    const parentCode = getParentCode(data, productInfo.cat);
    const child_categories = siblingCategories(data, parentCode);
    navigate(`/category/${convertToSlug(productInfo.cat_name)}_${productInfo.cat}`, { state: { child_categories } });
    //navigate(`/category/${childName}`,{ state: { child_categories }});
  };
  function convertToSlug(str) {
    str = str.toLowerCase();
    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    str = str.replace(/[^a-z0-9\s-]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');

    return str;
  }
  function formatDate(dateString) {
    const date = dateString ? new Date(dateString) : new Date();

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Tháng bắt đầu từ 0 nên cần cộng thêm 1
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }


  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    if (userInfo && userInfo.customers != null && userInfo?.customers[0]?.customer_no) {
      setDialogOpen(true);
      setDialogReset(prev => !prev); // Toggle reset to clear data
    }
    else {
      toast.error("Vui lòng đăng nhập");
      navigate(`/signin`);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);

  };
  const handleSubmitRating = (data) => {
    insertRating(data.rating, data.comment);

  };

  return (
    <div className="w-full bg-primeColorDark mx-auto border-b-[1px] border-b-bsmBG pt-10 md:pt-10">
      <Helmet>
        <title>{productInfo.name}</title>
        <meta name="description" content={productInfo.des} />
        <meta name="keywords" content={`bsm, bsmart, ecommerce, fox-commerce, shopping, ${productInfo.name}`} />
        {/* Ensure these are unique and replaced */}
        <meta property="og:description" content={productInfo.des} data-react-helmet="true" />
        <meta property="og:title" content={productInfo.name} data-react-helmet="true" />
        <meta property="og:image" content={productInfo.image ?? `${window.location.origin}/sampleImage.jpg`} data-react-helmet="true" />
        <meta property="og:url" content={`${window.location.origin}`} data-react-helmet="true" />
        <meta property="og:type" content="website" data-react-helmet="true" />
      </Helmet>
      <div className="max-w-container mx-auto px-4">
        <div className="xl:-mt-10 -mt-7">
          {/* <Breadcrumbs title="" prevLocation={prevLocation} /> */}
          <div className="w-full py-10 xl:py-10 flex flex-col gap-3" style={{ paddingTop: "50px" }}>
            <p className="text-sm font-normal text-lightText capitalize flex items-center">
              <span className="capitalize font-semibold text-primeColor" onClick={() => go_home()} style={{ cursor: "pointer" }}>
                {En ? "Home" : "Trang chủ"}
              </span>
              <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: "5px", marginRight: "5px" }}><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
              <span className="capitalize font-semibold text-primeColor" onClick={() => go_shop()} style={{ cursor: "pointer" }}>
                {productInfo.cat_name}
              </span>
            </p>
          </div>
        </div>

        <div className="w-full grid grid-cols-1 md:grid-cols-4 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-10 bg-white p-4">

          <div className="h-full md:col-span-2 xl:col-span-2">
            <img
              className="w-full h-full md:col-span-2 xl:col-span-2 xl:px-4 flex flex-col gap-6 justify-center"
              src={productInfo.image??`${window.location.origin}/sampleImage.jpg`}
              alt={productInfo.image}
            />
          </div>
          <div className="h-full w-full md:col-span-2 xl:col-span-4 xl:px-4 flex flex-col gap-6 justify-center">
            <ProductInfo productInfo={productInfo} ProRating={ProRating} />
          </div>
        </div>
        <div>
          {/* <div className=" space-x-4  pt-4">
            <button
              key="d1"
              className={`bg-blue-500 text-white py-2 px-4  focus:outline-none`}
            >
              {En ? "Description" : "Mô tả"}
            </button>
          </div>
          <div className="my-4">
            <p>{productInfo.des ?? ""}</p>
          </div> */}
          {/* <div className=" space-x-4  pt-4">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`${activeTab === tab.id
                  ? "bg-blue-500 text-white"
                  : "bg-cartBGLight text-thirdTextColor"
                  } py-2 px-4  focus:outline-none`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.label}
              </button>
            ))}
          </div>
          <div className="my-4">
            {tabs.map((tab) => (
              <div
                key={tab.id}
                className={activeTab === tab.id ? "" : "hidden"}
              >
                {tab.id === "Fiche Technique" && productInfo.ficheTech ? (
                  <div>
                    <table className="table-auto w-full">
                      <tbody>
                        {productInfo.ficheTech.map((row) => (
                          <tr key={row.label} className="bg-bsmBG">
                            <td className="border px-4 py-2 font-semibold">
                              {row.label}
                            </td>
                            <td className="border px-4 py-2">{row.value}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="my-4 flex justify-end">
                      <button className="inline-flex items-center px-4 py-2 border border-bsmBG bg-blue-500 hover:bg-blue-600 text-white font-bodyFont">
                        <FaDownload className="h-5 w-5 mr-2 text-white" />
                        <a
                          href={productInfo.pdf}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-white"
                        >
                          Download PDF
                        </a>{" "}
                      </button>
                    </div>
                  </div>
                ) : (
                  <p>{tab.content}</p>
                )}
              </div>
            ))}
          </div> */}
          <h2 style={{ fontSize: "25px", fontWeight: "bold" }}> {En ? "Product Reviews" : "Đánh giá sản phẩm"}</h2>
          <button
            onClick={handleDialogOpen}
            className="bg-primeButtonColor text-secondTextColor py-2 px-4 rounded"
          >
            {En ? "Write to Review" : "Viết đánh giá"}
          </button>
          <RatingDialog
            open={isDialogOpen}
            onClose={handleDialogClose}
            onSubmit={handleSubmitRating}
            reset={dialogReset}
          />
          <table style={{ width: "100%" }}>
            {dataRating && dataRating.length > 0 ? (
              dataRating.map((data_item) => {
                return (
                  <tr style={{ borderBottom: "1px solid #ebf0f9" }}>
                    <td>
                      <table style={{ marginTop: "10px", marginBottom: "10px" }}>
                        <tr>
                          <td><span style={{ fontSize: "16px", fontWeight: "bold" }}>{data_item.customer_name ?? data_item.customer_code}</span></td>
                        </tr>
                        <tr>
                          <td><Rating rating={data_item.rating} /></td>
                        </tr>
                        <tr>
                          <td><span>{data_item.comment}</span></td>
                        </tr>
                        <tr>
                          <td><span style={{ color: "#8f9bb3", fontSize: "12px" }}>{formatDate(data_item.created_at)}</span></td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                );
              })
            ) : (
              <></>
            )}
          </table>
          <br />
          <RelatedProducts productInfo={productInfo}></RelatedProducts>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;