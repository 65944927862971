import { fetchData } from "../common.js/http";
import { formatDateTime } from "../utils/DatetimeHelper";
import { CONST_DATA } from "../common.js/constData";
import BackendApi from "../common.js/backendApi";
import _ from 'lodash';
import { RemoveCartItemsAnyway } from "./CartServices";
//const baseUrl="http://172.26.16.163:1880";
//const baseUrl = "https://172.26.16.188:3030";
const baseUrl = CONST_DATA.FOX;

async function CreateOrder(data,orderStatus ="10") {
  let newData = {
    created_date: formatDateTime(new Date()),
    created_by: data.customer_no,
    sale_channel: CONST_DATA.CHANNEL.toString(),
     order_status: orderStatus,
    customer_no: data.customer_no,
    customer_name: data.customer_name,
    card_no: data.card_no,
    //vat_indicator: "2",
    representative: data.customer_name,
    //sale_order_source: "1",
    store_code: data.store_id,
    //payment_type: "POD_",
    //fulfillment_type: "1",
    invoice_address: data.invoice_address,
    fiscal_no: "0401880552-001",
    delivery_fee: data.delivery_fee,
    delivery_address: data.delivery_address,
    delivery_code: data.delivery_code,
    //delivery_office_phone: "",
    delivery_email: data.delivery_email,
    delivery_date: data.delivery_date,
    delivery_time_from: data.delivery_time_from,
    delivery_time_to: data.delivery_time_to,
    // send_order_mail_flag: "",
    // send_order_email: "",
    // internal_comment: "",
    // picking_comment: "",
    // Invoice_comment: "",
    delivery_comment: data.delivery_comment,
    total_price_ex_vat: data.total_price_ex_vat,
    total_discount_ex_vat: data.total_discount_ex_vat,
    //data.total_discount_ex_vat,
    total_vat: data.total_vat,
    grand_total: data.grand_total,
    receiver_name: data.receiver_name,
    receiver_phone: data.receiver_phone,
    order_items: data.order_items,
  };

  let url = `${BackendApi.CreateOrder.url}`;
  // `${baseUrl}/create-order`;
  let props = {
    url: url,
    method: BackendApi.CreateOrder.method,
    //body:data
    body: newData,
  };
  return await fetchData(props);
}
async function GetOrdersHistory(customer_no, statuses, start_date, pageSize,pageNum) {
  
  
  
  let filter ='';
  if(!_.isNil(statuses)){
    if(statuses.length>0){
      let statusValues = statuses.map((item)=> {
        return item.value
      })
      filter +=`status=${statusValues.join(',')}` 
    }
    
  }
  if(!_.isNil(start_date)){
    
      filter+= `&order_date[gt_or_eq]=${start_date}`
  }
  if(_.isNil(pageSize)){
      pageSize= 10000000
  }
  if(_.isNil(pageNum)){
    pageNum= 1
}
  let url = `${BackendApi.GetOrdersHistory.url}?page=${pageNum}&size=${pageSize}&customer_no=${customer_no}&${filter}`;
  let props = {
    url: url,
    method: BackendApi.GetOrdersHistory.method,
  };
  const response = await fetchData(props);
  return response;
}
async function GetOrderById(id) {
  let url = `${BackendApi.GetOrderById.url}/${id}`;
  let props = {
    url: url,
    method: BackendApi.GetOrderById.method,
  };
  const response = await fetchData(props);
  return response;
}
async function GetDeliveryFee(orderInfo) {
  let deliInfo = orderInfo.delivery_info;
  let urlBase = `${BackendApi.GetDeliveryFee.url}`;
  let url = `${urlBase}?province_code=${deliInfo.city.value}&district_code=${deliInfo.district.value}&sub_district_code=${deliInfo.sub_district.value}&channel=${CONST_DATA.CHANNEL}`;

  let props = {
    url: url,
    method: BackendApi.GetDeliveryFee.method,
    //body:orderInfo
  };
  const response = await fetchData(props);
  return response;
}
async function GetDeliveryItems() {
  let url = `${BackendApi.GetDeliveryItems.url}`;
  let props = {
    url: url,
    method: BackendApi.GetDeliveryItems.method,
  };
  const response = await fetchData(props);
  return response;
}
function ReCalcPriceExVatItems(orderItems) {
  let newOrderItems = orderItems.map((item) => {
    let vat_after_disc = Math.round(
      (item.total_price_in_vat * (item.vat_rate / 100)) /
        (1 + item.vat_rate / 100)
    );
    let unit_price_ex_vat = Math.round(
      (item.total_price_in_vat - vat_after_disc) / item.order_qty
    );
    let total_price_ex_vat = item.total_price_in_vat - vat_after_disc;

    let newItem = {
      ...item, // Ensure this spreads before the new properties
      vat_after_disc: vat_after_disc,
      unit_price_ex_vat: unit_price_ex_vat,
      total_price_ex_vat: total_price_ex_vat,
    };

    return newItem;
  });
  return newOrderItems;
}
async function GetOrderStatuses(){
  let url = `${BackendApi.GetOrderStatuses.url}`;
  let props = {
    url: url,
    method: BackendApi.GetOrderStatuses.method,
  };
  const response = await fetchData(props);
  return response;
}
async function DeliveryTimeSlots(){
  
  let url = `${BackendApi.GetTimesSlot.url}`;
  let props = {
    url: url,
    method: BackendApi.GetTimesSlot.method,
  };
  const response = await fetchData(props);
  return response.results;
  
}
 
async function ThresholdDeliveryFees(){
 
  let url = `${BackendApi.DeliveryFee.url}`;
  let props = {
    url: url,
    method: BackendApi.DeliveryFee.method,
  };
  const response = await fetchData(props);
  return response.results;
}
async function  InitPatment(params) {
  let url = `${BackendApi.InitPayment.url}`;
  
  let props = {
    url: url,
    method: BackendApi.InitPayment.method,
    body:params
  };
  const response = await fetchData(props);
  return response;
}
async function  ChangeToPayLater(order) {
  let url = `${BackendApi.UpdateOrder.url}`;
  
  let props = {
    url: url,
    method: BackendApi.UpdateOrder.method,
    body:order
  };
  const response = await fetchData(props);
  return response;
}
async function DeleteCartItemsByOrderId(order_id) {
  try{
     var orderResult =await GetOrderById(order_id);
     let productCodes = orderResult.results[0].order_items.map((p)=> p.Product.product_code);
     await RemoveCartItemsAnyway(orderResult.results[0].customer_no, productCodes)
  }
  catch(error){

  }
}

export {
  CreateOrder,
  GetOrdersHistory,
  GetOrderById,
  GetDeliveryFee,
  GetDeliveryItems,
  ReCalcPriceExVatItems,
  GetOrderStatuses,
  DeliveryTimeSlots,
  ThresholdDeliveryFees,
  InitPatment,
  ChangeToPayLater,
  DeleteCartItemsByOrderId
};
