import BackendApi from "../common.js/backendApi";
import { CONST_DATA } from "../common.js/constData";
import httpFactory from "../common.js/httpFactoty";
import { store } from "../redux/store";
import { replaceVietnameseChars } from "../utils/processVietnameseLanguage";
function CreateElasticQuery(store_id, proudct_name) {
  
  const state = store.getState();
  
  if (state.FoxReducer.En) {
    return CreateQueryEn(store_id, proudct_name)
  } else {
    return CreateQueryVn(store_id, proudct_name)
  }
  
}
function countWords(sentence) {
  // Loại bỏ khoảng trắng đầu và cuối câu và tách câu thành các từ dựa trên khoảng trắng
  const words = sentence.trim().split(/\s+/);
  // Trả về độ dài của mảng từ, chính là số lượng từ trong câu
  return words.length;
}
function CreateQueryVn(store_id, product_name) {
  let product_name_unsig = replaceVietnameseChars(product_name);
  let query = {
    from: 0,
    size: 20,
    _source: [
      "art-no",
      "art-name",
      "slug_url",
      "full_desc_local",
      "full_desc_en",
      "Categories",
      "ProductAttributes",
      "ProductImages",
    ],
    query: {
      bool: {
        should: [
          {
            match: {
              full_desc_local: {
                query: product_name,
                operator: "and",
                boost: 5,
              },
            },
          },
          {
            match: {
              full_desc_local: {
                query: product_name_unsig,
                operator: "and",
                boost: 5,
              },
            },
          },
          {
            multi_match: {
              query: product_name,
              fields: [
                "art-name^3",
                "Categories.name_local^1",
                "ProductAttributes.name_local^2",
              ],
              fuzziness: CONST_DATA.FUZZINESS,
              operator: "and",
            },
          },
          {
            multi_match: {
              query: product_name_unsig,
              fields: [
                "art-name^3",
                "Categories.name_local^1",
                "ProductAttributes.name_local^2",
              ],
              fuzziness: CONST_DATA.FUZZINESS,
              operator: "and",
            },
          },
        ],
        filter: [
          {
            term: {
              "retail_prices.store_id": CONST_DATA.STORE_DEFAULT,
            },
          },
          {
            term: {
              
              "Categories.active":1,
             
            },
          },
          {
            term: {
             
              "Categories.channel_code":CONST_DATA.CHANNEL
              //CONST_DATA.CHANNEL,
            },
          },

          {
            range: {
              "retail_prices.status": {
                lte: 1,
              },
            },
          },
        ],
        minimum_should_match: 1, // params at least 1 and >50%
      },
    },
    script_fields: {
      retail_prices: {
        script: {
          lang: "painless",
          source: `def filtered = []; for (item in params['_source']['retail_prices']) { if (item.store_id == ${store_id}) { filtered.add(item); } } return filtered;`,
        },
      },
    },
    sort: [
      {
        _score: {
          order: "desc",
        },
      },
    ],
  };
  return query;
}
function CreateQueryEn(store_id, proudct_name) {
  let query = {
    from: 0,
    size: 20,
    _source: [
      "art-no",
      "art-name",
      "slug_url",
      "full_desc_local",
      "full_desc_en",
      "Categories",
      "ProductAttributes",
      "ProductImages",
    ],
    query: {
      bool: {
        should: [
          {
            match: {
              full_desc_en: {
                query: proudct_name,
                operator: "and",
                boost: 5,
              },
            },
          },

          {
            multi_match: {
              query: proudct_name,
              fields: [
                "full_desc_en^3",
                "Categories.name_en^1",
                "ProductAttributes.name_en^2",
              ],
              fuzziness: CONST_DATA.FUZZINESS,
              operator: "and",
            },
          },
        ],
        filter: [
          {
            term: {
              "retail_prices.store_id": CONST_DATA.STORE_DEFAULT,
            },
          },
          {
            term: {
              
              "Categories.active":1,
             
            },
          },
          {
            term: {
             
              "Categories.channel_code":CONST_DATA.CHANNEL
              //CONST_DATA.CHANNEL,
            },
          }
          ,
          {
            range: {
              "retail_prices.status": {
                lte: 1,
              },
            },
          },
        ],
        minimum_should_match: 1, // params at least 1 and >50%
      },
    },
    script_fields: {
      retail_prices: {
        script: {
          lang: "painless",
          source:
            "def filtered = []; for (item in params['_source']['retail_prices']) { if (item.store_id == 30002) { filtered.add(item); } } return filtered;",
        },
      },
    },
    sort: [
      {
        _score: {
          order: "desc",
        },
      },
    ],
  };
  return query;
}

async function SearchProductElastic(product_name, store_id) {
  let queryData = CreateElasticQuery(store_id, product_name);

  let props = {
    url: BackendApi.SearchProductElastic.url,
    method: BackendApi.SearchProductElastic.method,
    body: queryData,
  };
  const fact = new httpFactory(props);
  let response = await fact.fetchData();
  return response;
}

async function SearchProduct(product_name, store_id) {
  let products = [];

  if (product_name.length === 0) return products;

  
  
  let elasticResponse = await SearchProductElastic(product_name, store_id);
  let elasticProducts = [];
  if (elasticResponse.success) {
    if (elasticResponse.results[0].hits.hits.length > 0) {
      
      elasticProducts= elasticResponse.results[0].hits.hits.filter(o=>o.fields.retail_prices[0].status<2)

    }
  }
  
  return elasticProducts;
}
async function RetrieveProducts(product_codes) {
  let size = product_codes.length;
  let list_product_code = product_codes.join(",");
  let url = `${BackendApi.RetrieveProducts.url}?page=1&scopes=["ProductOrigin","ProductCategory","ProductAttribute","ProductPrice","ProductImage"]&active=true&published=true&size=${size}&product_list=${list_product_code}`;
  let props = {
    url: url,
    method: BackendApi.RetrieveProduct.method,
  };
  const fact = new httpFactory(props);
  let response = await fact.addStore().addChannel().fetchData();
  if (response.success) {
    return response.results;
  } else return null;
}
async function RetrieveProduct(product_code) {
  //let url = `${baseUrl}/products/${product_code}?active=true&scopes=["ProductOrigin","ProductCategory","ProductAttribute","ProductPrice", "ProductImages"]`;
  let url = `${BackendApi.RetrieveProduct.url}/${product_code}?active=true&scopes=["ProductOrigin","ProductCategory","ProductAttribute","ProductPrice", "ProductImages"]`;
  let props = {
    url: url,
    method: BackendApi.RetrieveProduct.method,
  };
  //let response = await fetchData(props);
  const fact = new httpFactory(props);
  let response = await fact.addStore().addChannel().fetchData();
  //await fetchData(props);

  if (response.success) {
    return response.results[0];
  } else return null;
}
export { RetrieveProduct, SearchProduct, RetrieveProducts };
