import { CONST_DATA } from "../common.js/constData";
import { GetDeliveryFee, ThresholdDeliveryFees } from "./OrderServices";

class DeliveryFeeFactory {
  constructor(orderInfo) {
    this.orderInfo = orderInfo;
    this.shipingValue = 0;
    this.vatCode = "";
    this.vatRate = 10;
    this.charge_item="";
    this.storeCode =CONST_DATA.STORE_DEFAULT;
    

    this.totalAmount = this.orderInfo.order_items.reduce(
      (accumulator, currentValue) => accumulator + currentValue.retail_price*currentValue.quantity,
      0
    );
    // this.add_base_fee = false;
  }

  async addBaseFee() {
    const response = await GetDeliveryFee(this.orderInfo);
    
    if (response.success) {
      let unit = response.results[0].DeliveryZone.unit.toLowerCase();
      this.vatCode = response.results[0].DeliveryZone.vat_code;
      this.vatRate = response.results[0].DeliveryZone.vat_rate;
      this.charge_item= response.results[0].DeliveryZone.charge_item;
      this.storeCode=response.results[0].store_code
      if (unit === "amt") {
        this.shipingValue += response.results[0].DeliveryZone.charge === 1 ?? 0;
      }
      const validUnits = ["pct", "percent", "%"];

      if (validUnits.includes(unit)) {
        this.shipingValue +=
          (this.totalAmount * response.results[0].DeliveryZone.charge) / 100;
      }
    }

    return this;
  }
  async addThresholdFee() {
    const response = await ThresholdDeliveryFees();
    const deli = response.find(
      (o) => o.from_amt <= this.totalAmount && o.to_amt >= this.totalAmount
    );
    if (deli) {
      this.vatCode = deli.vat_code.toString();
      this.vatRate = deli.vat_rate;
      this.shipingValue += deli.charge;
    }
    
    return this;
  }
  addOtherFee() {
    return this;
  }
  calcFee() {
    if (this.shipingValue < 0) this.shipingValue = 0;
    this.shipingValue =this.roundDownToThousand(this.shipingValue);
    return this;
  }
  roundDownToThousand(num) {
    return Math.floor(num / 1000) * 1000;
  }
}
export default DeliveryFeeFactory;

