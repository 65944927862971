import React, { useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { logoLight, vi, uk } from "../../assets/images";
import { useNavigate, useLocation } from "react-router-dom";
import { sendLoginOtp } from "../../services/AccountServices";
import { Loading } from "../../components/Load/Loading";
import { LoadingButton } from "../../components/Load/LoadingButton";
import { useDispatch } from "react-redux";
import { toggleLanguage } from "../../redux/FoxSlice";
import Image from "../../components/designLayouts/Image";
import { LableItem } from "../../components/LabelLanguge/LabelItem";
import { useContentLangByCode, GetContentLangByCode } from "../../components/contentLanguage/ContentFactory";
import { ValidateVietnamesePhone } from "../../utils/CheckObjectHelper";
import { toast } from "react-toastify";
const SignIn = () => {
  // ============= Initial State Start here =============
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  // ============= Initial State End here ===============
  // ============= Error Msg Start here =================
  const [errEmail, setErrEmail] = useState("");
  const [errPassword, setErrPassword] = useState("");

  // ============= Error Msg End here ===================
  const [successMsg, setSuccessMsg] = useState("");
  // ============= Event Handler Start here =============

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  let returnUrl = location.state?.returnUrl;
  const [En, setEn] = useState(false);

  const dispatch = useDispatch();
  const signinButtonLabel = useContentLangByCode("P.Signin.SigninButton");
  const handleLanguageToggle = () => {
    const newLanguage = En ? "vi" : "en";
    setEn(!En);
    dispatch(toggleLanguage(newLanguage));
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
    setErrEmail("");
  };

  // ============= Event Handler End here ===============
  const handleSignUp = async (e) => {
    e.preventDefault();

    if (!phone) {
      setErrEmail(GetContentLangByCode("Error.Phone.Empty"));
      return;
    }
    if(!ValidateVietnamesePhone(phone)){
      setErrEmail(GetContentLangByCode("Error.Phone.Format"));
      return;
    }
    // ============== Getting the value ==============
    if (phone) {
      let data = {
        phone: phone,
      };
      setLoading(true);
      const response = await sendLoginOtp(data);
      setLoading(false);
      if (response.success) {
        navigate("/login-otp", {
          state: { phone: phone, returnUrl: returnUrl ? returnUrl : "/" },
        });
      }
    }
  };
  return (
    <div className="w-full h-screen flex items-center justify-center bg-bsmBG">
      <div className="w-full max-w-[450px]">
        <form className="bg-white px-6 py-8 h-[90%] flex flex-col justify-center overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor shadow-md rounded-lg">
          <div className="flex justify-end">
            <div
              className="relative top-4 right-4"
              onClick={handleLanguageToggle}
            >
              <Image
                className="w-6 h-6 object-cover cursor-pointer"
                imgSrc={En ? uk : vi}
              />
            </div>
          </div>
          <h1 className="text-3xl font-semibold underline underline-offset-4 mb-4">
            <LableItem code="P.Signin.Header"></LableItem>
          </h1>
          <div className="flex flex-col gap-3">
            {/* Phone number */}
            <div className="flex flex-col gap-.5">
              <p className="text-base font-semibold text-thirdTextColor">
                <LableItem code="P.Signin.Phone"></LableItem>
              </p>
              <input
                onChange={handlePhone}
                value={phone}
                className="w-full h-10 px-4 text-base font-medium rounded-md border-[1px] border-bsmBoder outline-none"
                type="tel"
                placeholder="123-456-7890"
              />
              {errEmail && (
                <p className="text-sm text-red-500 font-semibold px-4">
                  <span className="italic mr-1">!</span>
                  {errEmail}
                </p>
              )}
            </div>

            {/* Sign In Button */}
            <LoadingButton
              onButtonClick={handleSignUp}
              loading={loading}
              btName={signinButtonLabel}
              className="mt-4"
            />

            <p className="text-sm text-ci font-medium text-center">
              <Link to="/">
                <li
                  key="homepage"
                  className="flex font-normal hover:font-bold justify-center items-center text-lg text-blue-600 hover:underline underline-offset-[4px] decoration-[1px] hover:text-black md:border-r-[2px] border-r-bsmBG hoverEffect last:border-r-0"
                >
                  <LableItem code="C.Multi.BackToHome"></LableItem>
                </li>
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
