import React, { useState, useEffect } from 'react';
import { useContentLangByCode } from '../contentLanguage/ContentFactory';
const NumberInput = ({ value, onChange, key_code, unit_code }) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipMess, setTooltipMess]= useState('');
  const IS_WEIGT_INPUT = 2;
  const messageDecimal  = useContentLangByCode("C.NumberInput.Decimal1")
  const messageInt  = useContentLangByCode("C.NumberInput.Int")
  useEffect(() => {
    setInputValue(value || '');
    if(unit_code === IS_WEIGT_INPUT){

      setTooltipMess(messageDecimal)
    }
    else{
      setTooltipMess(messageInt)
    }
  }, [value]);

  const handleChange = (key_code) => (e) => {
    let value = e.target.value;
    let isValid = true;

    if (unit_code === IS_WEIGT_INPUT) {
      isValid = /^\d*\.?\d{0,1}$/.test(value);
    } else {
      isValid = /^\d*\.?\d{0,0}$/.test(value);
    }

    if (isValid) {
      setInputValue(value);
      setShowTooltip(false);
      if (onChange) {
        onChange(value, key_code);
      }
    } else {
      setShowTooltip(true);
    }
  };

  return (
    <div className="relative w-1/2">
      <input
        type="text"
        value={inputValue}
        onChange={handleChange(key_code)}
        className="w-full h-10 px-2 text-center text-sm font-medium form-input border border-bsmBG rounded-md focus:ring-primeColor focus:border-primeColor"
        placeholder="Enter a number"
      />
      {showTooltip && (
        <div className="absolute top-full mt-1 w-full bg-red-500 text-white text-xs p-2 rounded">
         {tooltipMess}
        </div>
      )}
    </div>
  );
};

export default NumberInput;
