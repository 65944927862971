Date.prototype.isMoreThanDate = function (otherDate) {
  this.setHours(0, 0, 0, 0);
  otherDate.setHours(0, 0, 0, 0);
  return this.setHours(0, 0, 0, 0) > otherDate.setHours(0, 0, 0, 0);
};
// Date.prototype.isBetween = function (fromDate, toDate) {
//   this.setHours(0, 0, 0, 0);
//   fromDate.setHours(0, 0, 0, 0);
//   toDate.setHours(0, 0, 0, 0);
//   return (
//     fromDate.setHours(0, 0, 0, 0) <= this.setHours(0, 0, 0, 0) &&
//     this.setHours(0, 0, 0, 0) <= toDate.setHours(0, 0, 0, 0)
//   );
// };
Date.prototype.isBetweenIgnoreTime = function (fromDate, toDate) {
  this.setHours(0, 0, 0, 0);
  fromDate.setHours(0, 0, 0, 0);
  toDate.setHours(0, 0, 0, 0);
  return (
    fromDate.setHours(0, 0, 0, 0) <= this.setHours(0, 0, 0, 0) &&
    this.setHours(0, 0, 0, 0) <= toDate.setHours(0, 0, 0, 0)
  );
};

Date.prototype.isBetweenDateTime = function (fromDate, toDate) {
  
  return (this>=fromDate && this<=toDate);
  
};
export {};
