import React from "react";

const MomoIcon = () => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      height="24px"
      viewBox="0 0 296 296"
      style={{ enableBackground: "new 0 0 296 296" }}
      xmlSpace="preserve"
    >
      <style>
        {`
          .st0 { fill-rule:evenodd; clip-rule:evenodd; fill:#A50064; }
          .st1 { fill:#FFFFFF; }
        `}
      </style>
      <path
        className="st0"
        d="M276,0H20C9,0,0,9,0,20v256c0,11,9,20,20,20h256c11,0,20-9,20-20V20C296,9,287,0,276,0z"
      />
      <g>
        <path
          className="st1"
          d="M204.8,139c23.5,0,42.5-19,42.5-42.5c0-23.5-19-42.5-42.5-42.5c-23.5,0-42.5,19-42.5,42.5
            C162.3,120,181.3,139,204.8,139z M204.8,78.4c10,0,18.1,8.1,18.1,18.1c0,10-8.1,18.1-18.1,18.1c-10,0-18.1-8.1-18.1-18.1
            C186.7,86.5,194.8,78.4,204.8,78.4z"
        />
        <path
          className="st1"
          d="M204.8,157.4c-23.5,0-42.5,19-42.5,42.5c0,23.5,19,42.5,42.5,42.5c23.5,0,42.5-19,42.5-42.5
            C247.3,176.4,228.3,157.4,204.8,157.4z M204.8,218c-10,0-18.1-8.1-18.1-18.1c0-10,8.1-18.1,18.1-18.1c10,0,18.1,8.1,18.1,18.1
            C222.9,209.9,214.8,218,204.8,218z"
        />
        <path
          className="st1"
          d="M118.2,157.4c-7.2,0-13.8,2.4-19.1,6.4c-5.3-4-12-6.4-19.1-6.4c-17.6,0-31.9,14.3-31.9,31.9v53.2h24.4V189
            c0-4,3.2-7.2,7.2-7.2c4,0,7.2,3.2,7.2,7.2v53.4h24.4V189c0-4,3.2-7.2,7.2-7.2c4,0,7.2,3.2,7.2,7.2v53.4H150v-53.2
            C150,171.7,135.8,157.4,118.2,157.4z"
        />
        <path
          className="st1"
          d="M118.2,54c-7.2,0-13.8,2.4-19.1,6.4c-5.3-4-12-6.4-19.1-6.4C62.3,54,48,68.3,48,85.9V139h24.4V85.6
            c0-4,3.2-7.2,7.2-7.2c4,0,7.2,3.2,7.2,7.2V139h24.4V85.6c0-4,3.2-7.2,7.2-7.2c4,0,7.2,3.2,7.2,7.2V139H150V85.9
            C150,68.3,135.8,54,118.2,54z"
        />
      </g>
    </svg>
  );

export default MomoIcon;
  



