import React, { useEffect, useState } from 'react';
import { CheckPaymentResult } from '../../services/PaymentServices';
import { useNavigate } from 'react-router-dom';

const PaymentResult = () => {
  const [params, setParams] = useState({});
  const navigate = useNavigate();
  useEffect(() => {

    const checkPayment = async (paymentResult) => {
      var rs =await CheckPaymentResult(paymentResult);
      if(rs===200){
        console.log("Pament is valid");
        if(paymentResult.StatusCode==="001"){
          // navigate(`/order-history`,{
          //   state: { order_id: paymentResult.OrderId },
          // });
          navigate("/order-completed", {
            state: { order_no: paymentResult.OrderId},
          });
         
        }
      }
      else {
        navigate(`/order-payment/${paymentResult.OrderId}`);
      }
    }
    // Create a URLSearchParams object from the query string in the URL
    const queryParams = new URLSearchParams(window.location.search);

    // Create an object to store the parameters
    const paramsObject = {};
    
    // Iterate over all the query parameters
    queryParams.forEach((value, key) => {
      paramsObject[key] = value;
    });

    // Set the params state with all the query parameters
    setParams(paramsObject);
    checkPayment(paramsObject);
    console.log(paramsObject);
  }, []);


  return (
    <div className="max-w-container mx-auto px-0 pt-5 md:pt-2">
        <div className="mt-20"></div>
      <div className="border-b pb-4 mb-6 md:mt-100 ">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Đã nhận kết quả thanh toán
          </h2>
        </div>
      </div>
    </div>
  );
};
export default PaymentResult