import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'; // Để lấy URL hiện tại
import useSEO from "../../hooks/useSEO";

const HtmlPageRa = () => {
  const [content, setContent] = useState('');
  const En = useSelector((state) => state.FoxReducer.En); 
  const location = useLocation(); 
  useSEO({
    title: (location.pathname === '/chinh-sach-doi-tra'?"Return Policy":(location.pathname === '/chinh-sach-bao-mat'?"Privacy Policy":(location.pathname === '/chinh-sach-giao-hang'?"Delivery Policy":"Customer policy"))),
    description: "Bsmart Policy",
    keywords: ["return Policy", "privacy policy", "Customer policy"],
  });  
  useEffect(() => {
    var policyType = "customerPolicy";
    if(location.pathname === '/chinh-sach-doi-tra')
        policyType ="returnPolicy";
    if(location.pathname === '/chinh-sach-bao-mat')
        policyType ="privacypolicy";
    if(location.pathname === '/chinh-sach-giao-hang')
        policyType ="deliveryPolicy";
   
    if (policyType) {
      fetch('/data_html.json')
        .then((response) => response.json())
        .then((data) => {
          const policyContent = data[policyType][En?"en":"vi"];
          setContent(policyContent);
        })
        .catch((error) => console.error('Error fetching policy:', error));
    }
  }, [location.pathname, En]);

  return (
    <div className="max-w-container mx-auto px-4 pt-10">
      {content ? (
        <div className="mt-auto" dangerouslySetInnerHTML={{ __html: content }}></div>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default HtmlPageRa;
