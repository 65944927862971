import React, { useEffect, useState } from "react";
import { HiOutlineChevronRight } from "react-icons/hi";
import { useLocation } from "react-router-dom";

const Breadcrumbs = ({ prevLocation, title }) => {
  const location = useLocation();
  const [locationPath, setLocationPath] = useState("");

  useEffect(() => {
    // Update locationPath whenever location changes
    const pathSegments = location.pathname.split("/");
    setLocationPath(pathSegments.length > 1 ? pathSegments[1] : "Home");
  }, [location]);

  return (
    <div className="w-full py-10 xl:py-12 flex flex-col">
      {/* <h1 className="text-sm md:text-5xl text-primeColor font-titleFont font-bold">
        {title}
      </h1> */}
      <p className="text-sm font-normal text-lightText capitalize flex items-center">
        <span>{prevLocation === "" ? "Home" : prevLocation}</span>
        <span className="px-1">
          <HiOutlineChevronRight />
        </span>
        <span className="capitalize font-semibold text-primeColor">
          {locationPath}
        </span>
      </p>
    </div>
  );
};

export default Breadcrumbs;
